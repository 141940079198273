import styled from 'styled-components/macro';
import React from 'react';
import {useLogMessages} from './logging';

export function LogMessages() {
  const {logMessages} = useLogMessages();
  return (
    <ScrollContainer>
      {
        logMessages.map(({index, message}) => {
          return(
            <Message key={index}>{message}</Message>
          )
        })
      }
    </ScrollContainer>
  );
}

const ScrollContainer = styled.div`
  height: 50vh;
  width: calc(min(100vw, 500px));
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  justify-items: start;
  background-color: var(--color-background);
`;

const Message = styled.div`
  text-align: left;
  color: var(--color-text);
`
