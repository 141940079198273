import styled from 'styled-components/macro';
import {ReactComponent as BackArrowIcon} from '../../../assets/arrow-previous-left-icon.svg';
import React from 'react';
import {alert} from '../../../util/confirm';
import {ContactSupportButton} from '../../../util/shared';

export const MinSongListRowCount = 15;
export const SongRowWidth = 'min(calc(100vw - 4px), 280px)';
export const SongRowHeightDouble = '4rem';   // mobile (to stack label above song/text)
export const SongRowHeightSingle = '2.5rem'; // desktop
export const LiturgyYearHeight = '60px'; // for sticky positioning (yuck)
export const SongCellPadding = '10px';
export const DateRowTop = `calc(${LiturgyYearHeight} + 1px)`;

export const BackButton = ({onClick, isAbsolutePosition}: {
  onClick: () => void;
  isAbsolutePosition?: boolean;
}) => {
  return (
    <BackButtonWrapper onClick={onClick} $isAbsolutePosition={isAbsolutePosition}>
      <BackArrowIconWrapper/>
    </BackButtonWrapper>
  );
}

export const BackButtonWidth = '35px';
export const BackButtonHeight = '55px';

// takes up space in the flow where the back button would have resided
export const BackButtonPlaceholder = styled.div`
  width: ${BackButtonWidth};
  height: ${BackButtonHeight};
`;

const BackButtonWrapper = styled.div<{$isAbsolutePosition?: boolean}>`
  position: ${props => props.$isAbsolutePosition ? 'absolute' : 'fixed'};
  top: 0;
  left: 0;
  padding: 15px 11px;
  cursor: pointer;
  background-color: var(--color-background);
`

const BackArrowIconWrapper = styled(BackArrowIcon)`
  width: 15px;
  fill: var(--color-text);
`;

export async function DisplayUnauthorizedAlert() {
  await alert({
    confirmation: (
      <span>Your current household is not<br/>authorized to make changes.<br/><br/><ContactSupportButton small={true}/></span>
    )
  });
}
