import {useEffect} from 'react';
import './header.css';
import {ReactComponent as KebabIcon} from '../assets/ellipsis-vertical.svg';
import styled from 'styled-components/macro';
import {synchronizeHouseholdWithServer} from '../data/use_household';
import {UploadSongButton} from '../util/upload_song_button';
import {ReactComponent as SearchIcon} from '../assets/search.svg';
import {ReactComponent as HelpIcon} from '../assets/question-circle-outlined.svg';
import {BackArrow} from '../util/shared';

interface Props {
  title: string;
  isUploadSongButtonVisible?: boolean;
  onBack?: () => void;  //optional callback function for back button in header
  onKebabClick?: () => void;
  onSearchClick?: () => void;
  onHelpClick?: () => void;
  suppressIcons?: boolean;
}

export const HeaderHeight = '55px';

export const Header = ({
  isUploadSongButtonVisible,
  title,
  onBack,
  onKebabClick,
  onSearchClick,
  onHelpClick,
  suppressIcons,
}: Props) => {
  useEffect(() => {
    void synchronizeHouseholdWithServer();
  }, []);

  const isSearchVisible = onSearchClick && !suppressIcons;
  const isLeftJustified = !onKebabClick;

  let maxTitleWidth = '100%';
  if (onBack && (onKebabClick || isUploadSongButtonVisible || isSearchVisible)) {
    maxTitleWidth = '75%';
    // @ts-ignore: TypeScript thinks onKebabClick is always defined... :(
  } else if (onKebabClick || isUploadSongButtonVisible || isSearchVisible) {
    maxTitleWidth = '90%';
  } else if (onBack) {
    maxTitleWidth = '85%';
  }

  const OuterClass = isLeftJustified ? LeftJustifiedHeader : HeaderWrapper;

  return (
    <OuterClass key='header'>
      {
        onBack
          ? <BackButtonWrapper><BackArrow onClick={onBack}/></BackButtonWrapper>
          : (!isLeftJustified && <LeftPlaceholder/>)
      }
      <TitleWrapper $maxWidth={maxTitleWidth}>
        <Title
          $leftMargin={onBack || onKebabClick ? '0' : '20px'}
          $textAlign={isLeftJustified ? 'left' : 'center'}
        >
          {title}
        </Title>
        {isUploadSongButtonVisible && <UploadSongButton />}
      </TitleWrapper>
      <HeaderIcons>
        {onKebabClick && <KebabIcon className="kebabIcon" onClick={() => onKebabClick()}/>}
        {/* TODO(hewitt): use <Link> here instead? */}
        {isSearchVisible && <SearchIcon className="searchHeaderIcon" onClick={() => onSearchClick()}/>}
        {onHelpClick && <HelpButton onClick={() => onHelpClick()}/>}
      </HeaderIcons>
    </OuterClass>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  min-height: ${HeaderHeight};
  height: ${HeaderHeight};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-text-light);
  white-space: normal;
  background-color: var(--color-background);
`

const LeftJustifiedHeader = styled(HeaderWrapper)`
  justify-content: space-between;
  grid-template-columns: revert;
`;

const HeaderIcons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-grow: 1;
  flex-basis: 0;
`;

const LeftPlaceholder = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 4px;
`;

const TitleWrapper = styled.div<{$maxWidth?: string}>`
  display: flex;
  align-items: center;
  max-width: ${props => props.$maxWidth};
`;

const Title = styled.div<{$leftMargin: string, $textAlign: string}>`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.17em;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: ${props => props.$textAlign};
  margin-left: ${props => props.$leftMargin};
`;

const HelpButton = styled(HelpIcon)`
  width: 40px; /* this is necessary for iOS but has no effect in Chrome -> size is unchangeable on both */
  padding-right: 12px;
  cursor: pointer;
  fill: var(--color-text);
`;
