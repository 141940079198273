import {EngineeringAdminWrapper, useRefreshMusicCaches} from '../shared';
import {useBrokenCustomMusic, useBrokenSongsForThisSunday} from './shared';
import styled from 'styled-components/macro';
import {EngineeringSongList} from './engineering_song_list';
import {Spinner} from '../../../../util/spinner';
import {UploadButton} from './upload_button';

export const EngineeringBrokenSongsPage = () => {
  const {brokenSongsByOrg, sundaysPopulated} = useBrokenSongsForThisSunday();
  const {brokenCustomMusicOrg, customMusicPopulated} = useBrokenCustomMusic();
  useRefreshMusicCaches();

  return (
    <EngineeringAdminWrapper title={'Broken Songs'}>
      <OuterWrapper>
        <UploadButton/>
        <Heading>This Sunday</Heading>
        <EngineeringSongList hymnsByOrg={brokenSongsByOrg} showTXTLink={true}/>
        {!sundaysPopulated && <Spinner/>}
        <Heading>Custom Music</Heading>
        <EngineeringSongList hymnsByOrg={brokenCustomMusicOrg} showTXTLink={true}/>
        {!customMusicPopulated && <Spinner/>}
      </OuterWrapper>
    </EngineeringAdminWrapper>
  );
}

const Heading = styled.h1`
  padding-top: 15px;
`;

const OuterWrapper = styled.div`
  text-align: left;
  padding: 20px 40px;
`;
