export enum SettingsDisplay {
  ChooseChurch = 'ChooseChurch',
  Household = 'Household',
  LocalStorage = 'LocalStorage',
  Login = 'Login',
  Logging = 'Logging',
  Settings = 'Settings',
  SongIntroduction = 'SongIntroduction',
  VerseCount = 'VerseCount',
  HouseholdSchedule = 'HouseholdSchedule'
}