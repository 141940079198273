import * as md5 from './md5';
import {
  addDayOffset,
  DateString,
  DayOfWeek,
  getDayOfWeek,
  maxDate,
  maxDateAbsolute,
  minDate,
  minDateAbsolute,
  todayDate
} from "./date_string";
import {ensureUnreachable} from './util';
import {Optional} from './types';
import {TimeString} from './time_string';

export interface Favorite {
  hymnalName: string;
  songNumber: number;
}

export interface TransposedSong {
  hymnalName: string;
  songNumber: number;
  transposeValue: number;
}

export interface SongView {
  songSlug: SongSlug;
  durationMs: number;
}

export interface User {
  token: string;
  name: string;
  email?: string;
  internal?: boolean;
  sysAdmin?: boolean;
}

export type ChurchId = number;

export interface Household {
  token: string;
  familyLastName: string;
  householdEmail: string;
  users: User[];
  churchId?: ChurchId;
  subscriptionExpirationTimestamp?: number;
  status?: HouseholdStatus;
  mostRecentSyncTimestamp?: number;
  surveyResponses?: HouseholdSurveyResponses;
  isAdminForChurchId?: ChurchId;
  singingPlanStreakInDays?: number;
}

export interface WeeklyHouseholdSingingPlan {
  priorStreakInDays?: number;
  days: DailyHouseholdSingingPlan[];
}

export interface DailyHouseholdSingingPlan {
  date: DateString;
  completed?: boolean;
  songs: HouseholdSingingPlanSong[];
}

export interface HouseholdSingingPlanSong {
  category: HouseholdSingingPlanSongCategory;
  slug: SongSlug;
  completed?: boolean;
}

export enum HouseholdSingingPlanSongCategory {
  HymnOfTheMonth = 'Hymn of the Month',
  Seasonal = 'Seasonal',
  ThisSunday = 'This Sunday',
  LastSunday = 'Last Sunday',
}

// song slug formats (note there are no '0' prefixes on song numbers):
//   - hymnal/<hymnal-slug>/song/<song-number> (e.g. "hymnal/cc2020/song/111a")
//   - church/<church-id>/song/<song-number> (e.g. "church/73/song/1")
export type SongSlug = string;

// hymnal slugs are often, but not always, the hymnal initials + publishing year (e.g. 'cc2020')
export type HymnalSlug = string;

export type SongNumber = string;

export interface ChurchHymnal {
  songs: ChurchHymnalSong[];
}

export interface ChurchHymnalSong {
  slug: SongSlug;
  count: number;
  dates: DateString[];
}

export type ActiveChurchCounts = {[date: DateString]: number};

// TODO(hewitt): Should be based on household member - see comments on isChurchAdmin in server_api.ts
export function isAdminForCurrentChurch(household?: Household): boolean {
  return household?.isAdminForChurchId === household?.churchId;
}

export interface Subscription {
  householdId?: number;
  paymentProcessor?: PaymentProcessor,
  email?: string;
  customerId?: string;
  subscriptionExpirationTimestamp?: number;
  productId?: string;
}

export enum PaymentProcessor {
  Apple = 'Apple',
  Google = 'Google',
  Stripe = 'Stripe',
}

export enum GoogleProductIds {
  Annual = 'annualfamily',
  Monthly = 'montlyfamily', // note the misspelling of "monthly"
}

export enum HouseholdStatus {
  Unsubscribed = 'Unsubscribed',
  Subscribed = 'Subscribed',
}

export type SongTempos = {[songSlug: string]: number};

// TODO(hewitt): rename Church to Organization
export interface Church extends Partial<ChurchTokens> {
  id: ChurchId;
  name: string;
  location: string;
  type: OrganizationType;
  city?: string;
  state?: string;
  country?: string;
  deleted?: boolean;
  renewalDate?: number;
  areHouseholdsSubscribed?: boolean;
  primaryHymnalId?: number;
  songTempos?: SongTempos;
  schoolTerms?: SchoolTerm[];           // school only (create subtype after renaming base type to Organization)
  churchLiturgySchema?: SongListSchema; // church only (create subtype after renaming base type to Organization)
  allowPrinting?: boolean;
}

// for JSON type validation
export type ChurchOptionalId = Optional<Church, 'id'>

// church used by client (as opposed to data persisted in the server database)
export interface ClientChurch extends Church {
  hymnals?: Hymnal[]; // do not persist to database
  mostRecentSyncTimestamp?: number;
}

// Note: These values are persisted to the database
export enum OrganizationType {
  Church = 'Church',
  School = 'School',
  SmallGroup = 'Small Group',
}

export interface SchoolTerm {
  startDate: DateString;
  name?: string;
}

export function getCurrentStartEndDatesForSchoolTerms(schoolTerms?: SchoolTerm[]): {
  termStartDate: DateString;
  termEndDate: DateString;
} {
  let termStartDate: DateString = minDateAbsolute();
  let termEndDate: DateString = maxDateAbsolute();
  if (schoolTerms?.length) {
    // Monday treated like prior Sunday
    const dates = schoolTerms.map(term =>
      getDayOfWeek(term.startDate) === DayOfWeek.Monday ? addDayOffset(term.startDate, -1) : term.startDate);
    const datesBeforeToday = dates.filter(date => date <= todayDate());
    termStartDate = datesBeforeToday.length === 0 ? maxDateAbsolute() : maxDate(datesBeforeToday);
    const datesAfterToday = dates.filter(date => date > todayDate());
    termEndDate = datesAfterToday.length === 0 ? maxDateAbsolute() : addDayOffset(minDate(datesAfterToday), -1);
  }
  return {termStartDate, termEndDate}
}

export function getNameForOrgType(type?: OrganizationType): string {
  if (!type) {
    return 'Church';
  }
  switch (type) {
    case OrganizationType.School:
      return 'School';
    case OrganizationType.Church:
      return 'Church';
    case OrganizationType.SmallGroup:
      return 'Group';
    default:
      ensureUnreachable(type);
  }
}

export type ChurchToken = string;

export interface ChurchTokens {
  token: ChurchToken;
  adminToken: string;
}

export interface ChurchAdminCredentials {
  churchId: ChurchId,
  churchAdminToken: string,
  userToken: string,
}

export type HymnalId = number;

export interface Hymnal {
  id: HymnalId;
  name: string;
  isInternal?: boolean;
  isChurchSpecific?: boolean;
  slug: string;
  publisher?: string;
  songListType?: SongListType
}

export interface SongRequest {
  email: string;
  hymnalName: string;
  songNumber: number;
  timestamp: number;
  issue?: CopyrightIssue;
}

export interface Issue {
  hymnalName?: string;
  songNumber?: number;
  timestamp: number;
  description: any;
  status?: string;
}

export enum CopyrightIssue {
  Music = 'Music',     // Music copyright (trumps text copyright)
  Text = 'Text',       // Text copyright
}

export enum SongCopyrightType {
  Music = 'Music',     // Music copyright (trumps text copyright)
  Text = 'Text',       // Text copyright
}

export enum SongFileExtensions {
  Pdf = 'pdf',
  Mid = 'mid',
  Txt = 'txt',
}

export enum SongListType {
  WorshipService = 'worship-service',   // for churches
  WeeklyList = 'weekly-list',           // for schools
  HymnOfTheMonth = 'hymn-of-the-month',
  Event = 'event',
  StaticList = 'static-list',
  SongsOfTheTerm = 'songs-of-the-term', // for schools like Telos Classical (quarterly song list)
}

export function getWeeklySongListType(church?: Pick<Church, 'type'>) {
  return church?.type === OrganizationType.Church ? SongListType.WorshipService : SongListType.WeeklyList;
}

export function getWeeklySongListTypes() {
  return [SongListType.WorshipService, SongListType.WeeklyList];
}

export enum SongListEntryType {
  Song = 'song',
  Text = 'text',
  Scripture = 'scripture',
  Assignment = 'assignment',
}

// only events support song list deletion
export const SongListTypeSupportsDeletion = SongListType.Event;

export type SongListId = number;

export interface SongList {
  id?: SongListId; // server generated
  type: SongListType;
  songs: SongListEntry[];
  date: DateString;

  // events
  name?: string;
  time?: TimeString;

  suppressCodaSync?: boolean; // indicates the song list has been edited directly in the app
}

export function wasSongListEdited(songList?: SongList): boolean {
  return Boolean(songList && (songList.name || songList.time || songList.songs.length > 0)); // ignores date
}

interface SongListEntryBase {
  row: number; /* 1-based */
}

export interface BoundSongListEntry extends SongListEntryBase {
  slug: SongSlug;
}

export interface UnboundSongListEntry extends SongListEntryBase {
  text: string;
}

export type SongListEntry = BoundSongListEntry | UnboundSongListEntry;

export interface SongListSchemaEntry extends SongListEntryBase {
  label: string;
  type: SongListEntryType;
}

export interface SongListSchema {
  rows: SongListSchemaEntry[];
}

export function isBound(entry: SongListEntry | undefined): entry is BoundSongListEntry {
  return Boolean(entry && (entry as any).slug);
}

export function isUnbound(entry: SongListEntry | undefined): entry is UnboundSongListEntry {
  return Boolean(entry && (entry as any).text);
}

export type CopyrightPermissions = {[copyrightHolder: string]: string};

export interface HymnalsManifest {
  [hymnalName: string]: HymnalsManifestEntry;
}

export interface HymnalsManifestEntry extends Hashed {
  id: number;
  publisher: string;
  slug: HymnalSlug;
  isInternal?: boolean;
  isChurchSpecific?: boolean;
}

export interface Hashed {
  hash: string;
}

export interface HymnalManifest {
  [songNumber: string]: SongManifestEntry;
}

export type OrganizationKey = string; // e.g. "224 - Christ the King Church (Stillwater MN)"

export interface CustomMusicManifest {[organizationKey: OrganizationKey]: Hashed}

export function getOrgId(organizationKey: OrganizationKey): ChurchId {
  const id = organizationKey.match(/^(?<id>\d+)/)?.groups?.id;
  if (!id) {
    throw new Error(`Unable to parse ID from organization key: ${organizationKey}`);
  }
  return Number(id);
}


export interface Hymn {
  basePath?: string;
  slug: SongSlug;
  hymnal: string; // deprecated - prefer slug
  number: number; // deprecated - prefer slug
  title: string;
  psalm?: string;
  issue?: CopyrightIssue;
  isMidiFileMissing?: boolean;
  verseCount?: number;
  author?: string;
  composer?: string;
  harmony?: string;
  copyright?: string;
  key?: string;
  features?: SongFeature[];
  vocals?: string;
  vocalPartCount?: string;
  beatsPerMeasure?: number[];
  firstMeasureNumber?: number;
  measurePercentages?: number[];
}

export enum SongFeature {
  Vocals = "Vocals",
}

export interface SongManifestEntry {
  title: string,
  hash: string;
  psalm?: string;
  issue?: CopyrightIssue;
  isMidiFileMissing?: boolean;
  features?: SongFeature[];
  copyrights?: SongCopyrightType[];
}

export enum Season {
  Advent = 'Advent',
  Christmas = 'Christmas',
  Epiphany = 'Epiphany',
  LifeOfChrist = 'Life of Christ',
  Transfiguration = 'Transfiguration',
  AshWednesday = 'Ash Wednesday',
  Lent = 'Lent',
  TriumphalEntry = 'Triumphal Entry',
  Passion = 'Christ\'s Passion',
  Resurrection = 'Christ\'s Resurrection',
  Ascension = 'Christ\'s Ascension',
  Pentecost = 'Pentecost',
  AllSaints = 'All Saints',
  Thanksgiving = 'Thanksgiving',
}

export interface HymnalMetadata {
  id: number;
  publisher: string;
  slug: string;
  isInternal?: boolean;
  contents?: {
    seasonal: Array<{season: Season, range: string, betterKnown?: string[]}>
  }
}

export function computeAggregateHash(hashes: Hashed[]): string {
  let content = hashes.reduce((acc, {hash}) => (acc + hash), '');
  return md5.md5(content).substring(0, hashLength);

  // alternatively, we could use the built-in browser sha-1 encoder (no built-in md5)
  // const encoder = new TextEncoder();
  // const data = encoder.encode(content);
  // const hashBuffer = await crypto.subtle.digest('SHA-1', data);
  // const hashArray = Array.from(new Uint8Array(hashBuffer));
  // const hashHex = hashArray
  //   .map((b) => b.toString(16).padStart(2, "0"))
  //   .join(""); // convert bytes to hex string
  // return hashHex.substring(0, hashLength);
}

export type CustomMusic = { [orgId: string]: Hymn[] };

export interface AppHashes {
  appVersion: string;
  customMusicHash: string;
  hymnalsHash: string;
}

export const hashLength = 8

// for test purposes so the server doesn't log an error during client tests
export const InvalidTestCredentials = `Google Y0NTNkYzlkYzNkZDkzM2E0MWVhNTdkYTNlZjI0MmIwZjciLCJ0eXAiOiJKV1QifQ.iOjE2NjAyNDIzNjUsImF1ZCI6Ijk3MjQ4MDczMDU1MC0yOXM0MzFpYzY2aGljNmxkcGlkcDY2c2NnMW81b3A3aC5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjEwMzg4NDk4OTEwMzEyMTg1MDM3OCIsImVtYWlsIjoidGVzdG1haWxjcmVzY2VuZG9AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF6cCI6Ijk3MjQ4MDczMDU1MC0yOXM0MzFpYzY2aGljNmxkcGlkcDY2c2NnMW81b3A3aC5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsIm5hbWUiOiJDb2RlIFRlc3RlciIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BSXRidm1tQnJiNnFaeTRpUXhMYWxwVmd0Ym8zbHhwN3JyME9mQ1psc25oXz1zOTYtYyIsImdpdmVuX25hbWUiOiJDb2RlIiwiZmFtaWx5X25hbWUiOiJUZXN0ZXIiLCJpYXQiOjE2NjAyNDI2NjUsImV4cCI6MTY2MDI0NjI2NSwianRpIjoiMjNlNzg4OTljMGVhYTJjYjJjOTg0NTQwMGNkNDYzZjYxMTZlMjQ2YiJ9.cWgj6TIwOpciUtgkw7JAqpVkPDXImyBANIShJg_uTbfl3eNX-o5nQ18n8nv5UTNnZCqyAOQYDWP3tr_j6bQs7FTUOzfopJL4WxbYKIQvpbtQhQaMtxwrqgmvW76bYNjGoAeED8eh-CDvsk4xpNGeErfjIvDaqnRGMJPRmPjQEjygKvch5sL1U4sPe5K7hrhHqZdZQy-h-VcCu2HuDzs1FW8Pdg--g65YiKMeObWu-wWq45nLzgijiwTRXTqcNwgNP_7GtRinxg5mhdhsskWry3THd3xBuB2BfKKHelr5coQ`

export const AppBundleId = `com.crescendosw.ensemble`;

export enum AppConfig {
  EnableAppleServerWebhookLogging = 'enable-apple-server-webhook-logging',
  SuppressAppUpdateButton = 'suppress-app-update-button', // TODO(hewitt): Remove when app store approves 3.5
  IOSAppNewestReleasedVersion = 'ios-app-newest-released-version',
}

export type AppConfigType<T extends AppConfig> = AppConfigTypeMap[T];
export type AppConfigValues = {[key in AppConfig]: AppConfigType<key>};
export type PartialAppConfigValues = {[key in AppConfig]?: AppConfigType<key>};

interface AppConfigTypeMap {
  [AppConfig.EnableAppleServerWebhookLogging]: boolean,
  [AppConfig.SuppressAppUpdateButton]: boolean,
  [AppConfig.IOSAppNewestReleasedVersion]: number,
}

export const appConfigDefaultValues: {[key in AppConfig]: AppConfigType<key>} = {
  [AppConfig.EnableAppleServerWebhookLogging]: false,
  [AppConfig.SuppressAppUpdateButton]: false,
  [AppConfig.IOSAppNewestReleasedVersion]: 3.6,
}

export enum HouseholdOriginSource {
  Church = 'church',
  School = 'school',
  Friend = 'friend',
  Self = 'self',
}

export enum ConfidenceLevel {
  Unable = 'unable',
  Sometimes = 'sometimes',
  Regularly = 'regularly',
  Choir = 'choir',
  Leader = 'leader',
}

export enum ExpectedUsage {
  PostSongLists = 'post-song-lists',
  Choir = 'choir',
  SmallGroup = 'small-group',
  Home = 'home',
  Harmony = 'harmony',
}

export enum HouseholdSchedule {
  Morning = 'morning',
  Lunch = 'lunch',
  Dinner = 'dinner',
  Bedtime = 'bedtime',
}

export interface HouseholdSurveyResponses {
  origin?: HouseholdOriginSource;
  schedule?: HouseholdSchedule[];
  optedIntoNotifications?: boolean;
  sharedWithChurch?: boolean;
  sharedWithFamily?: boolean;
  choseOrganization?: boolean; // true = picked a church, false = church wasn't listed, undefined = didn't show the page
}

export enum SocketMessageType {
  UpdateWeeklySongLists = 'update-weekly-song-lists',
}

export interface SocketMessage {
  type: SocketMessageType;
}

export interface UpdateWeeklySongListMessage extends SocketMessage {
  churchId: ChurchId;
}

export function isUpdateWeeklySongListMessage(message: any): message is UpdateWeeklySongListMessage {
  return Boolean(message && (message as SocketMessage).type === SocketMessageType.UpdateWeeklySongLists);
}

export enum EmailType {
  ThisSundaySongListRequest = 'this-sunday-song-list-request',
}

export type EmailInfo = ThisSundaySongListRequestEmail;  // add more email interfaces in future with |

export interface ThisSundaySongListRequestEmail {
  type: EmailType.ThisSundaySongListRequest;
  date: string;
  churchToken: ChurchToken;
}

export interface EmailReceipt {
  emailInfo: EmailInfo;
  fromEmail: string;
  fromName?: string;
  recipients: MessageRecipient[];
  initiator: Initiator;
  subject: string;
  body: string;
}

/* ripped off from mailchimp */
type MessageRecipientType = "to" | "cc" | "bcc";
interface MessageRecipient {
  email: string;
  name?: string;
  type?: MessageRecipientType;
}

// feel free to extend this type as necessary
export interface Initiator {
  householdId: number
}
