import React, {useCallback, useRef} from 'react';
import {uploadChurchCustomMusicFile} from '../../../../../common/server_api';
import {alert} from '../../../../util/confirm';
import styled from 'styled-components';
import {useHousehold} from '../../../../data/use_household';

export function UploadButton() {
  const fileChooserInput = useRef<HTMLInputElement>(null);
  const {household} = useHousehold();

  const onClickUploadMusicXMLFile = useCallback(() => {
    fileChooserInput?.current?.click();
  }, [fileChooserInput]);

  const getFileInfo = useCallback((): {file: File, churchId: number, songNumber: string} => {
    const file = fileChooserInput?.current?.files?.[0];
    if (!file || !file.name.toLowerCase().endsWith('.xml')) {
      throw new Error(`Expected a file name ending in .xml`);
    }
    const {churchId, songNumber} = file.name.match(/^(?<churchId>\d+)-(?<songNumber>\d+).*/)?.groups ?? {};
    if (!churchId) {
      throw new Error('Filename not prefixed with church ID');
    }
    return {file, churchId: Number(churchId), songNumber};
  }, [fileChooserInput]);

  const onFileChosen = useCallback(async () => {
    try {
      const {file, churchId, songNumber} = getFileInfo();
      await uploadChurchCustomMusicFile({churchId, songNumber, file, household});
      await alert({confirmation: <span>File upload successful.</span>})
    } catch (error: any) {
      await alert({confirmation: <span>Error: {error.message}</span>})
    }
  }, [getFileInfo, household]);

  return (
    <>
      <input type="file" style={{display: "none"}} ref={fileChooserInput} onChange={onFileChosen}/>
      <UploadButtonWrapper onClick={onClickUploadMusicXMLFile}>Upload Music XML</UploadButtonWrapper>
    </>
  );
}

const UploadButtonWrapper = styled.button`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.3rem;
  color: var(--color-text);
  padding: 0 15px;
  margin: 10px 0;
  border: 2px solid var(--color-text);
  border-radius: 10px;
  background-color: var(--color-background);
  cursor: pointer;
`;
