import {Tile} from './shared';
import {OrganizationPage} from '../organization_page';
import {getWeeklySongListType, SongListType} from '../../../common/model';
import {DateString, getWeeklyDateForOrganization} from '../../../common/date_string';
import {useCallback, useState} from 'react';
import styled from 'styled-components/macro';
import {useChurch} from '../../data/use_church';

export const ThisSundayTile = () => {
  const [hidden, setHidden] = useState(true);
  const {church} = useChurch();
  const checkSongLists = useCallback((songLists: Array<{type: SongListType, date: DateString}> | undefined) => {
    setHidden(songLists?.find(songList =>
      songList.type === getWeeklySongListType(church) &&
      songList.date === getWeeklyDateForOrganization(church)) === undefined);
  }, [church]);
  return (
    <Tile $thinTopPadding={true} $hidden={hidden}>
      <PageWrapper>
        <OrganizationPage showThisSundayOnly={true} checkSongLists={checkSongLists}/>
      </PageWrapper>
    </Tile>
  );
}

const PageWrapper = styled.div`
  padding-bottom: 15px;
`;
