import {LocalStorageKey, localStorageSet, removeGuestKeys} from "../data/client_local_storage";
import {favorites} from "../data/favorites";
import {useLocalStorage} from "../data/use_local_storage";
import {useCallback} from "react";

export function useAuthenticationHelper() {

  const [, setUser] = useLocalStorage(LocalStorageKey.User);
  const setUserAndCredentials = useCallback((
    user: {email: string, name?: string}, credentials: string, userToken: string
  ) => {
    setUser(user);  // remove 4/2025
    localStorageSet(LocalStorageKey.UserToken, userToken);  // remove 4/2025
    localStorageSet(LocalStorageKey.ActiveUserToken, userToken);

    removeGuestKeys();

    void favorites.reconcileWithServer();
  }, [setUser]);
  return {setUserAndCredentials};
}