import styled from 'styled-components/macro';
import {
  DateRowTop, DisplayUnauthorizedAlert,
  LiturgyYearHeight,
  SongCellPadding,
  SongRowHeightDouble,
  SongRowHeightSingle,
  SongRowWidth
} from './shared';
import {useChurch} from '../../../data/use_church';
import React, {ChangeEvent, MouseEvent, KeyboardEvent, useCallback, useEffect, useRef, useState} from 'react';
import {getWeeklySongListType, SongListEntryType} from '../../../../common/model';
import {focusNextTabStop} from '../../../util/shared';
import {ReactComponent as EighthNoteIcon} from "../../../assets/eighth-note.svg";
import {ReactComponent as BibleIcon} from "../../../assets/bible.svg";
import {ReactComponent as PersonIcon} from "../../../assets/person.svg";
import {ReactComponent as TrashIcon} from "../../../assets/trash.svg";
import {capitalize, ensureUnreachable} from '../../../../common/util';
import {setSongListSchemaLabel, setSongListSchemaType} from './song_list_schema';
import {ReactComponent as WedgeUpIcon} from "../../../assets/wedge-up.svg";
import {ReactComponent as WedgeDownIcon} from "../../../assets/wedge-down.svg";
import {AllSongLists} from './use_all_song_lists';
import {confirm} from '../../../util/confirm';
import {useUserAttributes} from '../../../data/use_user_attributes';

interface Props {
  allSongLists: AllSongLists;
  songRowCount: number;
}

export function LiturgySchemaColumn({allSongLists, songRowCount}: Props) {
  const {isInternalUser, isChurchAdmin} = useUserAttributes();
  const [newRowIndex, setNewRowIndex] = useState<number | undefined>(); // for selecting new rows
  return (
    <LiturgySchemaWrapper>
      <YearRow/>
      <WeekRow>Sunday</WeekRow>
      <SongRow
        key='hymn-of-the-month'
        rowIndex={-1}
        allSongLists={allSongLists}
        newRowIndex={newRowIndex}
        setNewRowIndex={setNewRowIndex}
        isInternalUser={isInternalUser}
        isChurchAdmin={isChurchAdmin}
      />
      {
        [...Array(songRowCount).keys()].map((index) =>
          <SongRow
            key={index + 1}
            rowIndex={index + 1}
            allSongLists={allSongLists}
            newRowIndex={newRowIndex}
            setNewRowIndex={setNewRowIndex}
            isChurchAdmin={isChurchAdmin}
            isInternalUser={isInternalUser}
          />
        )
      }
    </LiturgySchemaWrapper>
  );
}

const LiturgySchemaWrapper = styled.div`
  z-index: 5; // above liturgy row content, but below year
  display: flex;
  flex-direction: column;
  position: sticky;
  left: 0;
`;

const YearRow = styled.div`
  height: ${LiturgyYearHeight};
  position: sticky;
  top: 0;
  background-color: var(--color-background);
`;

function SongRow({allSongLists, rowIndex, newRowIndex, setNewRowIndex, isChurchAdmin, isInternalUser}: {
  allSongLists: AllSongLists;
  rowIndex: number;
  newRowIndex: number | undefined;
  setNewRowIndex: (newRowIndex: number | undefined) => void;
  isChurchAdmin: boolean;
  isInternalUser: boolean;
}) {
  const {church, updateChurchInfo} = useChurch();
  const schema = church?.churchLiturgySchema;
  const row =  schema?.rows.find(({row}) => row === rowIndex);
  const isHymnOfTheMonth = rowIndex < 0;
  const [value, setValue] = useState(row?.label ?? '');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (rowIndex === newRowIndex) {
      inputRef.current?.focus();
      setNewRowIndex(undefined);
    }
  }, [inputRef, rowIndex, newRowIndex, setNewRowIndex]);

  useEffect(() => {
    setValue(row?.label ?? '');
  }, [row?.label]);

  const onLabelBlur = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!church) {
      return;
    }
    const label = (event.target as HTMLInputElement).value;
      updateChurchInfo({...church, churchLiturgySchema: setSongListSchemaLabel(schema, rowIndex, label)});
  }, [church, rowIndex, schema, updateChurchInfo]);

  const onKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    // Treat <Enter> keypress like <Tab>
    if (event.key === 'Enter') {
      focusNextTabStop(event.target as HTMLInputElement);
    }
  }, []);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const onMouseDown = useCallback((event: MouseEvent<HTMLInputElement>) => {
    if (!isChurchAdmin && !isInternalUser) {
      void DisplayUnauthorizedAlert();
      event.preventDefault();
      return;
    }
  }, [isChurchAdmin, isInternalUser]);

  return (
    <SongRowWrapper $isDoubleHeight={isHymnOfTheMonth}>
      <RowCellWrapper key='cell'>
        {isHymnOfTheMonth ? 'Hymn of the Month' : (
          <RowInput
            value={value}
            onMouseDown={onMouseDown}
            onBlur={onLabelBlur}
            onKeyDown={onKeyDown}
            onChange={onChange}
            ref={inputRef}/>
        )}
      </RowCellWrapper>
      {!isHymnOfTheMonth &&
        <TypeIcon
          allSongLists={allSongLists}
          key='icon'
          type={row?.type ?? SongListEntryType.Song}
          rowIndex={rowIndex}
          setNewRowIndex={setNewRowIndex}
          isChurchAdmin={isChurchAdmin}
          isInternalUser={isInternalUser}
        />
      }
    </SongRowWrapper>
  );
}

const SongRowWrapper = styled.div<{$isDoubleHeight?: boolean}>`
  font-family: Jost-SemiBold, Arial, sans-serif;
  height: ${props => props.$isDoubleHeight ? SongRowHeightDouble : SongRowHeightSingle};
  width: ${SongRowWidth};
  outline: 1px solid var(--color-text); /* outline instead of border takes up 0 space */
  background-color: var(--color-liturgy-row-header);
  padding: ${SongCellPadding};
  display: flex;
  align-items: center;
`;

const WeekRow = styled(SongRowWrapper)`
  position: sticky;
  top: ${DateRowTop};
`;

const RowCellWrapper = styled.div`
  text-align: left;
`;

const TypeIconWidth = '15px';
const TypeIconHeight = '18px';

const RowInput = styled.input`
  background-color: transparent;
  border: none;
  width: calc(${SongRowWidth} - (2 * ${SongCellPadding}) - ${TypeIconWidth});
`;

function TypeIcon({allSongLists, rowIndex, setNewRowIndex, type, isChurchAdmin, isInternalUser}: {
  allSongLists: AllSongLists;
  rowIndex: number;
  setNewRowIndex: (newRowIndex: number) => void;
  type: SongListEntryType;
  isChurchAdmin: boolean;
  isInternalUser: boolean;
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const onClick = useCallback((event: MouseEvent<HTMLInputElement>) => {
    if (!isChurchAdmin && !isInternalUser) {
      void DisplayUnauthorizedAlert();
      event.preventDefault();
      return;
    }
    setMenuOpen(!menuOpen);
  }, [isChurchAdmin, isInternalUser, menuOpen]);

  const onBlur = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <TypeIconWrapper tabIndex={rowIndex + 1000} onClick={onClick} onBlur={onBlur}>
      {getTypeIcon(type)}
      {menuOpen && (
        <TypeMenu>
          {
            Object.values(SongListEntryType).map((item) => (
              <TypeMenuEntry key={item} type={item} rowIndex={rowIndex}/>
            ))
          }
          <InsertMenuEntry
            allSongLists={allSongLists}
            isBefore={true}
            rowIndex={rowIndex}
            setNewRowIndex={setNewRowIndex}/>
          <InsertMenuEntry
            allSongLists={allSongLists}
            isBefore={false}
            rowIndex={rowIndex}
            setNewRowIndex={setNewRowIndex}/>
          <DeleteRow allSongLists={allSongLists} rowIndex={rowIndex} />
        </TypeMenu>
      )}
    </TypeIconWrapper>
  );
}

function InsertMenuEntry({allSongLists, isBefore, rowIndex, setNewRowIndex}: {
  allSongLists: AllSongLists;
  isBefore: boolean;
  rowIndex: number;
  setNewRowIndex: (newRowIndex: number) => void;
}) {
  const {church, updateChurchInfo} = useChurch();
  const schema = church?.churchLiturgySchema;

  const onInsertRow = useCallback(async () => {
    const newRowIndex = isBefore ? rowIndex : rowIndex + 1;
    if (church && schema && schema.rows.length > 0) {
      updateChurchInfo({...church, churchLiturgySchema: {...schema,
          rows: schema.rows.map(row => row.row >= newRowIndex ? {...row, row: row.row + 1} : row)
        }});
    }
    await allSongLists.insertNewRowAt(newRowIndex);
    setNewRowIndex(newRowIndex);
  }, [allSongLists, church, isBefore, rowIndex, schema, setNewRowIndex, updateChurchInfo]);

  return (
    <TypeMenuEntryWrapper
      $isFirst={false}
      $isLast={false}
      $borderTop={isBefore}
      $borderBottom={!isBefore}
      onClick={onInsertRow}
    >
      {isBefore ? <InsertBeforeIconWrapper/> : <InsertAfterIconWrapper/>}
      Insert {isBefore ? 'Before' : 'After'}
    </TypeMenuEntryWrapper>
  );
}

const TypeIconWrapper = styled.div`
`;

const menuBorderRadius = '10px';

const TypeMenu = styled.div`
  z-index: 8;
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-text);
  border-radius: ${menuBorderRadius};
  background-color: var(--color-background);
  width: max-content;
`;

const TypeMenuEntry = function({type, rowIndex}: {type: SongListEntryType, rowIndex: number}) {
  const entryTypes = Object.values(SongListEntryType)
  const isFirst = entryTypes.indexOf(type) === 0;
  const isLast = entryTypes.indexOf(type) === entryTypes.length - 1;
  const {church, updateChurchInfo} = useChurch();

  const onClick = useCallback(() => {
    if (!church) {
      return;
    }
    const schema = church?.churchLiturgySchema;
    updateChurchInfo({...church, churchLiturgySchema: setSongListSchemaType(schema, rowIndex, type)});
  }, [church, rowIndex, type, updateChurchInfo]);

  return (
    <TypeMenuEntryWrapper $isFirst={isFirst} $isLast={false} $borderBottom={isLast} onClick={onClick}>
      {getTypeIcon(type)}
      {capitalize(type)}
    </TypeMenuEntryWrapper>
  );
}

function getVerticalMenuPadding(border?: boolean) {
  return border ? '9px' : '5px';
}

const TypeMenuEntryWrapper = styled.div<{
  $isFirst: boolean;
  $isLast: boolean;
  $borderTop?: boolean;
  $borderBottom?: boolean;
}>`
  text-align: left;
  cursor: pointer;
  display: flex;
  padding: ${props => getVerticalMenuPadding(props.$borderTop)} 15px ${props => getVerticalMenuPadding(props.$borderBottom)} 12px;
  gap: 10px;
  align-items: center;
  ${props => props.$borderTop &&`
    border-top: 1px solid var(--color-text);
  `}
  // avoid clipping rounded menu border, give a little breathing room to top & bottom of menu
  ${props => props.$isFirst &&`
    border-top-left-radius: ${menuBorderRadius};
    border-top-right-radius: ${menuBorderRadius};
    padding-top: 8px;
  `}
  ${props => props.$isLast &&`
    border-bottom-left-radius: ${menuBorderRadius};
    border-bottom-right-radius: ${menuBorderRadius};
    padding-bottom: 8px;
  `}
  &:hover {
    background: var(--color-background-selected-item);
  }
`;

const TypeTextWrapper = styled.div`
  font-family: "Times New Roman", Times, serif;
  font-size: 1.2rem;
  font-weight: bold;
  width: ${TypeIconWidth};
  cursor: pointer;
`;

const EighthNoteIconWrapper = styled(EighthNoteIcon)`
  width: ${TypeIconWidth};
  height: ${TypeIconHeight};
  cursor: pointer;
  fill: var(--color-text);
`;

const BibleIconWrapper = styled(BibleIcon)`
  width: ${TypeIconWidth};
  height: ${TypeIconHeight};
  cursor: pointer;
  fill: var(--color-text);
`

const PersonIconWrapper = styled(PersonIcon)`
  width: ${TypeIconWidth};
  height: ${TypeIconHeight};
  cursor: pointer;
  fill: var(--color-text);
`

function getTypeIcon(type: SongListEntryType) {
  switch (type) {
    case SongListEntryType.Song:
      return <EighthNoteIconWrapper/>;
    case SongListEntryType.Assignment:
      return <PersonIconWrapper/>;
    case SongListEntryType.Scripture:
      return <BibleIconWrapper/>
    case SongListEntryType.Text:
      return <TypeTextWrapper>T</TypeTextWrapper>
    default:
      ensureUnreachable(type);
  }
}

function DeleteRow({allSongLists, rowIndex}: {allSongLists: AllSongLists, rowIndex: number}) {
  const {church, updateChurchInfo} = useChurch();
  const row = church?.churchLiturgySchema?.rows.find(({row}) => row === rowIndex);

  const getCountVerbiage = useCallback(() => {
    const entryCount = allSongLists.filter(({type, songs}) =>
      type === getWeeklySongListType(church) && !!songs.find(({row}) => row === rowIndex)).length;
    if (entryCount === 0) {
      return '';
    } else if (entryCount === 1) {
      return ' and one cell value';
    } else if (entryCount === 2) {
      return ' and both cell values';
    } else {
      return ` and all ${entryCount} cell values`;
    }
  }, [allSongLists, church, rowIndex]);

  const onClick = useCallback(async () => {
    const labelVerbiage = row?.label ? ` “${row.label}”` : '';
    if (await confirm({confirmation: <span>Delete row{labelVerbiage}{getCountVerbiage()}?</span>})) {
      const schema = church?.churchLiturgySchema;
      if (church && schema && schema.rows.length > 0) {
        updateChurchInfo({...church, churchLiturgySchema: {...schema,
          rows: schema.rows
            .filter(({row}) => row !== rowIndex) // filter out matching row
            .map(row => row.row >= rowIndex ? {...row, row: row.row - 1} : row) // bump following rows up
        }});
      }
      await allSongLists.deleteRowAt(rowIndex);
    }
  }, [allSongLists, church, getCountVerbiage, row, rowIndex, updateChurchInfo]);

  return (
    <DeleteMenuEntryWrapper $isFirst={false} $isLast={true} $borderTop={true} onClick={onClick}>
      <TrashIconWrapper/>
      Delete Row
    </DeleteMenuEntryWrapper>
  )
}

const DeleteMenuEntryWrapper = styled(TypeMenuEntryWrapper)`
  color: var(--color-trash);
  fill: var(--color-trash);
`;

const TrashIconWrapper = styled(TrashIcon)`
  width: ${TypeIconWidth};
  height: ${TypeIconHeight};
  cursor: pointer;
`;

const InsertBeforeIconWrapper = styled(WedgeUpIcon)`
  width: ${TypeIconWidth};
  height: ${TypeIconHeight};
  cursor: pointer;
  fill: var(--color-text);
`;

const InsertAfterIconWrapper = styled(WedgeDownIcon)`
  width: ${TypeIconWidth};
  height: ${TypeIconHeight};
  cursor: pointer;
  fill: var(--color-text);
`;
