import {useCallback} from 'react';
import {localStorageGet, LocalStorageKey, localStorageRemove, localStorageSet} from './client_local_storage';
import {useLocalStorage} from './use_local_storage';
import {Household, HouseholdStatus} from '../../common/model';
import * as server_api from '../../common/server_api';
import {synchronizeMobileHouseholdStatus} from '../util/billing';
import {OneDayMs} from '../../common/sleep';

export function useHousehold(): {
  household: Household | undefined,
  setHousehold: (household: Household | undefined) => void
} {

  const [household, setHousehold] = useLocalStorage(LocalStorageKey.Household);

  const setHouseholdAndPushToServer = useCallback((newHousehold: Household | undefined) => {

    setHousehold(newHousehold);

    localStorageSet(LocalStorageKey.PushHousehold, true);

    void synchronizeHouseholdWithServer();

  }, [setHousehold]);

  return {household, setHousehold: setHouseholdAndPushToServer};
}

// syncs once per day unless forced
export async function synchronizeHouseholdWithServer({force}: {force?: boolean} = {}) {
  let household = localStorageGet(LocalStorageKey.Household);
  const pushHousehold = localStorageGet(LocalStorageKey.PushHousehold);

  // The Apple billing code had been creating households without a token -> need to nuke these to enable onboarding
  if (household && !household.token) {
    localStorageRemove(LocalStorageKey.Household);
    household = undefined;
  }

  if (household && !force && !pushHousehold &&
    household?.mostRecentSyncTimestamp && (Date.now() - household.mostRecentSyncTimestamp) < OneDayMs
  ) {
    return;
  }

  // ignore network errors
  try {
    if (household && pushHousehold && household?.token) {
      await server_api.upsertHousehold(household);
      localStorageRemove(LocalStorageKey.PushHousehold);
    }

    // go ahead and pull down the server household even if we just pushed it up so everything is in sync
    if (household && household?.token) {
      const serverHousehold = await getHouseholdFromServer(household.token); // CAN THROW!
      if (serializeHousehold(household) !== serializeHousehold(serverHousehold)) {
        localStorageSet(LocalStorageKey.Household, serverHousehold);
      }
    }

    // If the server says the household is subscribed, then we don't consult the client.
    // On iOS, this means a different household token can be used than the currently subscribed iOS token,
    // but only if the other token is subscribed.
    // If the other household token is not subscribed but iOS has a subscription, iOS will smash the
    // household token the one used for the iOS family subscription.
    // This enables people in the same iCloud household to be automatically configured with the same token.
    // Note that the household last name & email can still be updated regardless.
    if (household?.status !== HouseholdStatus.Subscribed) {
      await synchronizeMobileHouseholdStatus();
    }
  }
  catch {}
}

function serializeHousehold(household: Household | undefined): string | undefined {
  if (!household) {
    return undefined;
  }
  // avoid taking into acount the sync timestamp when comparing household contents
  const {mostRecentSyncTimestamp: _unused, ...householdWithoutTimestamp} = household;
  return JSON.stringify(householdWithoutTimestamp);
}

export async function getHouseholdFromServer(householdToken: string): Promise<Household | undefined> {
  const household = await server_api.getHousehold({householdToken});
  if (!household) {
    return undefined;
  }
  setHouseholdStatus(household);
  household.mostRecentSyncTimestamp = Date.now();
  return household;
}

function setHouseholdStatus(household?: Household): HouseholdStatus | undefined {
  if (!household) {
    return;
  }
  if (household.isAdminForChurchId ||
    (
      household.subscriptionExpirationTimestamp &&
      household.subscriptionExpirationTimestamp > new Date().getTime()
    )
  ) {
    household.status = HouseholdStatus.Subscribed;
  } else {
    household.status = HouseholdStatus.Unsubscribed;
  }
}

export function deleteHouseholdLocalStorageEntries() {
  localStorageRemove(LocalStorageKey.Household);
  localStorageRemove(LocalStorageKey.Church);
  localStorageRemove(LocalStorageKey.WeeklySongList);
}
