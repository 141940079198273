/*
Date counts months starting at zero
Date.getDay() returns a number 0-6 representing the days of the week. 0=sun 1=mon, etc.
*/

import {dateFromString, DateString, validateDateString} from './date_string';

/**
 * Return the date of Easter in the Gregorian calendar for the given year.
 */
function easterDate(year: number): Date {
  if (year < 1583 || year > 9999) {
    throw new Error("Year out of bounds");
  }
  let a = BigInt(year) % 19n;
  let b = BigInt(year) / 100n;
  let c = BigInt(year) % 100n;
  let d = b / 4n;
  let e = b % 4n;
  let f = (b + 8n) / 25n;
  let g = (b - f + 1n) / 3n;
  let h = (19n * a + b - d - g + 15n) % 30n;
  let i = c / 4n;
  let j = c % 4n;
  let k = (32n + 2n * e + 2n * i - h - j) % 7n;
  let l = (a + 11n * h + 22n * k) / 451n;
  let month = (h + k - 7n * l + 114n) / 31n;
  let day = (h + k - 7n * l + 114n) % 31n + 1n;
  return new Date(Number(year), Number(month) - 1, Number(day));
}

function isEaster(date: Date): boolean {
  const easter = easterDate(date.getFullYear());
  return date.getMonth() === easter.getMonth() && date.getDate() === easter.getDate();
}

function getSundayBeforeHoliday(holiday: Date): Date {

  let offset = holiday.getDay() === 0 ? 7 : holiday.getDay();

  const sundayBeforeHoliday = new Date(holiday.getFullYear(), holiday.getMonth(), holiday.getDate() - offset);
  return sundayBeforeHoliday;
}

function isSundayBeforeHoliday(date: Date, holiday: Date): boolean {

  const sundayBeforeHoliday = getSundayBeforeHoliday(holiday);
  return date.getTime() === sundayBeforeHoliday.getTime();
}

function getSundayAfterHoliday(holiday: Date): Date {

  const offset = 7 - holiday.getDay();

  const sundayAfterHoliday = new Date(holiday.getFullYear(), holiday.getMonth(), holiday.getDate() + offset );
  return sundayAfterHoliday;
}

function isSundayAfterHoliday(date: Date, holiday: Date): boolean {

  const sundayAfterHoliday = getSundayAfterHoliday(holiday);
  return date.getTime() === sundayAfterHoliday.getTime();
}

function getFutureSunday(date: Date, numSundays: number): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7*numSundays);
}

function getPastSunday(date: Date, numSundays: number): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7*numSundays);
}

export function getNameForSunday(dateString: DateString): string {

  validateDateString(dateString);
  const date = dateFromString(dateString);

  if (date.getDay() !== 0) {
    throw new Error(`Date: ${date} is not a sunday`);
  }

  const thisChristmas: Date = new Date(date.getFullYear(), 11, 25);
  const lastChristmas: Date = new Date(date.getFullYear() - 1, 11, 25);
  const epiphany: Date = new Date(date.getFullYear(), 0, 6);
  const reformationDay: Date = new Date(date.getFullYear(), 9, 31);
  const allSaintsDay: Date = new Date(date.getFullYear(), 10, 1);

  const weeksBetweenEasterAndAscensionSunday = 6;
  const weeksBetweenEasterAndPentecost = weeksBetweenEasterAndAscensionSunday + 1;
  const weeksBetweenEasterAndOrdinary = weeksBetweenEasterAndPentecost + 1;
  const weeksOfLent = 6;
  const weeksOfEaster = 6;
  const weeksOfAdvent = 4;
  const minWeeksOfEpiphany = 4;
  const maxWeeksOfEpiphany = 9;

  const romanNumerals = [
    'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X',
    'XI', 'XII', 'XIII', 'XIV', 'XV', 'XVI', 'XVII', 'XVIII', 'XIX', 'XX',
    'XXI', 'XXII', 'XXIII', 'XXIV', 'XXV', 'XXVI', 'XXVII', 'XXVIII', 'XXIX','XXX'
  ];

  if (lastChristmas.getDay() !== 1 && lastChristmas.getDay() !== 2) {

    if (date.getTime() === getFutureSunday(getSundayAfterHoliday(lastChristmas), 1).getTime() && date.getTime() !== epiphany.getTime()) {
      return 'Christmas II'
    }
  }

  if (date.getTime() === epiphany.getTime()) {
    return 'Epiphany'
  }
  if (isSundayAfterHoliday(date, epiphany)) {
    return 'Epiphany I'
  }

  for (let i = 1; i < minWeeksOfEpiphany; i++) {
    if (date.getTime() === getFutureSunday(getSundayAfterHoliday(epiphany), i).getTime()) {
      return `Epiphany ${romanNumerals[i]}`
    }
  }

  // Epiphany can be 4-9 weeks depending on when easter starts, so give lent priority over later epiphany weeks
  for (let i = weeksOfLent; i > 0; i--) {
    if (isEaster(getFutureSunday(date, i))) {
      return `Lent ${romanNumerals[Math.abs(i - weeksOfLent)]}`
    }
  }

  for (let i = minWeeksOfEpiphany; i < maxWeeksOfEpiphany; i++) {
    if (date.getTime() === getFutureSunday(getSundayAfterHoliday(epiphany), i).getTime()) {
      return `Epiphany ${romanNumerals[i]}`
    }
  }

  if (isEaster(date)) {
    return 'Easter';
  }

  for (let i = 1; i < weeksOfEaster; i++) {
    if (isEaster(getPastSunday(date, i))) {
      return `Easter ${romanNumerals[i]}`
    }
  }

  if (isEaster(getPastSunday(date, weeksBetweenEasterAndAscensionSunday))) {
    return 'Ascension Sunday';
  }

  if (isEaster(getPastSunday(date, weeksBetweenEasterAndPentecost))) {
    return 'Pentecost';
  }

  if (date.getTime() === reformationDay.getTime()) {
    return 'Reformation Day'
  }
  if (date.getTime() === getSundayBeforeHoliday(allSaintsDay).getTime()) {
    return 'Reformation Sunday'
  }

  if (date.getTime() === allSaintsDay.getTime()) {
    return 'All Saints Day'
  }
  if (date.getTime() === getSundayAfterHoliday(allSaintsDay).getTime()) {
    return 'All Saints Sunday'
  }

  for (let i = 0; i < weeksOfAdvent; i++) {
    if (isSundayBeforeHoliday(getFutureSunday(date, i), thisChristmas)) {
      return `Advent ${romanNumerals[Math.abs(i-(weeksOfAdvent-1))]}`
    }
  }

  if (date.getTime() === thisChristmas.getTime()) {
    return 'Christmas'
  }
  if (isSundayAfterHoliday(date, thisChristmas)) {
    return 'Christmas I'
  }

  for (let i = weeksBetweenEasterAndOrdinary; i < (romanNumerals.length + weeksBetweenEasterAndOrdinary); i++) {

    if (isEaster(getPastSunday(date, i))) {
      const isAfterAllSaintsDay = date.getTime() > allSaintsDay.getTime();
      const sundaysToSubtract = isAfterAllSaintsDay ? 2 : 0;
      const romanNumeral = romanNumerals[i - weeksBetweenEasterAndOrdinary - sundaysToSubtract];

      return `Ordinary Sunday ${romanNumeral}`
    }
  }

  return 'Ordinary Sunday'
}