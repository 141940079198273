import {DateString} from '../../../../common/date_string';
import React, {useCallback, useState} from 'react';
import {CellPosition} from './liturgy_planner';

export function useLiturgyKeyboardNavigation({areDatesVertical, defaultDate, nextDate, prevDate, rowCount}: {
  areDatesVertical?: boolean;
  defaultDate: DateString;
  nextDate?: (date: DateString) => DateString;
  prevDate?: (date: DateString) => DateString;
  rowCount: number;
}) {
  const [selectedCell, setSelectedCell] = useState<CellPosition>({date: defaultDate, row: 1});
  const {date, row} = selectedCell;

  const moveDown = useCallback(() => {
    setSelectedCell({date, row: ((row) % rowCount) + 1}); // 1 based
  }, [date, row, rowCount]);

  const moveUp = useCallback(() => {
    setSelectedCell({date, row: ((row - 2 + rowCount) % rowCount) + 1}); // 1 based
  }, [date, row, rowCount]);

  const moveRight = useCallback(() => {
    if (!nextDate) {
      return;
    }
    setSelectedCell({date: nextDate(date), row: row});
  }, [date, nextDate, row]);

  const moveLeft = useCallback(() => {
    if (!prevDate) {
      return;
    }
    setSelectedCell({date: prevDate(date), row: row});
  }, [date, prevDate, row]);

  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown':
        if (areDatesVertical) {
          moveRight();
        } else {
          moveDown();
        }
        event.preventDefault();
        break;
      case 'ArrowUp':
        if (areDatesVertical) {
          moveLeft();
        } else {
          moveUp();
        }
        event.preventDefault();
        break;
      case 'Tab':
        if (nextDate) {
          event.getModifierState('Shift') ? moveLeft() : moveRight();
        } else {
          event.getModifierState('Shift') ? moveUp() : moveDown();
        }
        event.preventDefault();
        break;
      case 'ArrowRight':
        if (areDatesVertical) {
          return;
        }
        moveRight();
        event.preventDefault();
        break;
      case 'ArrowLeft':
        if (areDatesVertical) {
          return;
        }
        moveLeft();
        event.preventDefault();
        break;
      default:
        return; // IMPORTANT: return early to avoid stopping propagation!
    }
  }, [areDatesVertical, moveDown, moveLeft, moveRight, moveUp, nextDate]);

  return {moveDown, onKeyDown, selectedCell, setSelectedCell};
}
