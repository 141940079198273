import styled from 'styled-components/macro';
import React, {useCallback, useEffect, useRef} from 'react';
import {SongCell} from './song_cell';
import {SongList, SongListEntryType, SongListType} from '../../../../common/model';
import {useAllSongLists} from './use_all_song_lists';
import {
  createDateString,
  dateFromString,
  DateString,
  getMonthName,
} from '../../../../common/date_string';
import {synchronizeAllSongLists} from '../../../data/use_church';
import {getPlatform, Platform} from '../../../util/platform';
import {useCustomMusic} from '../../../data/use_custom_music';
import {useHymnals} from '../../../data/use_hymnals';
import {useUserAttributes} from '../../../data/use_user_attributes';
import {useLiturgyKeyboardNavigation} from './use_liturgy_keyboard_navigation';

export interface HymnOfTheMonthPlannerProps {
  songList: SongList;
  onClose: () => void;
}

const monthsInYear = 12;

export const HymnOfTheMonthPlanner = ({songList, onClose}: HymnOfTheMonthPlannerProps) => {
  const year = dateFromString(songList.date).getFullYear();
  const dates = [...Array(monthsInYear).keys()].map(month => createDateString(year, month + 1, 1));
  const allSongLists = useAllSongLists();
  let hymnsOfTheMonth = dates.map(date =>
    allSongLists.find(songList => songList.type === SongListType.HymnOfTheMonth && songList.date === date) ??
    {type: SongListType.HymnOfTheMonth, date, songs: []}
  );
  const dialogElement = useRef<HTMLDialogElement>(null);
  const customMusic = useCustomMusic();
  const hymnals = useHymnals();
  const {isInternalUser, isChurchAdmin} = useUserAttributes();

  const onCloseDialog = useCallback(async () => {
    void synchronizeAllSongLists();
    onClose();
  }, [onClose]);

  const onClickBackground = useCallback(async (event: MouseEvent) => {
    if (event.target === dialogElement.current) {
      await onCloseDialog();
    }
  }, [onCloseDialog]);

  useEffect(() => {
    const {current} = dialogElement;
    current?.showModal();
    current?.addEventListener('click', onClickBackground);
    return () => {current?.removeEventListener('click', onClickBackground)}
  });

  const getTitle = () => {
    const year = `${dateFromString(songList.date).getFullYear()}`;
    const onMobile = getPlatform() === Platform.Mobile;
    return onMobile ? year : `${year} Hymns of the Month`;
  }

  const nextDate = useCallback((date: DateString) => dates[(dates.indexOf(date) + 1) % dates.length], [dates]);
  const prevDate = useCallback((date: DateString) =>
    dates[(dates.indexOf(date) - 1 + dates.length) % dates.length], [dates]);
  const {moveDown, onKeyDown, selectedCell, setSelectedCell} = useLiturgyKeyboardNavigation({
    defaultDate: songList.date,
    rowCount: monthsInYear,
    nextDate,
    prevDate,
    areDatesVertical: true,
  });

  return (
    <Dialog ref={dialogElement}>
      <DialogContent>
        <YearTitle>{getTitle()}</YearTitle>
        <CloseButton key='close' onClick={onCloseDialog}>Done</CloseButton>
          <ScrollWrapper key='scroll-wrapper'>
            <SongCellsWrapper onKeyDown={onKeyDown}>
              {
                hymnsOfTheMonth.map((hymn, index) => {
                  return (
                    <SongCell
                      key={index}
                      date={hymn.date}
                      row={1}
                      label={`${getMonthName(hymn.date)}`}
                      moveDown={moveDown}
                      songList={hymn}
                      songListType={SongListType.HymnOfTheMonth}
                      songListEntryType={SongListEntryType.Song}
                      selectedCell={selectedCell}
                      setSelectedCell={setSelectedCell}
                      isInHymnOfTheMonthPlanner={true}
                      customMusic={customMusic}
                      hymnals={hymnals}
                      isInternalUser={isInternalUser}
                      isChurchAdmin={isChurchAdmin}
                    />
                  )
                })
              }
            </SongCellsWrapper>
        </ScrollWrapper>
      </DialogContent>
    </Dialog>
  );
}

const Dialog = styled.dialog`
  height: 80vh;
  overflow: hidden;
  padding: 0; // prevent clicks on dialog itself
  margin: auto;
  border-radius: 15px;
  border: none;
  overscroll-behavior-x: none; /* avoid horizontal dialog scroll on iOS */
`;

// prevents clicks on dialog itself
const DialogContent = styled.div`
  height: 80vh;
  padding: 20px;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const YearTitle = styled.p`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: min(max(1.5vw, 1.1rem), 2rem);
  border: none;
  text-align: center;
  color: var(--color-text);
  background-color: transparent;
  padding: 0 20px 20px 20px;
  width: 65vw;
  text-overflow: ellipsis;
`;

const ScrollWrapper = styled.div`
  height: 62vh;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior-x: none; /* avoid swipe to go back */
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  margin: 8px 7px;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 0.75rem;
  color: var(--color-background);
  background-color: var(--color-primary-button);
  border-radius: 20px;
  cursor: pointer;
`;

const SongCellsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px; /* allows left & right cell outlines to be visible*/
`;
