import {SongList, SongListEntryType, SongListType, wasSongListEdited} from '../../../../common/model';
import styled from 'styled-components/macro';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ReactComponent as TrashIcon} from "../../../assets/trash.svg";
import {getShortTime, parseInputAsTimeString} from '../../../../common/time_string';
import {MinSongListRowCount, SongRowHeightSingle, SongRowWidth} from './shared';
import {useAllSongLists} from './use_all_song_lists';
import {SongCell} from './song_cell';
import {confirm} from '../../../util/confirm';
import {useCustomMusic} from '../../../data/use_custom_music';
import {useHymnals} from '../../../data/use_hymnals';
import {mapSongListId} from '../../../data/use_church';
import {Device, getDevice} from '../../../util/platform';
import {useUserAttributes} from '../../../data/use_user_attributes';
import {thisSundayDate} from '../../../../common/date_string';
import {useLiturgyKeyboardNavigation} from './use_liturgy_keyboard_navigation';

export interface EditEventProps {
  defaultName?: string;
  onClose: () => void;
  songListId: number;
}

export const EventEditor = ({defaultName, onClose, songListId: songListIdRaw}: EditEventProps) => {
  const customMusic = useCustomMusic();
  const hymnals = useHymnals();
  const allSongLists = useAllSongLists();
  const dialogElement = useRef<HTMLDialogElement>(null);
  const nameInput = useRef<HTMLInputElement>(null);
  const dateInput = useRef<HTMLInputElement>(null);
  const timeInput = useRef<HTMLInputElement>(null);
  const songListId = mapSongListId(songListIdRaw);
  // defaultSongList just avoids undefined -> should never be used
  const defaultSongList: SongList = useMemo(() => ({date: thisSundayDate(), type: SongListType.Event, songs: []}), []);
  const songList = allSongLists.find(list => list.id === songListIdRaw || list.id === songListId) ??
    defaultSongList;
  const [nameChanged, setNameChanged] = useState(false);
  const {isInternalUser, isChurchAdmin} = useUserAttributes();

  const onCloseDialog = useCallback(async () => {
    if (songList) {
      const time = parseInputAsTimeString(timeInput.current?.value);
      if (!wasSongListEdited(songList) && !nameChanged && !time) {
        await allSongLists.remove(songList);
      } else {
        const name = nameInput.current?.value;
        const date = dateInput.current?.value;
        const {name: unused1, time: unused3, ...nakedList} = songList;
        await allSongLists.update({...nakedList, ...(name && {name}), ...(date && {date}), ...(time && {time})})
      }
    }
    onClose();
  }, [allSongLists, nameInput, dateInput, timeInput, nameChanged, onClose, songList]);

  const onClickBackground = useCallback(async (event: MouseEvent) => {
    if (event.target === dialogElement.current) {
      await onCloseDialog();
    }
  }, [onCloseDialog]);

  useEffect(() => {
    const {current} = dialogElement;
    current?.showModal();

    // iOS does not allow input controls to be proactively selected & does not handle clicking a control w/selected text
    if (getDevice() === Device.iOS) {
      return;
    }

    if (songList?.name) {
      // avoid selecting existing name
      nameInput.current?.blur();
      setSelectedCell({date: songList?.date ?? '', row: 1});
    } else if (defaultName) {
      nameInput.current?.select();
    }

    // no dependencies, only run once!
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const {current} = dialogElement;
    current?.addEventListener('click', onClickBackground);
    current?.addEventListener('cancel', onCloseDialog);

    return () => {
      current?.removeEventListener('click', onClickBackground);
      current?.removeEventListener('cancel', onCloseDialog);
    };
  }, [onClickBackground, onCloseDialog]);

  const onDeleteEvent = useCallback(async () => {
    if (await confirm({confirmation: <span>'Are you sure you want to delete this event?'</span>})) {
      if (songList) {
        await allSongLists.remove(songList);
      }
      onClose();
    }
  }, [allSongLists, onClose, songList]);

  const onNameChanged = useCallback(() => {
    setNameChanged(true);
  }, []);

  const maxSongRow = Math.max(...songList.songs.map(({row}) => row));
  const rowCount = Math.max(MinSongListRowCount, maxSongRow + 1);

  const {moveDown, onKeyDown, selectedCell, setSelectedCell} = useLiturgyKeyboardNavigation({
    defaultDate: songList.date,
    rowCount,
  });

  return (
    <Dialog ref={dialogElement}>
      <DialogContent>
        {/* TODO(hewitt): iOS fails to focus input (https://medium.com/@brunn/autofocus-in-ios-safari-458215514a5f)*/}
        <EventName
          key='name'
          placeholder='Event Name'
          defaultValue={songList.name ?? defaultName}
          onChange={onNameChanged}
          ref={nameInput}
        />
        <EventDetails key='details'>
          <EventDate
            key='date'
            type='date'
            defaultValue={songList.date}
            ref={dateInput}
          />
            at
          <EventTime
            key='time'
            defaultValue={getShortTime(songList.time)}
            ref={timeInput}
          />
        </EventDetails>
        <DeleteEventButton key='delete' onClick={onDeleteEvent}/>
        <CloseButton key='close' onClick={onCloseDialog}>Done</CloseButton>
        <ScrollWrapper key='list' onKeyDown={onKeyDown}>
          <SongListWrapper $rowCount={rowCount}>
            {
              [...Array(rowCount).keys()].map(index => index + 1).map(row => (
                <SongCell
                  key={row}
                  customMusic={customMusic}
                  date={songList.date}
                  hymnals={hymnals}
                  moveDown={moveDown}
                  row={row}
                  songList={songList}
                  songListEntryType={SongListEntryType.Song}
                  songListType={SongListType.Event}
                  selectedCell={selectedCell}
                  setSelectedCell={setSelectedCell}
                  isInternalUser={isInternalUser}
                  isChurchAdmin={isChurchAdmin}
                />
              ))
          }
          </SongListWrapper>
        </ScrollWrapper>
      </DialogContent>
    </Dialog>
  );
}

// prevents clicks on dialog itself
const DialogContent = styled.div`
  height: 80vh;
  padding: 20px;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DeleteEventButton = styled(TrashIcon)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  fill: var(--color-background);
  background-color: var(--color-trash);
  padding: 7px;
  border-radius: 10px;
  width: 30px;
  cursor: pointer;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  margin: 8px 7px;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 0.75rem;
  color: var(--color-background);
  background-color: var(--color-primary-button);
  border-radius: 20px;
  cursor: pointer;
`;

const Dialog = styled.dialog`
  height: 80vh;
  overflow: hidden;
  padding: 0; // prevent clicks on dialog itself
  margin: auto;
  border-radius: 15px;
  border: none;
  overscroll-behavior-x: none; /* avoid horizontal dialog scroll on iOS */
`;

const EventName = styled.input`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: min(max(1.5vw, 1.1rem), 2rem);
  border: none;
  text-align: center;
  color: var(--color-text);
  background-color: transparent;
  padding: 0 20px 20px 20px;
  width: 65vw;
  text-overflow: ellipsis;
`;

const EventDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 1rem;
  color: var(--color-text);
  background-color: transparent;
  text-align: center;
  padding-bottom: 15px;
`;

const EventDate = styled.input`
  width: 13ch;
  text-align: right;
`;

const EventTime = styled.input`
  color: var(--color-text);
  background-color: transparent;
  text-align: center;
  width: 9ch;
`;

const ScrollWrapper = styled.div`
  height: 62vh;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior-x: none; /* avoid swipe to go back */
`;

const SongListWrapper = styled.div<{$rowCount: number}>`
  display: grid;
  grid-template-columns: repeat(1, min(${SongRowWidth}, 80vw));
  grid-template-rows: repeat(${props => props.$rowCount}, ${SongRowHeightSingle});
  grid-gap: 1px; /* avoids cell outline over-reach */
  padding: 3px; /* allows left & right cell outlines to be visible*/
`;