// retains current set of search params and optionally adds a new one
// used in concert with navigate (e.g. navigate(withSearchParms(url))) to retain or append search params
export function withSearchParams(url: string, ...additionalParams: string[]): string {
  const urlObj = new URL(url, window.location.origin);
  const params = new URLSearchParams(window.location.search);
  for (const additionalParam of additionalParams) {
    let key = additionalParam;
    let value = '';
    if (additionalParam.includes('=')) {
      [key, value] = additionalParam.split('=');
    }
    params.set(key, value);
  }
  urlObj.search = params.toString();
  // does not appear to be a way to get the url w/o scheme & host, so rebuild it ourselves
  return urlObj.pathname + urlObj.search + urlObj.hash;
}

const pushStateHandler = {
  apply: (target: any, thisArg: any, argArray: any[]) => {
    const output = target.apply(thisArg, argArray);
    if (argArray[1] !== 'ignore') {
      _callListeners();
    }
    return output;
  },
};

// TODO(hewitt): review when we adopt React Router
// browser does not provide an event for pushState, so we hook the call to generate our own event
export function monkeyPatchPushState() {
  window.history.pushState = new Proxy(window.history.pushState, pushStateHandler);
  window.history.replaceState = new Proxy(window.history.replaceState, pushStateHandler)
}

type Listener = () => void;

function _callListeners() {
  for (const listener of _listeners) {
    listener();
  }
}

const _listeners: Listener[] = [];
