export enum Device {
  iOS = 'ios',
  Android = 'android',
  Other = 'other',
}

export enum Platform {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export function getDevice(): Device {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
    return Device.iOS;
  } else if (userAgent.includes('android') || userAgent.trim().toLowerCase() === 'sing your part') {
    // Google Play app shows "Sing Your Part" as the user agent
    return Device.Android;
  }
  return Device.Other;
}

export function getPlatform(): Platform {
  return getDevice() === Device.Other ? Platform.Desktop : Platform.Mobile;
}

export const iOSVersion = typeof(global.navigator) !== 'undefined' && (parseFloat(
  ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0,''])[1])
    .replace('undefined', '3_2').replace('_', '.').replace('_', '')
  ) || false);
