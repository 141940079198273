import React from 'react';

interface Coordinates {
  x: number,
  y: number,
}

const lineThickness = 6;
const clefHeight = 350;
export const noteSpacing = clefHeight / 8 - lineThickness / 8;

export function Clef({left, right, centerY}: {left: number, right: number, centerY: number}) {
  return (
    <svg
      x={left}
      y={centerY - clefHeight / 2}
      width={right - left}
      height={clefHeight}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1={left} y1={lineThickness / 2} x2={right} y2={lineThickness / 2} stroke-width={lineThickness}/>
      <line x1={left} y1={clefHeight / 4 + lineThickness / 4} x2={right} y2={clefHeight / 4 + lineThickness / 4} stroke-width={lineThickness}/>
      <line x1={left} y1={clefHeight / 2} x2={right} y2={clefHeight / 2} stroke-width={lineThickness}/>
      <line x1={left} y1={clefHeight * 3 / 4 - lineThickness / 4} x2={right} y2={clefHeight * 3 / 4 - lineThickness / 4} stroke-width={lineThickness}/>
      <line x1={left} y1={clefHeight - lineThickness / 2} x2={right} y2={clefHeight - lineThickness / 2} stroke-width={lineThickness}/>
    </svg>
  );

}

export enum NoteType {
  Whole = 1,
  Half = 2,
  Quarter = 4,
  Eighth = 8,
  Sixteenth = 16,
}

export function Note({center, type}: {center:Coordinates, type: NoteType}) {
  const elipseCenter = {x: 50, y: 275};
  const rx = elipseCenter.x;
  const ry = rx * (NoteType.Whole ? 3.3 : 3.5) / 5;
  const lineX = elipseCenter.x * 2 - lineThickness - (type === NoteType.Half ? 3.5 : 2.35);
  const hasTail = type === NoteType.Eighth || type === NoteType.Sixteenth;
  return (
    <svg
      x={center.x - elipseCenter.x}
      y={center.y - elipseCenter.y}
      width={elipseCenter.x * 2 + (hasTail ? elipseCenter.x * 2 : 0)}
      height="320"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={`mask-${type.toString()}`}>
          <ellipse
            cx={elipseCenter.x}
            cy={elipseCenter.y}
            rx={rx}
            ry={ry}
            fill="white"
          />
          <ellipse
            cx={elipseCenter.x}
            cy={elipseCenter.y}
            rx={type === NoteType.Whole ? rx / 1.8 : (rx - lineThickness / 2) / 1.1}
            ry={type === NoteType.Whole ? ry / 1.5 : (ry / 2) / 1.2}
            fill="black"
            transform={type === NoteType.Whole ? `rotate(65, ${elipseCenter.x}, ${elipseCenter.y})` : ''}
          />
        </mask>
      </defs>
      <ellipse
        cx={elipseCenter.x}
        cy={elipseCenter.y}
        rx={rx}
        ry={ry}
        transform={type !== NoteType.Whole ? `rotate(-40, ${elipseCenter.x}, ${elipseCenter.y})` : ''}
        mask={type === NoteType.Whole || type === NoteType.Half ? `url(#mask-${type.toString()})` : ''}
      />
      {type !== NoteType.Whole &&
        <line
          x1={lineX}
          y1="260"
          x2={lineX}
          y2="0"
          stroke-width={lineThickness}
        />
      }
      {hasTail && <Tail left={lineX} top={0}/>}
      {(type === NoteType.Sixteenth) && <Tail left={lineX} top={60}/>}
    </svg>
  );
}

function Tail({left, top}: {left: number, top: number}) {
  return (
    <svg
      x={left}
      y={top}
      width={65}
      height={clefHeight}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="black" d={`
            m 0 75
            c0,-15.5 11.5,-5 17,-2.5
            c5.5,2.5 21,22 32,43
            c11,21 9.5,33.5 4.5,61.5
            c-5,28 20,-35 8,-65
            c-12,-30 -38.5,-58 -46.5,-67
            c-8,-9 -17,-37 -17,-47
            c0,-10 2,92.5 2,77
            z`}/>
    </svg>
  );
}
