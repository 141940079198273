import {Header} from "./header";
import './help_page.css'
import styled from 'styled-components/macro';
import {getMonthlySubscriptionPrice, isInsideMobileApp} from '../util/billing';
import {ContactSupportButton, OuterPageContent, PlainLinkOutlined} from '../util/shared';
import {InAppOnboardingAnnouncement, useInAppAnnouncementActive} from './onboarding/in_app_onboarding_announcement';
import React, {useCallback} from 'react';
import {Pages} from '../../common/pages';
import {useNavigate} from 'react-router-dom';
import {getUrlForPage} from '../util/path';
import {confirm} from '../util/confirm';
import * as server_api from '../../common/server_api';
import {synchronizeHouseholdWithServer, useHousehold} from '../data/use_household';
import {LocalStorageKey, localStorageRemove} from '../data/client_local_storage';

export const HelpPage = () => {
  const showOnboardingSection = useInAppAnnouncementActive();
  const navigate = useNavigate();
  const {household} = useHousehold();

  // TODO(hewitt): Should use <Link> once we switch to React Router
  const onPrivacy = useCallback(() => {
    navigate(getUrlForPage(Pages.Privacy));
  }, [navigate]);

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onDeleteHousehold = useCallback(async () => {
    if (!household?.token) {
      return;
    }
    if (await confirm({confirmation: <span>Are you sure you wish to<br/>delete your household?</span>})) {
      localStorageRemove(LocalStorageKey.PushHouseholdChurchChoice);
      localStorageRemove(LocalStorageKey.PushHousehold);
      await server_api.setHouseholdChurch(household.token, undefined);
      await server_api.clearHousehold({token: household.token});
      await synchronizeHouseholdWithServer({force: true});
      window.location.href = '/settings';
    }
  }, [household]);

  const showDeleteButton = isInsideMobileApp() && household?.householdEmail;

  const price = getMonthlySubscriptionPrice();
  return (
    <OuterPageContent>
      <Header title='Help' onBack={onBack}/>
      <div className='helpPageContent content'>
        <div className='contentBlock centeredContent'>
          <span>Questions or feedback?</span>
          <span>We would love to hear from you!</span>
          <ContactSupportButton/>
        </div>
        {showOnboardingSection && (
          <div className='contentBlock centeredContent'>
            <InAppOnboardingAnnouncement embed={true}/>
          </div>
        )}
        <CustomerSegmentFeatures
          src={`https://singyourpart.app/billing/app/#customer-segment-features?monthly-price-override=${price}`}
          sandbox="allow-popups allow-top-navigation allow-scripts allow-popups-to-escape-sandbox"/>
        <div className='contentBlock'>
          <h2>Why Sing Your Part?</h2>
          God commands each of us to praise Him joyfully with our voice (Psalm 100), yet the music we sing in church
          can be difficult to access outside of church.  With Sing Your Part, the music you will sing this Sunday
          is just two taps away on your phone, tablet or computer.
        </div>
        <div className='contentBlock'>
          <h2>Why is music important?</h2>
          Read these short books to find out.<br/>
          {/*<div style={{display: 'grid', gridTemplateColumns: '4fr 2fr 1fr 1fr', gap: '5px', paddingTop: '10px'}}>*/}
          <div>
            <div style={{paddingTop: '10px'}}><b>Church Music and the Other Kinds</b></div>
            <div style={{display: 'flex', gap: '12px'}}>
              <div style={{width: '17ch'}}>by Douglas Wilson</div>
              <div>
                <a style={{color: 'blue'}}
                   href='https://canonpress.com/products/church-music-and-the-other-kinds?srsltid=AfmBOopbrWnWsX7j3709TibO4DT_Hr8ICVEGi5chSI1MD7VcT2FkH9uU'>Paper</a>
              </div>
              <div>
                <a style={{color: 'blue'}}
                   href='https://www.audible.com/pd/Church-Music-and-the-Other-Kinds-Audiobook/B0BX75WTK6'>Audio</a>
              </div>
            </div>

            <div style={{paddingTop: '10px'}}><b>Sing!</b></div>
            <div style={{display: 'flex', gap: '10px'}}>
              <div style={{width: '17ch'}}>by Keith & Kristyn Getty</div>
              <div>
                <a style={{color: 'blue'}}
                   href='https://gettymusic.store/collections/books/products/sing-book-by-keith-kristyn'>Paper</a>
              </div>
              <div>
                <a style={{color: 'blue'}}
                   href='https://www.audible.com/pd/Sing-Audiobook/B074HCD658'>Audio</a>
              </div>
            </div>
          </div>
        </div>
        <div className="contentBlock">
          <h2>Time Commitment</h2>
          Learning to sing your part only takes 5-10 minutes per day. But, to make progress, you need to be consistent.
          Find a time of the day when you will remember to practice.
          Choose one relatively simple song to start with (e.g. "Amazing Grace" or "Be Thou My Vision").
          <br/>
          <br/>
          Do not expect overnight results! It might take a couple of months to master your first song. However,
          the second song will take less time, and the more you do it, the faster you will be able to acquire
          new songs!
        </div>
        <div className="contentBlock">
          <h2>Voice Range</h2>
          First, determine your voice range (soprano, alto, tenor, or bass).
          Sopranos are high female voices, and altos are low; tenors are high male voices and basses are low.
          If you're not sure which part you are best suited for, ask a musical friend to help you figure this out.
        </div>
        <div className="contentBlock">
          <h2>Singing</h2>
          To learn your part, start by opening up a song.
          Turn up the volume on your part (e.g. tenor) and turn down all the other parts.
          Adjust the tempo to a pace you are comfortable with.
          To sing along with the words, swipe to the right to view the music.
          Once you feel confident about singing your part on its own, slowly begin to turn up the volume of the melody
          (usually soprano).
          <br/>
          <br/>
          This will be challenging; once you hear the melody, you might be inclined to sing that instead of your own
          part.
          Keep the melody quiet enough for you to be able to hear your part, and as you gain confidence, gradually turn
          the melody up louder.
          <br/>
          <br/>
          When you can still sing your part while the melody is at full volume, congratulations - you are doing very
          well!
          However, you are not quite there yet. The next step is to gradually add in the remaining parts.
          If you can still sing your part with all the other parts at full volume, then the final step is to begin
          turning down your own part.
          Once your part is barely audible and you are still able to sing against the others, you are on the road to
          mastery.
          <br/>
          <br/>
          Again, this process takes time. Stick with it, though; before you know it, you will be able to sing your part
          with complete confidence!
        </div>
        <div className="contentBlock">
          <h2>Churches</h2>
          If your church is not in the list, ask your music
          leader send an email to <a target='_blank' rel='noreferrer'
                                     href='mailto:support@crescendosw.com'>support@crescendosw.com</a> with a request
          for your church to be added.
          Once we add your church, all that your music leader will need to do is submit their song list every week,
          which takes less than five minutes.
          This service is entirely free of charge.
          <br/>
          <br/>
          If your church is listed in Sing Your Part, but your weekly song list is empty,
          talk to your music leader about adding songs to Sing Your Part each week so that
          you can practice ahead of time for Sunday worship.
        </div>
        <div className="contentBlock">
          <h2>Feedback</h2>
          If you have questions, comments, or feedback about the app, please email us at
          <a href='mailto:support@crescendosw.com'> support@crescendosw.com</a>.
          Your input helps us make Sing Your Part better for everyone.
        </div>
        <div className='contentBlock'>
          <h2>FAQ</h2>
          <ul>
            <li>What if I can't match pitch?</li>
            <br/>
            <ul>
              <li>If you're having trouble matching pitch with the music, it would be worth it to spend time practicing
                this outside of Sing Your Part. If you have access to a keyboard, play individual notes and try matching
                them as best you're able. Doing this should help you improve!
              </li>
            </ul>
            <br/>
            <br/>
            <li>The hymnal my church uses isn't in Sing Your Part. How can I add it?</li>
            <br/>
            <ul>
              <li>We are always trying to expand our hymnal library. If the hymnal your church uses isn't available
                in Sing Your Part, email us at <a href='mailto:support@crescendosw.com'>support@crescendosw.com</a>,
                and we can add your hymnal to our list.
              </li>
            </ul>
            <br/>
            <br/>
            <li>I don’t know how to read sheet music. What can I do?</li>
            <br/>
            <ul>
              <li>Don't worry; lots of people can’t read sheet music. You don't need to know music theory to learn your
                part, but it can make the process a lot easier. As you practice singing along with the words on the
                sheet music, pay attention to the notes on the staff (the set of lines that run across the page).
                You'll begin to notice patterns: long notes look different from short ones, and the notes are placed
                higher and lower on the staff to indicate changes in pitch. In addition to this, try looking up the
                basics of music theory. Getting a grasp on the subject will help you approach new music more easily.
              </li>
            </ul>
          </ul>
        </div>
        <div className='contentBlock'>
          <HelpContent>
            <PlainLinkOutlined
              target='_blank'
              href='https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'
            >
              Terms of Use
            </PlainLinkOutlined>
            <PlainLinkOutlined onClick={onPrivacy}>Privacy Policy</PlainLinkOutlined>
            {showDeleteButton ?? <DeleteButton onClick={onDeleteHousehold}>Delete Household</DeleteButton>}
          </HelpContent>
        </div>
      </div>
    </OuterPageContent>
  )
}

const HelpContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const CustomerSegmentFeatures = styled.iframe`
  width: 100%;
  height: 700px;
  border: none;
`;

export const DeleteButton = styled.button`
  margin-top: 10px;
  padding: 5px 10px;
  border: 3px solid var(--color-text);
  background-color: var(--color-background);
  color: red;
  border-radius: 15px;
  cursor: pointer;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.3em;
  text-decoration: none;
  width: fit-content;

  &:hover {
    background-color: lightcoral;
    color: var(--color-text);
  }
`;