import {DBSchema, IDBPDatabase, openDB} from 'idb';
import {SongList} from '../../common/model';

// - Song updates are handled via a persistent queue
// - Newly updated song lists are added to one end of the queue
// - They are removed from the other end of the queue when successfully uploaded to the network
// - The queue is stored in IndexedDB, which has an async API

const DBName = 'sing-your-part';
const TestDBName = 'sing-your-part-test';
const SongListUpdateObjectStore = 'song-list-updates';

type Timestamp = number;             // not an actual date timestamp
let currentTimestamp: Timestamp = 0; // monotonically increasing number
let currentDatabaseName: string = DBName;

export interface DeletableSongList extends SongList {
  isDeleted?: boolean;
}

interface UpdateSongListDB extends DBSchema {
  [SongListUpdateObjectStore]: {
    key: Timestamp;
    value: DeletableSongList;
  };
}

export async function initializeCurrentSongUpdateTimestamp() {
  currentTimestamp = await getMaxTimestamp();
}

export async function testSetup() {
  currentDatabaseName = TestDBName;
  await initializeCurrentSongUpdateTimestamp();
}

export async function testTeardown() {
  currentDatabaseName = DBName;
  await initializeCurrentSongUpdateTimestamp();
}

async function openSingYourPartDB(): Promise<IDBPDatabase<UpdateSongListDB>> {
  // this is supposed to be cheap enough that we can do it on every operation rather than persisting the open session
  // https://stackoverflow.com/questions/40593260/should-i-open-an-idbdatabase-each-time-or-keep-one-instance-open
  const db = await openDB<UpdateSongListDB>(currentDatabaseName, 1, {
    upgrade(db) {
      db.createObjectStore(SongListUpdateObjectStore);
    }
  });
  return db;
}

async function getMaxTimestamp() {
  const db = await openSingYourPartDB();
  const keys = await db.getAllKeys(SongListUpdateObjectStore);
  return Math.max(...keys.map(key => key + 1), 0);
}

export async function recordSongListUpdate(songList: SongList, {isDeleted}: {isDeleted?: boolean} = {}) {
  const timestamp = currentTimestamp++;
  const db = await openSingYourPartDB();
  await db.put(SongListUpdateObjectStore, {...songList, ...(isDeleted && {isDeleted})}, timestamp);
}

export function getCurrentSongListUpdateTimestamp(): Timestamp {
  return currentTimestamp;
}

export async function getSongListUpdatesBefore(timestamp: Timestamp): Promise<DeletableSongList[]> {
  const db = await openSingYourPartDB();
  const keys = await db.getAllKeys(SongListUpdateObjectStore);
  let songLists = [] as SongList[];
  for (const key of keys) {
    if (key >= timestamp) {
      continue;
    }
    const songList = await db.get(SongListUpdateObjectStore, key);
    if (!songList) {
      continue;
    }
    songLists = [...songLists.filter(({id}) => id !== songList.id), songList];
  }
  return songLists;
}

export async function removeSongListUpdatesBefore(timestamp: Timestamp) {
  const db = await openSingYourPartDB();
  const keys = await db.getAllKeys(SongListUpdateObjectStore);
  for (const key of keys) {
    if (key >= timestamp) {
      continue;
    }
    await db.delete(SongListUpdateObjectStore, key);
  }
}

export async function songListUpdatesPending(): Promise<boolean> {
  const db = await openSingYourPartDB();
  const keys = await db.getAllKeys(SongListUpdateObjectStore);
  return keys.length > 0;
}

export async function clearAllSongListUpdates() {
  const db = await openSingYourPartDB();
  await db.clear(SongListUpdateObjectStore);
}
