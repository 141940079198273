import {DateString} from '../../../../common/date_string';
import {
  BoundSongListEntry,
  CustomMusic,
  isBound,
  SongList,
  SongListEntry,
  SongListEntryType,
  SongListType,
  SongSlug,
  UnboundSongListEntry
} from '../../../../common/model';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {SongSelector, SongSelectorProps} from './song_selector';
import {getDisplayNameForSongListEntry} from '../../../util/path';
import styled from 'styled-components/macro';
import {
  DisplayUnauthorizedAlert,
  SongCellPadding,
  SongRowHeightDouble,
  SongRowHeightSingle,
  SongRowWidth
} from './shared';
import {useAllSongLists} from './use_all_song_lists';
import {Device, getDevice, getPlatform, Platform} from '../../../util/platform';
import {CellPosition} from './liturgy_planner';
import {HymnOfTheMonthPlanner, HymnOfTheMonthPlannerProps} from './hymn_of_the_month_planner';
import {HymnalWithHymns} from '../../../data/use_hymnals';
import {isLiturgySchemaVisible} from '../../../data/use_church';
import {TextCellEditor, TextCellEditorProps} from './text_cell_editor';
import {ReactComponent as SongWarningIcon} from "../../../assets/song-warning.svg";
import {ReactComponent as TextWarningIcon} from "../../../assets/text-warning.svg";

export const CellHoverOutlineWidth = '3px';

interface SongCellProps {
  customMusic?: CustomMusic;
  date: DateString;
  hymnals?: HymnalWithHymns[];
  row: number;
  label?: string;
  songList: SongList;
  songListType: SongListType;
  songListEntryType: SongListEntryType;
  selectedCell?: CellPosition;
  setSelectedCell?: (cellPosition: CellPosition) => void;
  moveDown?: () => void;
  isInHymnOfTheMonthPlanner?: Boolean;
  isInternalUser: boolean;
  isChurchAdmin: boolean;
  setIsEditing?: (isEditing: boolean) => void;
}

export const SongCell = ({
  customMusic,
  date,
  hymnals,
  row,
  label,
  songList,
  songListType,
  songListEntryType,
  selectedCell,
  setIsEditing,
  setSelectedCell,
  moveDown,
  isInHymnOfTheMonthPlanner,
  isInternalUser,
  isChurchAdmin,
}: SongCellProps) => {
  const [songSelectorProps, setSongSelectorProps] = useState<SongSelectorProps | undefined>();
  const [hymnOfTheMonthPlannerProps, setHymnOfTheMonthPlannerProps] = useState<HymnOfTheMonthPlannerProps | undefined>();
  const [textCellEditorProps, setTextCellEditorProps] = useState<TextCellEditorProps | undefined>();
  const entry = songList.songs.find(song => song.row === row);
  const allSongLists = useAllSongLists();
  const editSongCellRef = useRef<HTMLDivElement>(null);
  let tabIndexCount = 0;

  const onCloseSongSelector = useCallback(() => {
    setSongSelectorProps(undefined);
  }, []);

  const onCloseHymnOfTheMonthPlanner = useCallback(() => {
    setHymnOfTheMonthPlannerProps(undefined);
  }, []);

  useEffect(() => {
    setIsEditing?.(Boolean(songSelectorProps || hymnOfTheMonthPlannerProps || textCellEditorProps))
  }, [setIsEditing, songSelectorProps, hymnOfTheMonthPlannerProps, textCellEditorProps])

  const updateSongListEntry = useCallback((entry: SongListEntry) => {
    songList.songs = [...songList.songs.filter(song => song.row !== row), entry]
      .sort((lhs, rhs) => lhs.row - rhs.row);
    void allSongLists.update(songList);
  }, [allSongLists, row, songList]);

  const deleteSongListEntry = useCallback(() => {
    const songs = songList.songs.filter(song => song.row !== row);
    void allSongLists.update({...songList, songs});
  }, [allSongLists, row, songList]);

  const onCloseTextCellEditor = useCallback((text: string | undefined) => {
    setTextCellEditorProps(undefined);
    if (text === undefined) {
      return;
    }
    if (text === '') {
      deleteSongListEntry();
      return;
    }
    let newEntry: UnboundSongListEntry;
    if (entry) {
      const {slug, ...songNoSlug} = entry as BoundSongListEntry; // strip 'text' to replace with 'slug'
      newEntry = {...songNoSlug, text};
    } else {
      newEntry = {row, text};
    }
    updateSongListEntry(newEntry);
  }, [deleteSongListEntry, entry, row, updateSongListEntry]);

  const bindSongListEntry = useCallback((slug: string) => {
    let newEntry: BoundSongListEntry;
    if (entry) {
      const {text, ...songNoText} = entry as UnboundSongListEntry; // strip 'text' to replace with 'slug'
      newEntry = {...songNoText, slug};
    } else {
      newEntry = {row, slug};
    }
    updateSongListEntry(newEntry);
  }, [entry, row, updateSongListEntry]);

  const onSelectSong = useCallback((songSlug?: SongSlug) => {
    if (songSlug) {
      bindSongListEntry(songSlug);
    } else {
      deleteSongListEntry();
    }
    onCloseSongSelector();
    moveDown?.();
  }, [bindSongListEntry, deleteSongListEntry, moveDown, onCloseSongSelector]);

  const onEditSongEntry = useCallback(() => {
    if (!isChurchAdmin && !isInternalUser) {
      void DisplayUnauthorizedAlert();
      return;
    }
    const editSongList = songList ?? {date, type: songListType, songs: []}
    const initialValue = getDisplayNameForSongListEntry({entry, hymnals, customMusic})
    if (songListEntryType !== SongListEntryType.Song) {
      setTextCellEditorProps({
        onClose: onCloseTextCellEditor,
        onEnter: moveDown,
        initialValue,
        selectValue: true,
      });
    } else if (songListType === SongListType.HymnOfTheMonth && !isInHymnOfTheMonthPlanner) {
      setHymnOfTheMonthPlannerProps({
        songList: editSongList,
        onClose: onCloseHymnOfTheMonthPlanner
      });
    }
    else {
      setSongSelectorProps({
        songList: editSongList,
        onClose: onCloseSongSelector,
        onSongClick: onSelectSong,
        initialValue,
        selectSearchValue: true
      });
    }
  }, [
    customMusic,
    date,
    entry,
    hymnals,
    isChurchAdmin,
    isInternalUser,
    isInHymnOfTheMonthPlanner,
    moveDown,
    onCloseHymnOfTheMonthPlanner,
    onCloseSongSelector,
    onCloseTextCellEditor,
    onSelectSong,
    songList,
    songListType,
    songListEntryType,
  ]);

  const isSelected = date === selectedCell?.date && row === selectedCell?.row;

  const handleCellMouseDown = useCallback(() => {
    // Only open the editor if the cell is both selected & active.
    // If the user clicks away from the liturgy planner and then clicks back into the selected cell, don't open.
    const isActive = editSongCellRef.current === document.activeElement;
    if (
      getPlatform() === Platform.Mobile ||
      (isSelected && isActive) ||
      (songListType === SongListType.HymnOfTheMonth && !isInHymnOfTheMonthPlanner)
    ) {
      setTimeout(onEditSongEntry); // allows song cell to get focus before text editor attempts to take focus
      return;
    }

    if (setSelectedCell !== undefined && !isSelected) {
      setSelectedCell({date, row});
    }
  }, [
    date,
    isSelected,
    isInHymnOfTheMonthPlanner,
    onEditSongEntry,
    row,
    setSelectedCell,
    songListType
  ]);

  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    const keyIsNumber = event.code === `Digit${event.key}` || event.code === `Numpad${event.key}`;
    const keyIsLetter = event.code === `Key${event.key.toUpperCase()}`;
    const isCopyPaste = (event.key === 'v' || event.key === 'c') && (event.metaKey || event.ctrlKey);

    if (isCopyPaste) {
      // avoid opening song selector or text editor on paste
      // either the text editor is already open & will accept the text, or we are doing a multi-cell paste
      return;
    }

    if (keyIsNumber || keyIsLetter) {
      if (songListEntryType === SongListEntryType.Song) {
        setSongSelectorProps({
          songList: songList,
          onClose: onCloseSongSelector,
          onSongClick: onSelectSong,
          initialValue: event.key,
          selectSearchValue: false
        });
      } else {
        setTextCellEditorProps({
          onClose: onCloseTextCellEditor,
          onEnter: moveDown,
          selectValue: false
        });
      }
      return;
    }

    switch (event.key) {
      case 'Enter':
        onEditSongEntry();
        break;
      case 'Backspace':
      case 'Delete':
        deleteSongListEntry();
        break;
    }
  }, [
    deleteSongListEntry,
    moveDown,
    onCloseSongSelector,
    onCloseTextCellEditor,
    onEditSongEntry,
    onSelectSong,
    songList,
    songListEntryType,
  ]);

  const onKeyUp = useCallback((event: React.KeyboardEvent) => {
  }, [
  ]);

  useEffect(() => {
    if (isSelected && editSongCellRef.current) {
      editSongCellRef.current.focus();
    }
  }, [isSelected, songSelectorProps]);

  const isWideFormat = songListType === SongListType.HymnOfTheMonth && !isInHymnOfTheMonthPlanner;

  const isWarningVisible = Boolean(entry && (
    (songListEntryType === SongListEntryType.Song && !isBound(entry)) ||
    (songListEntryType !== SongListEntryType.Song && isBound(entry))
  ));
  const warning = (isWarningVisible && (isBound(entry)
    ? <TextWarning/>
    : <SongWarning/>
  ));

  return (
    <>
      {hymnOfTheMonthPlannerProps && <HymnOfTheMonthPlanner key='HOTM planner' {...hymnOfTheMonthPlannerProps}/>}
      {songSelectorProps && <SongSelector key='selector' {...songSelectorProps}/>}
      <EditSongCell
        tabIndex={tabIndexCount++}
        key={`${date}-${row}`}
        $empty={!entry && songListType !== SongListType.HymnOfTheMonth}
        $isFixedWidth={!isWideFormat}
        $isDoubleHeight={!!label}
        onMouseDown={handleCellMouseDown}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        ref={editSongCellRef}
      >
        {
          textCellEditorProps ? (
            <TextCellEditor {...textCellEditorProps}/>
          ) : (
            <SongCellContent $sticky={isWideFormat} $isInternalUser={isInternalUser}>
              {label && <CellLabel key='header'>{label}</CellLabel>}
              <SongCellSongName $highlight={isBound(entry)}>
                {getDisplayNameForSongListEntry({entry: entry, hymnals, customMusic})}
              </SongCellSongName>
            </SongCellContent>
          )
        }
        {warning}
      </EditSongCell>
    </>
  )
}

const SongCellContent = styled.div<{$sticky: boolean, $isInternalUser: boolean}>`
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  ${props => props.$sticky ? 'position: sticky;' : ''}
  ${props => props.$sticky ? `left: ${
    !isLiturgySchemaVisible(props.$isInternalUser) || getPlatform() === Platform.Mobile ? 0 : SongRowWidth};` : ''}
  padding: ${SongCellPadding};
  /* overcoming Apple's poor CSS support */
  ${getDevice() === Device.iOS ? 'padding-right: 0;' : ''};
  white-space: nowrap;
`;

const SongCellSongNameWidth = `${SongRowWidth} - (${SongCellPadding} * 2)`

const SongCellSongName = styled.div<{$highlight: boolean}>`
  max-width: calc(${SongCellSongNameWidth});
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.$highlight ? 'var(--color-date-highlight-text)' : 'var(--color-text)'};
`;

const EditSongCell = styled.div<{
  $empty: boolean,
  $isFixedWidth: boolean,
  $isDoubleHeight: boolean,
}>`
  position: relative;
  display: flex;
  align-items: center;
  height: ${SongRowHeightSingle};
  ${props => props.$isFixedWidth && `width: ${SongRowWidth};`}
  justify-content: space-between;
  /* outline instead of border takes up 0 space */
  outline: 1px solid var(--color-text-lighter);
  height: ${props => props.$isDoubleHeight ? SongRowHeightDouble : SongRowHeightSingle};
  ${getPlatform() !== Platform.Mobile && `
    border: ${CellHoverOutlineWidth} solid var(--color-background); // avoids text movement on selection
    &:hover {
      background: var(--color-background-selected-item);
      border: ${CellHoverOutlineWidth} solid var(--color-background-selected-item); // avoids white border on hover
      cursor: pointer;
    }
    &:focus-within {
      border: ${CellHoverOutlineWidth} solid var(--color-text);
      z-index: 2;
    }
  `}
`;

const CellLabel = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
`;

// TODO(hewitt): combine these into the same .svg file and set <title> content dynamically
//               problem with setting child textContent attribute is that it changes for all instances
//               problem with wrapping in an outer <svg><title>text</title><Warning/></svg> is the svg does not draw
const SongWarning = styled(SongWarningIcon)`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 5px;
  stroke: var(--color-warning);
`;

const TextWarning = styled(TextWarningIcon)`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 5px;
  stroke: var(--color-warning);
`;
