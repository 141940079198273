import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {getHymnsFromHymnalManifest} from './use_hymnals';
import {
  customMusicDir,
  getChurchCustomMusicManifestPath, getChurchDirectoryName, getSongSlug,
  musicManifestFilename,
  parseSongSlug
} from '../../common/paths';
import {Church, CustomMusic, CustomMusicManifest, getOrgId, Hymn, HymnalManifest, SongSlug} from '../../common/model';
import {useLocalStorage} from './use_local_storage';
import {localStorageGet, LocalStorageKey, localStorageSet} from './client_local_storage';
import {getNumericSongNumber} from '../../common/util';

const customMusicManifestPath = `/${customMusicDir}/${musicManifestFilename}`;

export function useCustomMusic(): CustomMusic {
  const {customMusic} = useContext(CustomMusicContext);
  return customMusic;
}

const CustomMusicContext = createContext<{
  customMusic: CustomMusic;
}>({customMusic: {}});

export const CustomMusicProvider = ({children}: {children: ReactNode}) => {
  const [customMusic, setCustomMusic] = useState<CustomMusic>({});
  const [customMusicManifest, setCustomMusicManifest] = useState<CustomMusicManifest | undefined>();
  const [customMusicHash] = useLocalStorage(LocalStorageKey.CustomMusicHash);

  useEffect(() => {
    if (!customMusicHash) {
      return;
    }
    void (async () => {
      setCustomMusicManifest(await fetch(customMusicManifestPath).then(res => res.json()));
    })();
  }, [customMusicHash]);

  useEffect(() => {
    (async () => {
      try {
        if (!customMusicManifest) {
          return;
        }
        const churchManifests: HymnalManifest[] = await Promise.all(Object.keys(customMusicManifest)
          .map(key => fetch(getChurchCustomMusicManifestPath(key)).then(res => res.json())));
        const hymnLists = Array.from(Object.keys(customMusicManifest).entries())
          .map(([index, key]) => [
            getOrgId(key),
            getHymnsFromHymnalManifest({
              hymnalName: key,
              churchId: getOrgId(key),
              hymnalManifest: churchManifests[index]!,
              basePath: `/${customMusicDir}`
            }),
          ]);
        setCustomMusic(Object.fromEntries(hymnLists));
      } catch (err: any) {
        // TODO(hewitt): Retry strategy?  Perhaps when we implement React Router data sources.
        console.error(`Failed to download custom music: ${err}`);
      }
    })();
  }, [customMusicManifest]);

  return (
    <CustomMusicContext.Provider value={{customMusic}}>
      {children}
    </CustomMusicContext.Provider>
  );
}

// Allocates a temporary song slug for a recently uploaded song.
// Anticipates the future server assigned song number.
// Records a new entry in RecentlyAddedMusic for subsequent name lookup.
export function getTemporarySongSlug({church, songName, customMusic}: {
  church: Church;
  songName: string;
  customMusic: CustomMusic;
}): SongSlug {
  const recentlyAddedMusic = localStorageGet(LocalStorageKey.RecentlyAddedMusic);
  const recentlyAddedHymns = recentlyAddedMusic?.[church.id] ?? [];
  const churchSongSlugs = [
    ...(customMusic[church.id]?.map(({slug}) => slug) ?? []),
    ...(recentlyAddedHymns.map(({slug}) => slug) ?? []),
  ];
  const churchSongNumbers = churchSongSlugs
    .map(slug => getNumericSongNumber(parseSongSlug(slug).songNumber));
  const maxSongNumber = churchSongNumbers.length > 0 ? Math.max(...churchSongNumbers) : 0;
  const songNumber = Math.round(maxSongNumber) + 1;
  const slug = getSongSlug({songNumber: songNumber.toString(), churchId: church.id});
  const hymn: Hymn = {
    title: songName,
    slug,
    hymnal: getChurchDirectoryName(church),
    number: songNumber,
    basePath: `/${customMusicDir}`,
  };
  localStorageSet(LocalStorageKey.RecentlyAddedMusic, {
    ...(recentlyAddedMusic ?? {}),
    [church.id]: [...recentlyAddedHymns, hymn],
  });
  return slug;
}
