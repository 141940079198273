import {SongListEntryType, SongListSchema} from '../../../../common/model';

export function setSongListSchemaLabel(
  schema: SongListSchema | undefined, rowIndex: number, label: string
): SongListSchema {
  const currentRow = schema?.rows.find(({row}) => row === rowIndex);
  return {
    ...schema,
    rows: [
      ...(schema?.rows.filter(({row}) => row !== rowIndex) ?? []),
      {
        row: rowIndex,
        label: label,
        type: currentRow?.type ?? SongListEntryType.Song,
      },
    ],
  };
}

export function setSongListSchemaType(
  schema: SongListSchema | undefined, rowIndex: number, type: SongListEntryType
): SongListSchema {
  const currentRow = schema?.rows.find(({row}) => row === rowIndex);
  return {
    ...schema,
    rows: [
      ...(schema?.rows.filter(({row}) => row !== rowIndex) ?? []),
      {
        row: rowIndex,
        label: currentRow?.label || '',
        type,
      },
    ],
  };
}
