import {Location} from 'history';
import {ensureExists, getNumericSongNumber, userVisibleSongNumber} from "../../common/util";
import {Pages} from '../../common/pages';
import {CustomMusic, Hymn, HymnalSlug, isBound, isUnbound, SongListEntry, SongSlug} from '../../common/model';
import {getSongSlug, hymnalsPDFDir} from '../../common/paths';
import {HymnalWithHymns} from '../data/use_hymnals';
import {localStorageGet, LocalStorageKey} from '../data/client_local_storage';

export function generateFileName(hymn: Hymn) {
  let titleAllCaps = ensureExists(hymn.title).toUpperCase();
  const match = hymn.number.toString().match(/^(?<wholeNumber>\d+)(\.(?<decimal>\d+))?/);
  let number: string;
  if (match && match.groups) {
    const {wholeNumber, decimal} = match.groups;
    number = wholeNumber.padStart(3, '0') + (decimal ? `.${decimal.padEnd(2, '0')}` : '');
  } else {
    console.log(`Error parsing hymn number ${hymn.number}`);
    number = hymn.number.toString();
  }

  if(titleAllCaps.match(/^PSALM \d+$/)) {
    return number + ' - ' + hymn.title;
  }
  else {
    if(hymn.psalm) {
      return number + ' - ' + hymn.title + ' - ' + hymn.psalm;
    }
    else {
      return number + ' - ' + hymn.title;
    }
  }
}

export function generatePathFor(base: string, hymnalName: string, fileName: string) {
  // Cantus Christi hymnals were renamed to include parenthesis (e.g. "Cantus Christi (2002)")
  // Some churches still have the old links (e.g. The King's Congregation)
  if (hymnalName.includes('Cantus Christi') && !hymnalName.includes('(')) {
    hymnalName = hymnalName.replace(/(\d+)/, '($1)')
  }
  return `${base}/${encodeMusicURI(hymnalName)}/${encodeMusicURI(fileName)}`;
}

export function encodeMusicURI(uri: string) {
  return encodeURIComponent(uri)
    .replace(/!/g, '%21');
}

export function getPageFromLocation(location: Location | undefined): Pages | undefined {
  if (!location) {
    return;
  }
  const pagePart = location.pathname.match(/\/(?<page>[^/]+)/)?.groups?.page;
  return pagePart && Object.values(Pages).includes(pagePart as Pages) ? pagePart as Pages : undefined;
}

export function getUrlForPage(page: Pages) {
  return '/' + page;
}

export function getHymnFromSlug({songSlug, hymnals, customMusic}: {
  songSlug?: SongSlug,
  hymnals?: HymnalWithHymns[],
  customMusic?: CustomMusic,
}): Hymn | undefined {
  if (!songSlug || !hymnals || !customMusic) {
    return undefined;
  }
  // see SongSlug declaration for slug format examples
  const parts = songSlug.split('/');
  if (parts.length !== 4) {
    return undefined;
  }
  const [hymnalOrChurch, hymnalSlugOrChurchId, songKeyword, userVisibleSongNumber] = parts;
  if (songKeyword !== 'song') {
    console.log(`malformed song slug: ${songSlug}`);
    return undefined;
  }
  const songNumber = getNumericSongNumber(userVisibleSongNumber);
  if (hymnalOrChurch === 'church') {
    if (Object.keys(customMusic).length === 0) {
      return undefined;
    }
    const recentlyAddedMusic = localStorageGet(LocalStorageKey.RecentlyAddedMusic);
    const hymns = [
      ...(customMusic[hymnalSlugOrChurchId] ?? []),
      ...(recentlyAddedMusic[hymnalSlugOrChurchId] ?? []),
    ];
    if (!hymns) {
      console.log(`Unable to retrieve custom music for song slug: ${songSlug}`);
      return undefined;
    }
    return hymns.find(hymn => hymn.number === songNumber);
  } else {
    const hymnal = hymnals.find(hymnal => hymnal.slug.toLowerCase() === hymnalSlugOrChurchId.toLowerCase());
    if (!hymnal) {
      return undefined;
    }
    return hymnal.hymns.find(hymn => hymn.number === songNumber);
  }
}

export function getSongSlugForHymn(
  hymnals: HymnalWithHymns[],
  hymn: { number: number, hymnal: string, basePath?: string },
): string | undefined {
  const songNumber = userVisibleSongNumber(hymn.number);
  let hymnalSlug: HymnalSlug | undefined;
  let churchId: string | undefined;
  if (hymn.basePath) {
    churchId = hymn.hymnal.match(/^(?<churchId>\d+)/)?.groups?.churchId;
    if (!churchId) {
      console.log(`ERROR: Cannot parse church Id from hymnal "${hymn.hymnal}" for hymn with basePath ${hymn.basePath}`);
      return undefined;
    }
  } else {
    hymnalSlug = hymnals.find(hymnal => hymnal.name === hymn.hymnal)?.slug;
    if (!hymnalSlug) {
      return undefined;
    }
  }
  return getSongSlug({songNumber, hymnalSlug, churchId: churchId ? Number(churchId) : undefined});
}

export function resolveSearchStringToSongSlug(
  hymnals: HymnalWithHymns[] | undefined,
  customHymns: Hymn[] | undefined,
  text: string,
): SongSlug | undefined {
  if (!text) {
    return;
  }
  for (const hymn of customHymns ?? []) {
    if (hymn.title.includes(text)) {
      return hymn.slug;
    }
  }
  const songNumber = text.match(/^(?<songNumber>\d+[a-zA-Z]*)/)?.groups?.songNumber?.toLowerCase();
  for (const hymnal of hymnals ?? []) {
    for (const hymn of hymnal.hymns) {
      if (userVisibleSongNumber(hymn.number) === songNumber || hymn.title.includes(text)) {
        return hymn.slug;
      }
    }
  }
}

export function isCustomSong(songSlug: SongSlug) {
  return songSlug.startsWith('church');
}

export function getHymnFromSongListEntry({song, hymnals, customMusic}: {
  song: SongListEntry,
  hymnals?: HymnalWithHymns[],
  customMusic: CustomMusic,
}): Hymn | undefined {
  if (!isBound(song)) {
    return undefined;
  }
  return getHymnFromSlug({songSlug: song.slug, hymnals, customMusic});
}

export function generateHymnUrlFromSlug({songSlug, hymnals, customMusic, page, suppressSongIssue}: {
  songSlug: SongSlug,
  hymnals: HymnalWithHymns[],
  customMusic: CustomMusic,
  page: Pages | undefined,
  suppressSongIssue: boolean,
}): string | undefined {
  let hymn = getHymnFromSlug({songSlug, hymnals, customMusic});
  if (!hymn) {
    return undefined;
  }
  return generateHymnUrl({hymn, page, suppressSongIssue});
}

export function generateHymnUrl({hymn, page, suppressSongIssue}: {
    hymn: Hymn,
    page?: Pages,
    suppressSongIssue: boolean
  }
): string {
  const hymnalName = hymn.hymnal.replace(/ /g, '_');
  const hymnTitle = hymn.title.replace(/ /g, '_');
  const hymnNumber = hymn.number;
  let psalmName;

  if (hymn.psalm) {
    psalmName = hymn.psalm.replace(/ /g, '_');
  } else {
    psalmName = '';
  }

  const url: string =
    getUrlForPage(page ?? Pages.Library) + '?' +
    'hymnal=' + hymnalName +
    '&title=' + hymnTitle +
    '&number=' + hymnNumber.toString() +
    '&psalm=' + psalmName +
    (hymn.basePath ? '&basePath=' + hymn.basePath : '') +
    (hymn.issue && !suppressSongIssue ? '&issue=' + hymn.issue : '');

  return (url);
}

function getUrlForHymn(hymn: Hymn, fileExtension: string) {
  const filename = generateFileName(hymn) + fileExtension;
  const basePath = hymn.basePath ?? `/${hymnalsPDFDir}`;
  return generatePathFor(basePath, hymn.hymnal, filename);
}

export function getPdfUrlForHymn(hymn: Hymn) {
  return getUrlForHymn(hymn, '.pdf');
}

export function getTextUrlForHymn(hymn: Hymn) {
  return getUrlForHymn(hymn, '.txt');
}

export function getMidiUrlForHymn(hymn: Hymn): string {
  return getUrlForHymn(hymn, '.mid');
}

export function getXmlUrlForHymn(hymn: Hymn) {
  return getUrlForHymn(hymn, '.xml');
}

export function getDisplayNameForSongListEntry({entry, hymnals, customMusic}: {
  entry?: SongListEntry,
  hymnals?: HymnalWithHymns[],
  customMusic?: CustomMusic,
}): string | undefined {
  if (isBound(entry)) {
    const hymn = getHymnFromSlug({songSlug: entry.slug, hymnals, customMusic});
    return hymn && (isCustomSong(entry.slug) ? hymn.title : `${userVisibleSongNumber(hymn.number)} - ${hymn.title}`);
  } else if (isUnbound(entry)) {
    return entry.text;
  }
}
