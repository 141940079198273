import {useLocalStorage} from '../../../data/use_local_storage';
import {LocalStorageKey} from '../../../data/client_local_storage';
import {useCallback} from 'react';
import {getWeeklySongListType, SongList, SongListTypeSupportsDeletion} from '../../../../common/model';
import {synchronizeAllSongLists, useChurch} from '../../../data/use_church';
import {recordSongListUpdate, songListUpdatesPending} from '../../../data/song_updates';

export interface AllSongLists {
  containsUpdates(): Promise<boolean>;
  deleteRowAt(rowIndex: number): Promise<void>;
  find(callback: (songList: SongList) => boolean): SongList | undefined;
  filter(callback: (songList: SongList) => boolean): SongList[];
  insertNewRowAt(rowIndex: number): Promise<void>;
  map<T>(callback: (songList: SongList) => T): T[];
  remove(songList: SongList): Promise<void>;
  update(songList: SongList): Promise<number>;
  updateEach(songList: SongList[]): Promise<void>;
}

// cannot useState because it lags behind when adding multiple song lists, so forced to use global (yuck!)
let nextSongListId = -1;

export function useAllSongLists(): AllSongLists {
  const {church} = useChurch();
  const [allSongLists, setAllSongLists] = useLocalStorage(LocalStorageKey.AllSongLists);

  const markSongListUpdated = useCallback(async (songList: SongList, {isDeleted}: {isDeleted?: boolean} = {}) => {
    await recordSongListUpdate(songList, {isDeleted});
    void synchronizeAllSongLists();
  }, []);

  const allocateNewSongListId = useCallback((): number => {
    return nextSongListId--;
  }, []);

  const containsUpdates = useCallback(async () => {
    return await songListUpdatesPending();
  }, []);

  const updateRowIndices = useCallback(async ({rowIndex, offset}: {rowIndex: number, offset: number}) => {
    const updatedSongLists: SongList[] = [];
    setAllSongLists(allSongLists.map(current => {
      if (current.type !== getWeeklySongListType(church)) {
        return current;
      }
      const newSongList = {...current,
        songs: current.songs
          .filter(({row}) => offset > 0 || row !== rowIndex) // remove rows if offset negative
          .map(song => ({...song, row: song.row >= rowIndex ? song.row + offset : song.row}))};
      if (current.songs.find(({row}) => row >= rowIndex)) {
        updatedSongLists.push(newSongList);
      }
      return newSongList;
    }));
    await Promise.all(updatedSongLists.map(async (songList) => {
      await recordSongListUpdate(songList);
    }));
    void synchronizeAllSongLists();
  }, [allSongLists, church, setAllSongLists]);

  const deleteRowAt = useCallback(async (rowIndex: number) => {
    await updateRowIndices({rowIndex, offset: -1})
  }, [updateRowIndices]);

  const find = useCallback((callback: (songList: SongList) => boolean) => {
    return allSongLists.find(callback);
  }, [allSongLists]);

  const filter = useCallback((callback: (songList: SongList) => boolean) => {
    return allSongLists.filter(callback);
  }, [allSongLists]);

  const insertNewRowAt = useCallback(async (rowIndex: number) => {
    await updateRowIndices({rowIndex, offset: 1})
  }, [updateRowIndices]);

  const map = useCallback((callback: (songList: SongList) => any) => {
    return allSongLists.map(callback);
  }, [allSongLists]);

  const remove = useCallback(async (songList: SongList) => {
    if (songList?.type !== SongListTypeSupportsDeletion) { // only allow events to be removed
      return;
    }
    setAllSongLists(allSongLists.filter(current => current.id !== songList.id));
    await markSongListUpdated(songList, {isDeleted: true});
  }, [allSongLists, setAllSongLists, markSongListUpdated]);

  const update = useCallback(async (songList: SongList) => {
    songList.id = songList.id ?? allocateNewSongListId();
    setAllSongLists([...allSongLists.filter(list => list.id !== songList.id), songList]);
    await markSongListUpdated(songList);
    return songList.id;
  }, [allSongLists, allocateNewSongListId, markSongListUpdated, setAllSongLists]);

  const updateEach = useCallback(async (songLists: SongList[]) => {
    for (const songList of songLists) {
      songList.id = songList.id ?? allocateNewSongListId();
      setAllSongLists([...allSongLists.filter(list => list.id !== songList.id), songList]);
      await recordSongListUpdate(songList);
    }
    void synchronizeAllSongLists();
  }, [allSongLists, allocateNewSongListId, setAllSongLists]);

  return {containsUpdates, deleteRowAt, find, filter, insertNewRowAt, map, remove, update, updateEach};
}
