import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ButtonContent, navigateToOnboardingPage, setOnboardingHouseholdSurveyResponse} from './common';
import {Frame} from './frame';
import {Button, LeftAlignedButton} from './button';
import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey} from '../../data/client_local_storage';
import * as server_api from '../../../common/server_api';
import {Church, HouseholdOriginSource} from '../../../common/model';
import {useChurch} from '../../data/use_church';
import styled from 'styled-components/macro';
import {ReactComponent as CloseCircleIcon} from "../../assets/close-circle.svg";
import {ReactComponent as SearchIcon} from '../../assets/search.svg';
import {useHousehold} from '../../data/use_household';
import {getPlatform, Platform} from '../../util/platform';
import {OnboardingPages} from '../../../common/pages';
import {isInsideMobileApp} from '../../util/billing';
import {useNavigate} from 'react-router-dom';
import {useChurches} from '../../data/use_churches';

export interface Props {
  onSettingChanged?(): void;
  onBack?(): void;
}

export const ChurchChoice = ({onSettingChanged, onBack}: Props) => {
  const churches = useChurches();
  const {church, setHouseholdChurch} = useChurch();
  const [searchString, setSearchString] = useState(() => church ? church.location : '');
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const {household} = useHousehold();
  const [onboardingChurch, setOnboardingChurch] = useLocalStorage(LocalStorageKey.OnboardingChurch);
  const navigate = useNavigate();

  useEffect(() => {
    searchInputRef.current?.select();
    searchInputRef.current?.focus();
  }, [searchInputRef]);

  const onSetChurch = useCallback((selectedChurch?: Church) =>
    () => {
      if (household) {
        setHouseholdChurch(selectedChurch?.id === church?.id ? undefined : selectedChurch);
      } else {
        setOnboardingChurch(selectedChurch?.id === onboardingChurch?.id ? undefined : selectedChurch);
      }
    }, [household, church?.id, setHouseholdChurch, onboardingChurch?.id, setOnboardingChurch]
  );

  const cancelChurchSearch = useCallback(() => {
    setSearchString('');
  }, [setSearchString]);

  const onSearchKeyUp = useCallback((e: any /*KeyboardEvent*/) => {
    if (e.key === 'Escape') {
      cancelChurchSearch();
      searchInputRef.current?.blur();
    }
    scrollableRef.current?.scrollTo(0, 0);
  }, [cancelChurchSearch, searchInputRef]);

  function renderChurchSearchControl() {
    return (
      <SubHeaderWithOpaqueBackground>
        <ChooseChurchSearch>
          <SearchIconWrapper>
            <SearchIcon/>
          </SearchIconWrapper>
          <SearchInput
            key='search'
            type='text'
            enterKeyHint='next'
            placeholder='City, State OR Church Name'
            onChange={(event) => setSearchString((event.target as HTMLInputElement).value)}
            onFocus={() => setTimeout(() => setSearchHasFocus(true))}
            onBlur={() => setTimeout(() => setSearchHasFocus(false))}
            onKeyUp={onSearchKeyUp}
            autoComplete='off'
            data-1p-ignore /* disable password manager on search control */
            data-lpignore="true" /* disable password manager on search control */
            value={searchString}
            ref={searchInputRef}
          />
          {/* Note that the built-in search cancel button does not work on iOS */}
          <CancelSearchIcon
            hidden={!searchHasFocus && searchString === ''}
            key='cancelSearch'
            onClick={cancelChurchSearch}
          >
            <CloseCircleIcon/>
          </CancelSearchIcon>
        </ChooseChurchSearch>
      </SubHeaderWithOpaqueBackground>
    );
  }

  const spaceRegEx = "[a-z0-9]*[^a-z0-9']+";
  // remove apostrophes before breaking up search terms with regular expression
  const searchTerms = searchString.replace(/['’,]/g, '').replaceAll(/\s+/g, spaceRegEx);
  const regEx = new RegExp(searchTerms, 'gi')
  const churchesFiltered = churches.filter(
    church => server_api.stringFromChurch(church).replace(/['’,]/g, '').search(regEx) !== -1
  );

  const sortedChurches =
    [
      ...churchesFiltered.sort((lhs, rhs) => {
        const lhsName = lhs.name.startsWith('The ') ? lhs.name.slice(4) : lhs.name;
        const rhsName = rhs.name.startsWith('The ') ? rhs.name.slice(4) : rhs.name;
        return lhsName.localeCompare(rhsName);
      })
    ];

  const churchesRendered = sortedChurches
    .map(churchBeingRendered => {
      const isSelected =
        (household && churchBeingRendered.id === church?.id) ||
        (!household && churchBeingRendered.id === onboardingChurch?.id);
      const {name, location} = churchBeingRendered;
      return (
        <LeftAlignedButton
          selected={isSelected}
          key={name + location}
          onClick={onSetChurch(churchBeingRendered)}
          notPrimary={true}
        >
          <ChurchWrapper>
            <ChurchName>{name}</ChurchName>
            <ChurchLocation>{location}</ChurchLocation>
          </ChurchWrapper>
        </LeftAlignedButton>
      );
    });

  const isChurchSelected = (household && church) || (!household && onboardingChurch);

  const onContinue = useCallback(() => {
    if (!isChurchSelected) {
      return; // keyboard can trigger this code path
    }
    if (onSettingChanged) {
      onSettingChanged();
    } else {
      setOnboardingHouseholdSurveyResponse({choseOrganization: true});
      if (household && household.surveyResponses !== undefined) {
        navigate('/');
      } else {
        navigateToOnboardingPage(navigate, OnboardingPages.SurveyHouseholdSchedule);
      }
    }
  }, [onSettingChanged, household, isChurchSelected, navigate]);

  const onChurchNotFound = useCallback(() => {
    onSetChurch(undefined)();
    if (onSettingChanged) {
      onSettingChanged();
    } else {
      setOnboardingHouseholdSurveyResponse({choseOrganization: false});
      navigateToOnboardingPage(navigate, OnboardingPages.ShareMusicLeader);
    }
  }, [onSetChurch, onSettingChanged, navigate]);

  let isSmallBackButton = Boolean(onSettingChanged);

  const {origin} = useLocalStorage(LocalStorageKey.OnboardingHouseholdSurveyResponses)[0];
  let prompt = origin === HouseholdOriginSource.School || origin === HouseholdOriginSource.Church
    ? `Which is your ${origin}?`
    : 'Select your church or school';
  let noun = origin === HouseholdOriginSource.School ? 'school' : 'church';

  const displayContinue = isChurchSelected ? 'revert': 'none';
  const displayNotFound = isChurchSelected && isInsideMobileApp() ? 'none': 'revert';

  return (
    <Frame
      onboardingPage={OnboardingPages.ChurchChoice}
      mainContent={(
        <>
          <Message $widthInChars={22} style={{paddingTop: isSmallBackButton ? '17px' : '20px'}}>
            {prompt}
          </Message>
          {renderChurchSearchControl()}
          <ButtonContent ref={scrollableRef}>
            {churchesRendered}
            <MobilePadding/>
          </ButtonContent>
        </>
      )}
      footerContent={(
        <>
          <Button onClick={onContinue} disabled={!isChurchSelected} style={{display: displayContinue}}>
            CONTINUE
          </Button>
          <Button onClick={onChurchNotFound} notPrimary={true} style={{display: displayNotFound}}>
            My {noun} isn't in the list
          </Button>
        </>
      )}
      onEnterKeypress={onContinue}
      isSmallBackButton={isSmallBackButton}
      onBack={onBack}
      alignTop={true}
    />
  )
};

const ChurchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; // TODO(hewitt): determine why ellipsis is not working
  text-align: left;
  padding: 5px 15px;
`;

const ChurchName = styled.div`
`;

const ChurchLocation = styled.div`
  font-family: Jost-Regular, sans-serif;
  color: var(--color-text-light);
`;

const SubHeaderWithOpaqueBackground = styled.div`
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 7px;
  text-align: center;
`;

export const Message = styled.div<{$widthInChars: number}>`
  font-size: 1.2em;
  width: ${props => props.$widthInChars}ch;
`;

const ChooseChurchSearch = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 90%;
  background-color: var(--color-background-search);
  border-radius: 20px;
  box-sizing: border-box;
  height: 40px;
`;

const CancelSearchIcon = styled.div`
  position: absolute;
  right: 8px;
  width: 25px;
  fill: var(--color-text-search-placeholder);
`;

const SearchIconWrapper = styled.div`
  width: 40px;
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 1px;
  fill: var(--color-text-search-placeholder);
`;


const SearchInput = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: medium;
  box-sizing: border-box;

  &:focus {
    outline: 0 none;
    color: var(--color-text);
  }

  &::placeholder{
    color: var(--color-text-search-placeholder);
  }
`;

const MobilePadding = styled.div`
  width: 5px;
  padding-bottom: ${getPlatform() === Platform.Mobile ? '30vh' : 0};
`;
