import {Church, HymnalSlug, OrganizationKey, SongNumber, SongSlug} from './model';

export const musicManifestFilename = 'manifest3.json';
export const ignoreDirectories = ['.git', '.idea'];
export const metadataFilename = 'metadata.json';

export const customMusicDir = 'custom-music';
export const hymnalsDir = 'hymnals';
export const hymnalsPDFDir = 'hymnals';
export const vocalsDir = 'vocals';
export const songListsDir = 'song-lists';

export const churchHymnalIconPath = '/images/open-book.svg';

const CustomMusicHymnalName = 'cm';

export function getSongSlug({songNumber, hymnalSlug, churchId}: {
  songNumber: SongNumber,
  hymnalSlug?: HymnalSlug,
  churchId?: number
}): SongSlug {
  if (!hymnalSlug && !churchId) {
    throw new Error(`Must specify either hymnalSlug or churchId for song number ${songNumber}`);
  }
  if (hymnalSlug === CustomMusicHymnalName && !churchId) {
    throw new Error(`Must specify churchId for custom song number ${songNumber}`);
  }
  const prefix = hymnalSlug && hymnalSlug !== CustomMusicHymnalName
    ? `hymnal/${hymnalSlug.toLowerCase()}`
    : `church/${churchId}`;
  return `${prefix}/song/${songNumber}`;
}

export function getChurchCustomMusicManifestPath(organizationKey: OrganizationKey): string {
  return `/${customMusicDir}/${organizationKey}/${musicManifestFilename}`;
}

export function getHymnalIconPath(hymnalName: string) {
  return `/${hymnalsDir}/${hymnalName}/icon.png`;
}

export function parseSongSlug(slug: SongSlug): {
  songNumber: SongNumber;
  hymnalSlug?: HymnalSlug;
  churchId?: number;
} {
  const {prefix, hymnalSlugOrChurchId, songNumber} = slug.match(
    /^(?<prefix>(hymnal|church))\/(?<hymnalSlugOrChurchId>[a-zA-Z0-9]+)\/song\/(?<songNumber>[a-zA-Z0-9]+)$/
  )?.groups ?? {};
  if (!prefix || !hymnalSlugOrChurchId || !songNumber) {
    throw new Error(`Malformed song slug: ${slug}`);
  }
  if (prefix === 'hymnal') {
    return {songNumber, hymnalSlug: hymnalSlugOrChurchId as HymnalSlug};
  } else {
    const churchId = Number(hymnalSlugOrChurchId);
    if (isNaN(churchId)) {
      throw new Error(`Malformed song slug: ${slug}`);
    }
    return {songNumber, churchId}
  }
}

// converts 8.01 to '008.01'
export function getSongFilePrefix(numericSongNumber: number) {
  const wholePart = Math.trunc(numericSongNumber);
  const decimalPart = Math.round((numericSongNumber - wholePart) * 100) / 100;
  return wholePart.toString().padStart(3, '0') + (decimalPart === 0 ? '' : decimalPart.toString().slice(1));
}

export function getChurchDirectoryName(church: Church): string {
  return `${church.id} - ${church.name} (${church.location})`;
}
