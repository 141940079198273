import React, {ReactElement, useCallback, useEffect, useRef, useState} from "react";
import * as server_api from "../../common/server_api";
import {CopyrightIssue} from "../../common/model";
import {ensureUnreachable} from "../../common/util";
import {OnboardingPages} from '../../common/pages';
import {Frame} from './onboarding/frame';
import {Message} from './onboarding/common';
import {Button} from './onboarding/button';
import styled from 'styled-components/macro';
import {confirmable, ConfirmDialog, createConfirmation} from 'react-confirm';

export interface Props {
  hymnalName: string;
  isDialog?: boolean;
  isMidiFileMissing?: boolean;
  issue?: CopyrightIssue;
  onDisplayPDF?: () => void;
  songNumber: number;
}

export function RequestSongPage({
  hymnalName, isDialog, isMidiFileMissing, issue, onDisplayPDF, songNumber
}: Props) {
  const [requested, setRequested] = useState(false);

  let message: ReactElement;
  let allowPDF: boolean;
  if (isMidiFileMissing || !issue) {
    message = <span>We'll add this song soon!<br/>Request it to help us prioritize.</span>;
    allowPDF = true;
  } else {
    switch (issue) {
      case CopyrightIssue.Music:
        message = <span>The copyright holder has not yet granted permission for this song.</span>;
        allowPDF = false;
        break
      case CopyrightIssue.Text:
        message = <span>The copyright holder has not yet granted permission for the text.</span>;
        allowPDF = false;
        break
      default:
        ensureUnreachable(issue, 'Failed to cover all HymnIssue values');
    }
  }

  const onRequestSong = useCallback(() => {
    if (requested) {
      return;
    }
    setRequested(true);
    void server_api.addSongRequest({
      hymnalName: hymnalName,
      songNumber: songNumber,
      timestamp: Date.now(),
      issue: issue,
    });
  }, [hymnalName, issue, songNumber, requested]);

  return (
    <RequestSongWrapper $isDialog={Boolean(isDialog)}>
      <FrameWrapper $shiftUp={(allowPDF || issue === CopyrightIssue.Music) && !isMidiFileMissing}>
        <Frame
          onboardingPage={OnboardingPages.Start}
          mainContent={(
            <RequestSongMessage $widthInChars={isDialog? 22 : 24}>{message}</RequestSongMessage>
          )}
          footerContent={(
            <>
              <Button onClick={onRequestSong} $requested={requested}>
                {requested ? <span><b>✓</b> REQUESTED</span> : 'REQUEST SONG'}
              </Button>
              {allowPDF && onDisplayPDF && <Button onClick={onDisplayPDF}>VIEW PDF</Button>}
            </>
          )}
          onEnterKeypress={onRequestSong}
          embed={true}
        />
      </FrameWrapper>
    </RequestSongWrapper>
  );
}

const RequestSongMessage = styled(Message)<{$isDialog: boolean}>`
  font-size: 1em;
  padding-bottom: ${props => props.$isDialog ? '0' : '100px'};
`;

// TODO(hewitt): Some serious CSS hacking here :/ - ideally, the buttons would be bottom-aligned like onboarding.
//               However, the player page header forces them down off the screen.
const RequestSongWrapper = styled.div<{$isDialog: boolean}>`
  width: ${props => props.$isDialog ? 'auto' : '100vw'};
  height: ${props => props.$isDialog ? 'auto' : '100%'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FrameWrapper = styled.div<{$shiftUp: boolean}>`
  margin-top: ${props => props.$shiftUp ? '-25%' : '35%'};
  height: max(200px, 30%);
`;




interface DialogProps {
  confirmation: Props;
}

const RequestSongPageDialog: ConfirmDialog<DialogProps, boolean> = ({confirmation, proceed, show}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    if (show) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [show, dialogRef]);
  const onDisplayPDF = useCallback(() => {
    proceed(false);
  }, [proceed]);
  return (
    <Dialog ref={dialogRef}>
      <RequestSongPage
        issue={confirmation.issue}
        isMidiFileMissing={confirmation.isMidiFileMissing}
        hymnalName={confirmation.hymnalName}
        songNumber={confirmation.songNumber}
        onDisplayPDF={onDisplayPDF}
        isDialog={true}
      />
    </Dialog>
  );
}

export const requestSongPageDialog = createConfirmation(confirmable(RequestSongPageDialog));

const Dialog = styled.dialog`
  color: var(--color-text);
  background-color: var(--color-background);
  border: none;
  border-radius: 20px;
  position: fixed;
  margin: auto;
`
