import styled from 'styled-components/macro';
import {SongCellPadding, SongRowWidth} from './shared';
import {KeyboardEvent, useCallback, useEffect, useRef} from 'react';

export interface TextCellEditorProps {
  initialValue?: string;
  onClose: (text: string | undefined) => void;
  onEnter?: () => void;
  selectValue: boolean;
}

export function TextCellEditor({initialValue, onClose, onEnter, selectValue}: TextCellEditorProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const {current} = inputRef;
    if (!current) {
      return;
    }
    current.focus();
    if (selectValue) {
      current.select();
    }
  } , [inputRef, selectValue]);

  const onBlur = useCallback(() => {
    onClose(inputRef.current?.value);
  }, [onClose]);

  const onKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    // Treat <Enter> keypress like <Tab>
    if (event.key === 'Enter') {
      onEnter?.();
    } else if (event.key === 'Escape') {
      onClose(undefined);
    } else if (['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].includes(event.key)) {
      event.stopPropagation();
    }
  }, [onEnter, onClose]);

  return (
    <TextCellEditorWrapper>
      <TextCellInput onBlur={onBlur} ref={inputRef} defaultValue={initialValue} onKeyDown={onKeyDown}/>
    </TextCellEditorWrapper>
  );
}

const TextCellEditorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const TextCellInput = styled.input`
  width: calc(${SongRowWidth} - (2 * ${SongCellPadding}));
  border: none;
  background: transparent;
`;
