// NOTE: Node does not have a native indexeddb implementation, so we run these tests in the client at startup
//       and report failures in the console.

import {DateString} from '../../../common/date_string';
import {SongListType} from '../../../common/model';
import {
  clearAllSongListUpdates,
  DeletableSongList,
  getCurrentSongListUpdateTimestamp,
  getSongListUpdatesBefore,
  recordSongListUpdate,
  removeSongListUpdatesBefore,
  songListUpdatesPending,
  testSetup,
  testTeardown
} from '../song_updates';

let testsExecuted = false;

export async function runSongUpdateTests() {
  if (testsExecuted) {
    return;
  }

  await testSetup();
  try {
    await clearAllSongListUpdates();

    assertDeepEqual(await getSongListUpdatesBefore(10000000), []);
    assert(!(await songListUpdatesPending()));

    const songLists = [
      songList(10, '2025-01-01'),
      songList(11, '2025-01-02'),
      songList(12, '2025-01-03', {isDeleted: true}),
    ];

    const firstTimestamp = getCurrentSongListUpdateTimestamp();
    await recordSongListUpdate(songLists[0]!);
    assert(await songListUpdatesPending());
    const secondTimestamp = getCurrentSongListUpdateTimestamp();
    assertDeepEqual(await getSongListUpdatesBefore(secondTimestamp), [songLists[0]]);
    assertDeepEqual(await getSongListUpdatesBefore(firstTimestamp), []);

    await recordSongListUpdate(songLists[1]);
    assert(await songListUpdatesPending());
    const thirdTimestamp = getCurrentSongListUpdateTimestamp();
    assertDeepEqual(await getSongListUpdatesBefore(thirdTimestamp), [songLists[0], songLists[1]]);
    assertDeepEqual(await getSongListUpdatesBefore(secondTimestamp), [songLists[0]]);
    assertDeepEqual(await getSongListUpdatesBefore(firstTimestamp), []);

    await recordSongListUpdate(songLists[2]!);
    const forthTimestamp = getCurrentSongListUpdateTimestamp();
    assertDeepEqual(await getSongListUpdatesBefore(forthTimestamp), [songLists[0], songLists[1], songLists[2]]);

    await removeSongListUpdatesBefore(firstTimestamp);
    assertDeepEqual(await getSongListUpdatesBefore(forthTimestamp), [songLists[0], songLists[1], songLists[2]]);

    await removeSongListUpdatesBefore(secondTimestamp);
    assertDeepEqual(await getSongListUpdatesBefore(forthTimestamp), [songLists[1], songLists[2]]);

    await removeSongListUpdatesBefore(thirdTimestamp);
    assertDeepEqual(await getSongListUpdatesBefore(forthTimestamp), [songLists[2]]);
    assert(await songListUpdatesPending());

    await removeSongListUpdatesBefore(forthTimestamp);
    assertDeepEqual(await getSongListUpdatesBefore(forthTimestamp), []);
    assert(!(await songListUpdatesPending()));

    await clearAllSongListUpdates();
  } finally {
    testsExecuted = true;
    await testTeardown();
  }
}

function songList(id: number, date: DateString, {isDeleted}: {isDeleted?: boolean} = {}): DeletableSongList {
  return {id, date, type: SongListType.WorshipService, songs: [], ...(isDeleted && {isDeleted})};
}

function assertDeepEqual(lhs: any, rhs: any) {
  if (JSON.stringify(lhs) !== JSON.stringify(rhs)) {
    assert(false, `Expected ${JSON.stringify(lhs)} to equal ${JSON.stringify(rhs)}`);
  }
}

function assert(condition: boolean, message?: string) {
  if (!condition) {
    console.log('**********************************************************************');
    console.trace(`ERROR (song_update_test): ${message ?? 'Assertion failure'}`);
    console.log('**********************************************************************');
  }
}
