import {useCallback} from 'react';
import styled from 'styled-components/macro';
import {ReactComponent as AddIcon} from '../assets/add.svg';
import {addCustomSong} from '../pages/onboarding/add_custom_song';
import {useCustomMusic} from '../data/use_custom_music';

export const UploadSongButton = () => {
  const customMusic = useCustomMusic();

  const onClick = useCallback(async () => {
    await addCustomSong({customMusic});
  }, [customMusic]);

  return (
    <UploadSongButtonWrapper onClick={onClick}>
      <AddIconWrapper/>
    </UploadSongButtonWrapper>
  );
}

const UploadSongButtonWrapper = styled.button`
  z-index: 1;
  background-color: var(--color-background-button-onboarding);
  margin-left: 10px;
  margin-right: -35px; // avoid causing title to be off center
  padding: 2.5px;
  border-radius: 30px;
  cursor: pointer;
`;

const AddIconWrapper = styled(AddIcon)`
  width: 25px;
  color: var(--color-background);
`;
