import styled from 'styled-components/macro';
import {
  getAllLocalStorageKeys,
  localStorageGet,
  LocalStorageKey,
  localStorageRemove,
  localStorageSet,
  OnboardingLocalStoragePrefix
} from '../../data/client_local_storage';
import {withSearchParams} from '../../util/use_location';
import {getOnboardingPath, OnboardingPages} from '../../../common/pages';
import {Device, getDevice} from '../../util/platform';
import {getAppStoreUrl, isInsideMobileBrowser} from '../../util/billing';
import {Household, HouseholdSurveyResponses} from '../../../common/model';
import * as server_api from '../../../common/server_api';
import {synchronizeHouseholdWithServer} from '../../data/use_household';
import {NavigateFunction} from 'react-router-dom';

const musicLeaderMessage = 'Hello,\n' +
  '\n' +
  'This app makes the church’s music more accessible.  Check out the app here: https://singyourpart.org\n' +
  '\n' +
  'Click the link below to set a 20 minute phone call with their team located in Boise and they\'ll go over what it would look like for the church. Thank you!\n' +
  '\n' +
  'https://calendar.app.google/cn23rz2diHASqKon9';

export async function shareWithMusicLeader() {
  // *** Note that this only works over https in iOS Safari ***
  await navigator.share({
    ...(getDevice() !== Device.iOS && {title: musicLeaderMessage}), // prefer the title
    ...(getDevice() === Device.iOS && {text: musicLeaderMessage}), // fall back on the text for Safari
  });
}

const friendsAndFamilyMessage = 'Check out this church music app!';
const friendsAndFamilyUrl = 'https://singyourpart.org';

export async function shareWithFriendsAndFamily() {
  // *** Note that this only works over https in iOS Safari ***
  await navigator.share({
    ...(getDevice() !== Device.iOS && {title: friendsAndFamilyMessage}), // prefer the title
    url: friendsAndFamilyUrl,
    ...(getDevice() === Device.iOS && {text: friendsAndFamilyMessage}), // fall back on the text for Safari
  });
}

export const Title = styled.div`
  // TODO(hewitt): Use Bold instead of SemiBold 
  font-family: Jost-SemiBold, sans-serif;
  font-size: 2.7em;
  color: var(--color-text-button-onboarding);
  padding-bottom: 0.5em;
  text-wrap: nowrap;
`;

export const Message = styled.div<{
  $widthInChars: number;
  $lessPadding?: boolean;
  $moreTopPadding?: boolean;
}>`
  font-size: 1.3em;
  width: ${props => props.$widthInChars}ch;
  padding-bottom: ${props => props.$lessPadding ? '0.5em' : '1.5em'};
  ${props => props.$moreTopPadding && `padding-top: 4em`}
`;

export const BoldMessage = styled.span`
  font-family: Jost-SemiBold, sans-serif;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.3em;
  overflow: scroll;
`;

export const ButtonContent = styled(Content)`
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 10px;
`;

export const TextInput = styled.input`
  font-family: Jost-SemiBold, sans-serif;
  font-size: 1.2em;
  border: none;
  border-bottom: 1px solid var(--color-text);
  text-align: center;
  color: var(--color-text);
  background-color: transparent;
  width: 20ch;
`;

export function clearOnboardingLocalStorage() {
  for (const key of getAllLocalStorageKeys()) {
    if (key.startsWith(OnboardingLocalStoragePrefix)) {
      localStorageRemove(key as LocalStorageKey);
    }
  }
}

export const SubscribeContextQueryString = 'subscribe';
export const EditAllHouseholdInfoQueryString = 'edit-all';
export const ChurchIdTokenQueryString = 'church-id';
export const ChurchAdminTokenQueryString = 'church-admin-token';
export const PreExistingHouseholdInfoQueryString = 'pre-existing-household';
export const ActivateSearchQueryString = 'activate-search';

export function subscribing(): boolean {
  const params = new URLSearchParams(window.location.search);
  return params.has(SubscribeContextQueryString);
}

export function isAdminInvite(): boolean {
  const params = new URLSearchParams(window.location.search);
  return params.has(ChurchAdminTokenQueryString);
}

export function isHouseholdInvite(): boolean {
  const params = new URLSearchParams(window.location.search);
  return params.has(ChurchIdTokenQueryString) && !params.has(ChurchAdminTokenQueryString);
}

export function editAllHouseholdFields(): boolean {
  const params = new URLSearchParams(window.location.search);
  return params.has(EditAllHouseholdInfoQueryString);
}

export function activateSearch(): boolean {
  const params = new URLSearchParams(window.location.search);
  return params.has(ActivateSearchQueryString);
}

export function navigateToOnboardingPage(
  navigate: NavigateFunction,
  page: OnboardingPages,
  ...additionalParams: string[]
) {
  navigate(withSearchParams(getOnboardingPath(page), ...additionalParams));
}

export function isDebugOnboardingEnvironment(): boolean {
  // iOS has a test app (TestFlight) that runs against test.singyourpart.app, so it is not considered a "debug" env
  // Android does not, so we consider test.singyourpart.app to be a debug env so that we can test onboarding for Android
  return window.location.port === '3000' ||
    (getDevice() === Device.Android && window.location.hostname.startsWith('test'));
}

export function navigateAfterInvite(navigate: NavigateFunction, household?: Household) {
  console.log('navigateAfterInvite', navigate, household);
  if (isInsideMobileBrowser()) {
    const appStoreUrl = getAppStoreUrl();
    if (appStoreUrl) {
      window.location.href = appStoreUrl;
      return;
    } else {
      // throw up our hands
      return navigateToOnboardingPage(navigate, OnboardingPages.Download);
    }
  }

  if (household && !household.surveyResponses) {
    return navigateToOnboardingPage(navigate, OnboardingPages.Questions);
  }

  window.location.href = '/';
}

export function setOnboardingHouseholdSurveyResponse(response: HouseholdSurveyResponses) {
  localStorageSet(LocalStorageKey.OnboardingHouseholdSurveyResponses, {
    ...localStorageGet(LocalStorageKey.OnboardingHouseholdSurveyResponses),
    ...response,
  });
}

export async function uploadLatestSurveyResponses() {
  const household = localStorageGet(LocalStorageKey.Household);
  if (!household || !household.token || !household.surveyResponses) {
    return;
  }
  const newResponses = localStorageGet(LocalStorageKey.OnboardingHouseholdSurveyResponses);
  await server_api.recordHouseholdSurveyResponses({
    householdToken: household.token,
    responses: {
      ...household.surveyResponses,
      ...newResponses
    },
  });
  clearOnboardingLocalStorage();
  await synchronizeHouseholdWithServer({force: true});
}
