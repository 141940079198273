import {ReactComponent as BackArrowIcon} from '../assets/arrow-previous-left-icon.svg';
import React, {useCallback, useEffect, useState} from 'react';
import {RequestSongPage, requestSongPageDialog} from './request_song_page';
import {ensureExists} from '../../common/util';
import {Document, Page} from 'react-pdf';
import {getPdfUrlForHymn} from '../util/path';
import {useUserAttributes} from '../data/use_user_attributes';
import styled from 'styled-components/macro';
import {CopyrightIssue, Hymn} from '../../common/model';
import {useLocalStorage} from '../data/use_local_storage';
import {LocalStorageKey} from '../data/client_local_storage';
import {Spinner} from '../util/spinner';
import {FloatingBackButton} from '../util/shared';
import {Clef, Note, noteSpacing, NoteType} from '../util/musicRenderer';

interface Props {
  hymn: Hymn;
  width: number;
  height: string; // values like '75vh'
  headerHeight?: string; // values like '55px'
  onClose?: () => void;
  isMidiFileMissing?: boolean;
  onFinishedLoading?: () => void;
  isPDFVisible?: boolean;
  isInSongSelector?: boolean;
}

export function PdfViewerPage({
  hymn,
  width,
  height,
  headerHeight,
  isMidiFileMissing,
  onClose,
  onFinishedLoading,
  isPDFVisible,
  isInSongSelector,
}: Props) {
  const [numPages, setNumPages] = useState<number | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>();
  const pdfUrl = getPdfUrlForHymn(hymn);
  const {isInternalUser} = useUserAttributes();
  const [requestSongDisplayedForPdf, setRequestSongDisplayedForPdf] =
    useLocalStorage(LocalStorageKey.RequestSongDisplayedForPDF);
  const [isSolfegeVisible, setIsSolfegeVisible] = React.useState(false);

  useEffect(() => {
    void (async () => {
      // only show modal if .mid file is missing and .pdf file loaded successfully
      if (!isMidiFileMissing || !numPages || requestSongDisplayedForPdf[hymn.hymnal]?.[hymn.number]) {
        return;
      }
      setRequestSongDisplayedForPdf({
        ...requestSongDisplayedForPdf, [hymn.hymnal]: {
          ...requestSongDisplayedForPdf[hymn.hymnal], [hymn.number]: true
        }
      });
      await requestSongPageDialog({
        confirmation: {
          issue: hymn.issue,
          isMidiFileMissing: isMidiFileMissing,
          hymnalName: ensureExists(hymn.hymnal),
          songNumber: hymn.number,
        }
      });
    })();
  }, [
    requestSongDisplayedForPdf,
    setRequestSongDisplayedForPdf,
    hymn.hymnal,
    hymn.number,
    hymn.issue,
    isMidiFileMissing,
    numPages,
  ]);

  const onLoadSuccess = useCallback(({numPages}: { numPages: number }) => {
    setError(undefined);
    setNumPages(numPages);
    onFinishedLoading?.();
  }, [onFinishedLoading]);

  const onLoadError = useCallback((error: Error) => {
    setError(error);
    console.log(error.message);
    onFinishedLoading?.();
  }, [onFinishedLoading]);

  function renderLoadingPage() {
    return (
      <LoadingWrapper>
        <LoadingMessage>
          Loading sheet music...
        </LoadingMessage>
        <Spinner/>
      </LoadingWrapper>
    );
  }

  // TODO(hewitt): present request song page in song selector
  const isRequestPageVisible = !isInSongSelector && (
    (hymn.issue === CopyrightIssue.Text && !isInternalUser) || error !== undefined);

  const sheetMusic = isSolfegeVisible ? (
      <SolfegeSVG/>
    ) : (
      <PdfDoc key="pdf" $height={height} $headerHeight={headerHeight}>
        {
          isRequestPageVisible &&
          <RequestSongPage
            issue={error && CopyrightIssue.Text}
            isMidiFileMissing={error !== undefined}
            hymnalName={ensureExists(hymn.hymnal)}
            songNumber={hymn.number}/>
        }
        <div style={{...((numPages === undefined || error || isRequestPageVisible) && {visibility: 'hidden'})}}>
          <Document
            file={pdfUrl}
            options={{workerSrc: "/pdf.worker.js"}}
            onLoadSuccess={onLoadSuccess}
            onLoadError={onLoadError}
          >
            {
              Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map(page => <Page pageNumber={page} key={page} width={width}/>)
            }
          </Document>
        </div>
      </PdfDoc>
  );

  const onSolfegeToggle = useCallback(() => {
    setIsSolfegeVisible(!isSolfegeVisible);
  }, [isSolfegeVisible]);

  return isPDFVisible ? (
    <>
      <FloatingBackButton onClick={onClose}><BackArrowIcon/></FloatingBackButton>
      {isInternalUser && (
        <FloatingSolfegeButton onClick={onSolfegeToggle}>
          {isSolfegeVisible ? 'Lyrics' : 'Solfège'}
        </FloatingSolfegeButton>
      )}
      {sheetMusic}
      {!isRequestPageVisible && error === undefined && numPages === undefined && renderLoadingPage()}
    </>
  ) : sheetMusic;
}
export const PdfDoc = styled.div<{$height: string, $headerHeight?: string}>`
  ${props =>
    props.$headerHeight ? `
      margin-top: -${props.$headerHeight};
    ` : ''
  }
  height: ${props => props.$height};
  overflow-y: scroll;
  overflow-x: clip;
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingMessage = styled.div`
  font-size: 1.3em;
  width: 20ch;
  padding-bottom: 1.5em;
`;

const FloatingSolfegeButton = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index:1000;
  // TODO(hewitt): properly set back button color once PDF is displayed white on black for dark mode
  // background-color: color-mix(in srgb, var(--color-background) 80%, transparent);
  // color: var(--color-text);
  background-color: rgba(255, 255, 255, 0.8);
  margin: 10px 18px;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.2em;
  border: 2px solid var(--color-text);
`

function SolfegeSVG() {
  const width = 3000;
  const height = 1500;
  const clefCenterY = 500;
  return (
    <div style={{fill: 'var(--color-text)', stroke: 'var(--color-text)', background: 'var(--color-background)'}}>
      <SVGWrapper viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
        <Clef left={0} right={width} centerY={clefCenterY}/>
        <Note type={NoteType.Whole} center={{x: width / 2 - noteSpacing * 16, y: clefCenterY - noteSpacing * 4}}/>
        <Note type={NoteType.Half} center={{x: width / 2 - noteSpacing * 12, y: clefCenterY - noteSpacing * 3}}/>
        <Note type={NoteType.Quarter} center={{x: width / 2 - noteSpacing * 8, y: clefCenterY - noteSpacing * 2}}/>
        <Note type={NoteType.Eighth} center={{x: width / 2 - noteSpacing * 4, y: clefCenterY - noteSpacing}}/>
        <Note type={NoteType.Sixteenth} center={{x: width / 2, y: clefCenterY}}/>
      </SVGWrapper>
    </div>
  );
}

const SVGWrapper = styled.svg`
  width: 1500px;
`;
