import {Household, SongNumber} from './model';

export function ensure(value: any, message?: string): asserts value {
  if (!value) {
    throw new Error(message ?? `value not truthy`);
  }
}

export function ensureExists<T>(value: T | undefined | null, msg?: string): T {
  if (typeof (value) === 'undefined' || value === null) {
    throw new Error(msg ?? 'Expected value to be defined and not null');
  }
  return value;
}

export function ensureUnreachable(value: never, message?: string): never {
  throw new Error(`Unreachable code ${message}`);
}

export function userVisibleSongNumber(number: number): SongNumber {
  const decimal = number % 1;
  const suffix = (decimal ? String.fromCharCode(Math.round(decimal * 100) + 64) : '').toLowerCase();
  return Math.floor(number).toString() + suffix;
}

export function getNumericSongNumber(userVisibleSongNumber: string): number {
  const {numericPrefix, alphabeticSuffix} =
    userVisibleSongNumber.toLowerCase().match(/(?<numericPrefix>[0-9]+)(?<alphabeticSuffix>[a-z])?/)?.groups ?? {};
  if (!numericPrefix) {
    throw new Error(`no numeric prefix for song number ${userVisibleSongNumber}`);
  }
  const paddedNumericSuffix = alphabeticSuffix &&
    (alphabeticSuffix.charCodeAt(0) - 'a'.charCodeAt(0) + 1).toString().padStart(2, '0');
  const numberString = numericPrefix + (paddedNumericSuffix ? `.${paddedNumericSuffix}` : '');
  return Number(numberString);
}

export function isValidEmail(email?: string): boolean {
  if (!email) {
    return false;
  }
  return Boolean(
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ));
}

export function objectsEqual(lhs: any, rhs: any) {
  return JSON.stringify(lhs) === JSON.stringify(rhs)
}

export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function shallowListCompare(lhs: any[], rhs: any[]) {
  if (lhs.length !== rhs.length) {
    return false;
  }
  for (let i = 0; i < lhs.length; i++) {
    if (lhs[i] !== rhs[i]) {
      return false;
    }
  }
  return true;
}

// used to determine if two objects are equivalent or if an object instance has changed (been recreated)
// Usages:  objectId(obj1) === objectId(obj2)  - OR-  console.log(objectId(obj))
export const objectId = (() => {
  let currentId = 0;
  const map = new WeakMap();

  return (object: any) => {
    if (!map.has(object)) {
      map.set(object, ++currentId);
    }

    return map.get(object);
  };
})();

export type NonEmptyArray<T> = [T, ...T[]];

export async function assertThrowsAsync(callback: () => Promise<void>, message?: string) {
  let exceptionThrown: boolean;
  try {
    await callback();
    exceptionThrown = false;
  } catch {
    exceptionThrown = true;
  }
  if (!exceptionThrown) {
    throw new Error(message ?? 'Expected exception');
  }
}

export function getUnknownHouseholdCredentials(
  token: string
): Pick<Household, 'familyLastName' | 'householdEmail' | 'users'> {
  return {familyLastName: 'Unknown', householdEmail: `${token}@unknown.com`, users: []};
}
