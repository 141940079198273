import {Hymn, CopyrightIssue} from "../../common/model";
import {isInsideAppleAppStoreApp} from './billing';
import styled from 'styled-components/macro';
import {ReactComponent as CheckMarkIcon} from "../assets/check-mark-icon.svg";
import {ReactComponent as BackArrowIcon} from '../assets/arrow-previous-left-icon.svg';
import {ReactComponent as CloseIcon} from '../assets/close.svg';
import {HymnalWithHymns} from '../data/use_hymnals';
import React from 'react';
import {ReactComponent as QuarterNoteIcon} from '../assets/quarter-note.svg';
import {localStorageGet, LocalStorageKey} from '../data/client_local_storage';
import {getSongSlugForHymn} from './path';

export function isHymnUrl(search?: string): boolean {
  const searchParams = new URLSearchParams(search);
  const hymnTitle = searchParams.get('title');
  const hymnNumber = Number(searchParams.get('number'));
  const hymnalName = searchParams.get('hymnal');
  return !!hymnTitle && !!hymnNumber && !!hymnalName;
}

export function getHymnFromUrl(hymnals: HymnalWithHymns[], search?: string): Hymn | undefined {
  if (!search) {
    return;
  }
  const searchParams = new URLSearchParams(search);
  const hymnTitle = searchParams.get('title');
  const hymnNumber = Number(searchParams.get('number'));
  const hymnalName = searchParams.get('hymnal');
  const basePath = searchParams.get('basePath') as (string | undefined);
  const issue = searchParams.get('issue') as (CopyrightIssue | undefined);

  if (!hymnTitle || !hymnNumber || !hymnalName) {
    return;
  }

  const correctedHymnalName = hymnalName.replace(/_/g, ' ');
  const slug = getSongSlugForHymn(hymnals, {number: hymnNumber, hymnal: correctedHymnalName, basePath});

  if (!slug) {
    return;
  }

  let hymn: Hymn;
  const correctedHymnTitle = hymnTitle.replace(/_/g, ' ');
  const psalmName = searchParams.get('psalm');

  if (psalmName) {
    const correctedPsalmName = psalmName.replace(/_/g, ' ');

    hymn = {
      slug,
      hymnal: correctedHymnalName,
      title: correctedHymnTitle,
      number: hymnNumber,
      psalm: correctedPsalmName,
      ...(basePath && {basePath}),
      ...(issue && {issue}),
    };
  } else {
    hymn = {
      slug,
      hymnal: correctedHymnalName,
      title: correctedHymnTitle,
      number: hymnNumber,
      ...(basePath && {basePath}),
      ...(issue && {issue}),
    };
  }

  return hymn;
}

export const OuterPageContent = styled.div<{$noHeight?: boolean}>`
  display: flex;
  flex-flow: column;
  // Account for mobile nav bar height (iOS Safari fails to access the CSS variable, so short circuit)
  height: ${props =>
    props.$noHeight ? 'auto' : (
      isInsideAppleAppStoreApp() ? '100vh' : 'var(--view-height, 100vh)'
    )
};
`

export const InnerPageContent = styled.div`
  flex: auto;
  overflow-y: auto;
  white-space: nowrap;
  max-width: 100vw;
  isolation: isolate;
`

export const SettingsTransitionWrapper = styled(OuterPageContent)<{$left?: string;}>`
  position: fixed;
  left: ${props => props.$left};
  width: 100%;
  top: 0;
  bottom: 0;
  transition: left 200ms;
  transition-timing-function: ease-out;
`;

export const PlainLinkOutlined = styled.a<{$small?: boolean}>`
  margin-top: 10px;
  padding: 5px 10px;
  border: 3px solid var(--color-text);
  border-radius: 15px;
  cursor: pointer;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: ${props => props.$small ? '1em' : '1.3em'};
  text-decoration: none;
  width: fit-content;

  &:hover {
    background-color: lightgray;
  }
`;

export const ContactSupportLink = ({capitalized}: {capitalized?: boolean}) => (
  <a href="mailto:support@crescendosw.com">{capitalized ? 'C' : 'c'}ontact support</a>
);

export const FloatingBackButton = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 36px;
  z-index:1000;
  // TODO(hewitt): properly set back button color once PDF is displayed white on black for dark mode
  // background-color: color-mix(in srgb, var(--color-background) 80%, transparent);
  // color: var(--color-text);
  background-color: rgba(255, 255, 255, 0.8);
  margin: 2px 8px;
  padding: 15px 10px;
  border-radius: 3px;
  cursor: pointer;
`

export const CheckMark = styled(CheckMarkIcon)<{$size?: string; $padding?: string; $fillColor: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.$size ?? '35px'};
  height: ${props => props.$size ?? '35px'};
  padding-right: ${props => props.$padding ?? '15px'};
  margin-left: auto;
  fill: ${props => props.$fillColor ?? 'var(--color-text)'};
`;

export const BackArrow = styled(BackArrowIcon)`
  align-items: center;
  height: 24px;
  cursor: pointer;
  fill: var(--color-text);
`;

export function QuarterNote({hidden, width}: {hidden?: boolean, width?: string}) {
  return <QuarterNoteWrapper hidden={hidden} $width={width}><QuarterNoteIcon/></QuarterNoteWrapper>
}

const QuarterNoteWrapper = styled.div<{$width?: string}>`
  fill: var(--color-text);
  width: ${props => props.$width ? props.$width : '12px'};
  margin-right: 3px;
`;

// from https://stackoverflow.com/a/29883167/998490
export function focusNextTabStop(element: HTMLInputElement): void {
  const universe = document.querySelectorAll('input, button, select, textarea, a[href]');
  const list = Array.prototype.filter.call(universe, function(item) {return item.tabIndex >= "0"});
  const index = list.indexOf(element);
  const nextElement = list[index + 1] || list[0];
  nextElement?.focus();
}

export function userIsSignedIn() {  // TODO(halvorson) remove 3/2025
  const token = localStorageGet(LocalStorageKey.ActiveUserToken);

  if (!token) {
    return false
  }
  return token.length > 1;
}

export const ContactSupportButton = ({small}: {small?: boolean}) => {
  return <PlainLinkOutlined href="mailto:support@crescendosw.com" $small={small}>Contact Support</PlainLinkOutlined>;
}

export function CloseButton({onClick, isCloseToTop}: {onClick: () => void, isCloseToTop?: boolean}) {
  return (
    <CloseButtonWrapper onClick={onClick} $isCloseToTop={isCloseToTop}>
      <CloseIcon />
    </CloseButtonWrapper>
  );
}

const CloseButtonWrapper = styled.button<{$isCloseToTop?: boolean}>`
  z-index: 1;                      // TODO(hewitt): should not be necessary - wasn't when in frame.ts
  fill: var(--color-text-light);
  width: 50px;
  position: absolute;
  top: ${props => props.$isCloseToTop ? '5px' : '15px'};
  right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
