import React, {useCallback, useEffect, useState} from 'react';
import {HymnsList} from './hymns_list';
import '../util/shared.css';
import {synchronizeWeeklySongLists, useChurch} from '../data/use_church';
import {useLocalStorage} from '../data/use_local_storage';
import {LocalStorageKey} from '../data/client_local_storage';
import {
  Church,
  ClientChurch, CustomMusic,
  EmailType, getWeeklySongListType,
  Household,
  Hymn,
  isAdminForCurrentChurch, isBound,
  OrganizationType,
  SongList,
  SongListType
} from '../../common/model';
import {
  dateFromString,
  DateString,
  daysBetween, getWeeklyDateForOrganization,
  lastSundayDate,
  thisMonthDate,
  thisSundayDate,
  todayDate
} from '../../common/date_string';
import {useHousehold} from '../data/use_household';
import {ensureUnreachable} from '../../common/util';
import {getShortTime, getTimeFromDateTime} from '../../common/time_string';
import {Pages} from '../../common/pages';
import {getHymnFromSongListEntry, getUrlForPage} from '../util/path';
import {shareWithMusicLeader} from './onboarding/common';
import {getDemoSongList} from '../data/demo_song_list';
import * as server_api from '../../common/server_api';
import {Link, useNavigate} from 'react-router-dom';
import {getHymnOfTheMonthTitle, getSundayTitle} from '../../common/church';
import {useCustomMusic} from '../data/use_custom_music';
import {HymnalWithHymns, PrimaryChurchHymnalId, useHymnals} from '../data/use_hymnals';
import {useUserAttributes} from '../data/use_user_attributes';
import styled from 'styled-components/macro';
import {CheckMark} from '../util/shared';
import {getWeeklyListName} from '../util/school';

const thisWeekHymnalSlug = 'THIS-WEEK';

export interface Props {
  isDemo?: boolean;
  checkSongLists?: (songLists: Array<{type: SongListType, date: DateString}> | undefined) => void;
  showThisSundayOnly?: boolean;
}

export const OrganizationPage = ({isDemo, checkSongLists, showThisSundayOnly}: Props) => {
  const {church} = useChurch();
  const {household} = useHousehold();
  const [songsForWeek] = useLocalStorage(LocalStorageKey.WeeklySongList);
  const customMusic = useCustomMusic();
  const allHymnals = useHymnals();
  const [hymnals, setHymnals] = useState<HymnalWithHymns[]>([]);
  const navigate = useNavigate();
  const {isInternalUser} = useUserAttributes();

  useEffect(() => {
    if (!isDemo) {
      void synchronizeWeeklySongLists();
    }
  }, [isDemo]);

  useEffect(() => checkSongLists?.(songsForWeek), [checkSongLists, songsForWeek]);

  useEffect(() => {
    let songLists: Array<SongList>;
    if (isDemo) {
      songLists = getDemoSongList();
    } else if (showThisSundayOnly) {
      songLists = songsForWeek?.filter(songList =>
        songList.type === getWeeklySongListType(church) && songList.date === getWeeklyDateForOrganization(church)
      );
    } else {
      songLists = songsForWeek;
    }
    setHymnals(getHymnalsFromSongLists({
      songLists,
      church: isDemo ? undefined : church,
      allHymnals,
      customMusic,
      isDemo,
      household,
      showThisSundayOnly,
      isInternalUser,
    }));
  }, [allHymnals, customMusic, isDemo, showThisSundayOnly, songsForWeek, church, household, isInternalUser]);

  const onShare = useCallback(async () => {
    await shareWithMusicLeader();
  }, []);

  const askYourMusicLeader =
    <button className="inlineLink" onClick={onShare}>
      ask your music leader
    </button>;

  const onEditHymnal = useCallback((hymnal: HymnalWithHymns) => {
    if (hymnal.songListType === SongListType.Event) {
      navigate(getUrlForPage(Pages.EventCalendar));
    } else {
      navigate(getUrlForPage(Pages.LiturgyPlanner));
    }
  }, [navigate]);

  let message: JSX.Element | undefined;
  if (isDemo) {
    message = undefined;
  } else if (!household) {
    // this code path should be impossible (RootPage should have navigated to onboarding demo, passing isDemo=true)
    // displaying URL (window.location.href) as a debugging tool because some customers are still seeing it... :(
    message =
      <div>
        To view songs for your church, {askYourMusicLeader} to add your church to the app or <span
        className="inlineLink" onClick={() => navigate(getUrlForPage(Pages.Settings))}>sign in</span> if your church
        already participates.<br/>
        <br/>
        <p>{window.location.href}</p>
      </div>;
  } else if (!church) {
    message = <div>To use this tab, select a church in household <Link to={getUrlForPage(Pages.Settings)}>settings</Link> or {askYourMusicLeader} to add your church to the app. If you are the music leader, <Link to='https://calendar.app.google/gnnXn7zGBiB1fN469' target="_blank">click here</Link> to talk about getting your church setup.</div>;
  } else if (songsForWeek === undefined) {
    message = <div>Downloading songs for this week...</div>;
  } else if (hymnals.length === 0 && church.type === OrganizationType.Church) {
    message = <div><RequestSongs/> that your music leader upload this week’s songs.</div>;
  }
  const churchHymnal = allHymnals?.find(hymnal => hymnal.id === PrimaryChurchHymnalId);
  return (
    <HymnsList
      hymnals={allHymnals}
      title={church?.name ?? 'Your Church'}
      weeklySongGroups={[...(churchHymnal && !showThisSundayOnly ? [churchHymnal] : []), ...hymnals]}
      message={message}
      isDemo={isDemo}
      showThisSundayOnly={showThisSundayOnly}
      onEditHymnal={onEditHymnal}
      isFirstHymnalFancy={!!churchHymnal}
    />
  );
};

function getHymnalsFromSongLists({
  songLists,
  church,
  allHymnals,
  customMusic,
  isDemo,
  household,
  showThisSundayOnly,
  isInternalUser,
}: {
  songLists: Array<SongList>;
  church?: Church;
  allHymnals: HymnalWithHymns[] | undefined;
  customMusic: CustomMusic;
  isDemo: boolean | undefined;
  household: Household | undefined;
  showThisSundayOnly: boolean | undefined;
  isInternalUser: boolean;
}): HymnalWithHymns[] {
  const filteredSongLists = songLists
    .filter(songList =>
      songList.type !== SongListType.StaticList || (
        songLists.length === 1 &&
        church?.type !== OrganizationType.Church
      ))
    .filter(songList => !isEmptyLastSundayList(songList));

  const filterEvents = (songLists: (SongList)[]) => {

    const events = songLists.filter(songList => songList.type === SongListType.Event);

    if (!events.length) {
      return [];
    }

    if (isDemo) {
      const date = new Date();
      const futureEvents = events.filter(songList =>
        dateFromString(songList.date) >= date
      )
      if (!futureEvents.length) {
        return [];
      }
      let nextEvent = futureEvents[0];
      for (const event of futureEvents) {
        if (event.date < nextEvent.date) {
          nextEvent = event;
        }
      }
      return [nextEvent];
    }

    return events;
  }

  const orderedSongLists = [
    ...filteredSongLists.filter(songList =>
      songList.type === SongListType.HymnOfTheMonth && songList.date === thisMonthDate()),
    ...filteredSongLists
      .filter(songList =>
        songList.type === SongListType.WorshipService ||
        (songList.type === SongListType.WeeklyList && songList.songs.length > 0))
      .sort((lhs, rhs) => rhs.date.localeCompare(lhs.date)),
    ...filterEvents(filteredSongLists),
  ];

  const thisSundayRequest = <div><RequestSongs/> that your music leader upload this week’s songs.</div>;

  const hymnals: HymnalWithHymns[] = orderedSongLists
    .map((songList) => {
      const hymns = songList.songs
        .map(song => getHymnFromSongListEntry({song, hymnals: allHymnals, customMusic}))
        .filter(hymn => hymn) as Hymn[];
      return {
        id: 0,
        name: getSongListName(church, songList, hymns, songLists.length),
        hymns,
        slug: thisWeekHymnalSlug,
        songListType: songList.type,
        message: songList.songs.filter(song => isBound(song)).length !== 0 ? undefined : (
          songList.type === SongListType.WorshipService
            ? thisSundayRequest
            : <div>Your music director has not yet added any songs for this event.</div>
        )
      };
    });

  insertWeeklyPrompt();
  insertEventCalendarPrompt();

  return hymnals;

  function insertWeeklyPrompt() {
    if (!church?.type) {
      return;
    }

    // TODO(hewitt): remove this check? (was for Bohnet Music Academy, may not be used anymore)
    // hide weekly prompt for single static song list
    if (songLists.length === 1 && hymnals.length === 1 && hymnals[0].name === '') {
      return;
    }

    // prompt goes after hymn of the month
    const containsHymnOfTheMonth = songLists.length > 0 && songLists[0].type === SongListType.HymnOfTheMonth;
    const insertionIndex = containsHymnOfTheMonth ? 1 : 0;

    switch (church.type) {
      case OrganizationType.SmallGroup:
        return;

      case OrganizationType.School:
        if (songLists.find(({type, songs}) => type === getWeeklySongListType(church) && songs.length > 0)) {
          return;
        }
        if (!isAdminForCurrentChurch(household) && !isInternalUser) {
          return;
        }
        hymnals.splice(insertionIndex, 0, {
          id: 0,
          name: 'Weekly Planner',
          hymns: [],
          message: <div/>, // prevent hiding empty hymnal
          slug: thisWeekHymnalSlug,
        });
        break;

      case OrganizationType.Church: {
        if (songLists.find(({date, type}) =>
          date === getWeeklyDateForOrganization(church) && type === getWeeklySongListType(church))
        ) {
          return;
        }
        hymnals.splice(insertionIndex, 0, {
          id: 0,
          name: getSundayTitle(thisSundayDate()),
          hymns: [],
          message: thisSundayRequest,
          slug: thisWeekHymnalSlug,
        });
        break;
      }

      default:
        ensureUnreachable(church.type);
    }
  }

  function insertEventCalendarPrompt() {
    if (songLists.find(list => list.type === SongListType.Event)) {
      return;
    }
    if (!isAdminForCurrentChurch(household) && !isInternalUser) {
      return;
    }
    if (showThisSundayOnly) {
      return;
    }
    hymnals.push({
      id: 0,
      name: 'Event Calendar',
      hymns: [],
      message: <div/>,
      slug: thisWeekHymnalSlug,
      songListType: SongListType.Event,
    });
  }
}

function getSongListName(
  church: ClientChurch | undefined,
  songList: SongList,
  hymns: Hymn[],
  songListCount: number,
) {
  const {name, type, date: dateStr, time} = songList;
  switch (type) {
    case SongListType.SongsOfTheTerm:
      return 'Songs of the Term'
    case SongListType.HymnOfTheMonth:
      return getHymnOfTheMonthTitle(hymns);
    case SongListType.WorshipService:
      return getSundayTitle(dateStr);
    case SongListType.WeeklyList:
      return getWeeklyListName(church, dateStr);
    case SongListType.Event:
      const date = dateFromString(dateStr);
      const datePart = date ? ` - ${getDateAsShortString(date)}` : '';
      const shortTime = getShortTime(getTimeFromDateTime(time));
      const timePart = shortTime ? ` @ ${shortTime}` : '';
      return `${name ?? 'Event'}${datePart}${timePart}`;
    case SongListType.StaticList:
      // If there is only one static song list, it gets an empty title (flat list of songs)
      return songListCount === 1 ? '' : 'Songs';
    default:
      ensureUnreachable(type);
  }
}

function getDateAsShortString(date: Date) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${months[date.getMonth()]} ${date.getDate()}`;
}

let requestInProgress = false;

function RequestSongs() {
  const {church} = useChurch();
  const [requested, setRequested] = useLocalStorage(LocalStorageKey.SongListRequested);

  const onClick = useCallback(async () => {
    if (requestInProgress) {
      return;
    }
    requestInProgress = true;
    try {
      if (church?.token) {
        await server_api.sendEmail({
          type: EmailType.ThisSundaySongListRequest,
          date: thisSundayDate(),
          churchToken: church.token,
        });
      }
      setRequested(todayDate());
    } finally {
      requestInProgress = false;
    }
  }, [church?.token, setRequested]);

  return !requested || daysBetween(requested, todayDate()) >= 1 ? (
    <RequestButton onClick={onClick}>Request</RequestButton>
  ) : (
    <span>
      <CheckmarkWrapper>
        <CheckMark $size='15px' $padding='' $fillColor='var(--color-checkmark-song)'/>
      </CheckmarkWrapper> Requested
    </span>
  );
}

const isEmptyLastSundayList = (songList: SongList) =>
  songList.type === SongListType.WorshipService &&
  songList.date === lastSundayDate() &&
  songList.songs.filter(song => isBound(song)).length === 0;

const CheckmarkWrapper = styled.div`
  display: inline-block;
`

const RequestButton = styled.button`
  color: var(--color-text);
  background-color: var(--color-background);
  border-radius: 10px;
  padding: 0 7px;
  border: 2px solid var(--color-text);
  cursor: pointer;
`;
