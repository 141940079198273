import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Message, TextInput} from './common';
import {Button} from './button';
import {Frame} from './frame';
import styled from 'styled-components/macro';
import {useChurch} from '../../data/use_church';
import {getPlatform, Platform} from '../../util/platform';
import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey} from '../../data/client_local_storage';
import {confirmable, ConfirmDialog, createConfirmation} from 'react-confirm';
import {CloseButton} from '../../util/shared';
import {BackButton} from '../admin/church/shared';
import {Spinner} from '../../util/spinner';
import * as server_api from '../../../common/server_api';
import {CustomMusic, Hymn, SongSlug} from '../../../common/model';
import {confirm} from '../../util/confirm';
import {getTemporarySongSlug} from '../../data/use_custom_music';
import {useHousehold} from '../../data/use_household';

// see addCustomSong primary entry point at bottom of file

enum UploadStatus {
  Before = 'before',
  Uploading = 'uploading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

// The setStatus guard is ineffective b/c react invokes the useEffect twice without updating status.
// So, we resort to a global boolean... :(
let busyUploading = false;

// To support drag/drop into a dropzone / drop target in the future:
// https://www.freecodecamp.org/news/formdata-explained/
// "dropzone" is a good search keyword: https://www.npmjs.com/package/react-dropzone-uploader
// see https://medium.com/@blessingmba3/building-a-file-uploader-with-react-11dba6409480

const AddCustomSong: ConfirmDialog<
  {customMusic: CustomMusic, defaultSongName?: string}, {songSlug?: SongSlug}
> = ({customMusic, defaultSongName, proceed, show}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    if (show) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [show, dialogRef]);

  const [songName, setSongName] = useLocalStorage(LocalStorageKey.MusicUploadSongName);
  const [psalmNumber, setPsalmNumber] = useLocalStorage(LocalStorageKey.MusicUploadPsalmNumber);
  const songNameInput = useRef<HTMLInputElement>(null);
  const {church} = useChurch();
  const fileChooserInput = useRef<HTMLInputElement>(null);
  const [requestingPsalmNumber, setRequestingPsalmNumber] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();
  const psalmNumberInput = useRef<HTMLInputElement>(null);
  const [status, setStatus] = useState<UploadStatus>(UploadStatus.Before);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const {household} = useHousehold();

  useEffect(() => {
    setPsalmNumber(undefined); // clear previously entered psalm number
  }, [setPsalmNumber]);

  useEffect(() => {
    if (requestingPsalmNumber) {
      return;
    }
    songNameInput.current?.focus();
  }, [requestingPsalmNumber, songNameInput, setPsalmNumber, songName]);

  useEffect(() => {
    if (!requestingPsalmNumber) {
      return;
    }
    psalmNumberInput.current?.focus();
  }, [psalmNumberInput, requestingPsalmNumber, psalmNumber]);

  const onSongNameChanged = useCallback(() => {
    setSongName(songNameInput.current?.value ?? '');
  }, [songNameInput, setSongName]);

  const onPsalmNumberChanged = useCallback(() => {
    const value = psalmNumberInput.current?.value;
    if (value === undefined) {
      setPsalmNumber(undefined);
    }
    const number = psalmNumberInput.current?.value?.match(/(?<number>\d+)/)?.groups?.number;
    if (!number) {
      setPsalmNumber(undefined);
    }
    setPsalmNumber(Number(number));
  }, [psalmNumberInput, setPsalmNumber]);

  const onClickUpload = useCallback(() => {
    const number = songName?.match(/^Psalm (?<number>\d+)/)?.groups?.number;
    if (number && !psalmNumber) {
      setPsalmNumber(Number(number));
    }
    fileChooserInput?.current?.click();
  }, [fileChooserInput, psalmNumber, setPsalmNumber, songName]);

  const onAddPsalmNumber = useCallback(() => {
    setRequestingPsalmNumber(true);
  }, []);

  const onPsalmNumberBack = useCallback(() => {
    setRequestingPsalmNumber(false);
  }, []);

  const onFileChosen = useCallback(() => {
    const file = fileChooserInput?.current?.files?.[0];
    if (!file || !church || !songName) {
      console.log('no file')
      return;
    }
    setRequestingPsalmNumber(false);
    setFileToUpload(file);
  }, [church, songName]);

  const onClose = useCallback(() => {
    proceed({});
  }, [proceed]);

  const onRetry = useCallback(() => {
    setStatus(UploadStatus.Before);
    setErrorMessage(undefined);
    setFileToUpload(undefined);
    setIsUploadSuccessful(false);
    if (psalmNumber) {
      setRequestingPsalmNumber(true);
    }
  }, [psalmNumber]);

  const onFinish = useCallback(() => {
    if (!church|| !songName) {
      proceed({});
      return;
    }
    proceed({songSlug: getTemporarySongSlug({church, songName, customMusic})});
    setSongName(undefined);
  }, [church, customMusic, proceed, setSongName, songName]);

  const uploadFile = useCallback(async () => {
    if (busyUploading) {
      return;
    }
    busyUploading = true;
    try {
      if (
        status !== UploadStatus.Before || !fileToUpload || !church || !songName || isUploadSuccessful || errorMessage
      ) {
        return;
      }
      setStatus(UploadStatus.Uploading);
      try {
        const songNumber = songName && await songNumberToOverwrite(customMusic[church.id], songName, psalmNumber);
        await server_api.uploadChurchCustomMusicFile({
          churchId: church.id,
          songName,
          psalmNumber,
          file: fileToUpload,
          songNumber,
          household,
        });
        setPsalmNumber(undefined);
        setStatus(UploadStatus.Succeeded);
      } catch (error: any) {
        setErrorMessage(error.message);
        setStatus(UploadStatus.Failed);
      }
      setFileToUpload(undefined);
      setIsUploadSuccessful(true);
    } finally {
      busyUploading = false;
    }
  }, [
    church,
    customMusic,
    errorMessage,
    fileToUpload,
    household,
    isUploadSuccessful,
    songName,
    psalmNumber,
    status,
    setPsalmNumber,
  ]);

  useEffect(() => {
    void uploadFile();
  }, [uploadFile]);

  const squeezePageHeight = getPlatform() === Platform.Mobile;

  return (
    <Dialog ref={dialogRef}>
      {
        fileToUpload || isUploadSuccessful ? (
          <ShowUploadStatus/>
        ) : requestingPsalmNumber ? (
          <RequestPsalmNumber/>
        ) : (
          <RequestSongName/>
        )
      }
    </Dialog>
  );

  function RequestSongName() {
    return (
      <Frame
        embed={true}
        onboardingPage={undefined}
        mainContent={(
          <>
            <CloseButton onClick={onClose} isCloseToTop={true}/>
            <input type="file" id="imgupload" style={{display: "none"}} ref={fileChooserInput} onChange={onFileChosen}/>
            <Message $widthInChars={22}>What is the name of the song?</Message>
            <CustomMusicInput
              type='text'
              enterKeyHint='next'
              onChange={onSongNameChanged}
              ref={songNameInput}
              defaultValue={songName ?? defaultSongName}
              $squeezePageHeight={squeezePageHeight}
            />
          </>
        )}
        footerContent={(
          <>
            <Button onClick={onClickUpload} disabled={!songName}>UPLOAD</Button>
            <Button onClick={onAddPsalmNumber} disabled={!songName}>ADD PSALM NUMBER</Button>
          </>
        )}
        onEnterKeypress={onClickUpload}
        containsInputElement={true}
        containsMultipleButtons={true}
      />
    );
  }

  function RequestPsalmNumber() {
    const isValid = psalmNumber && psalmNumber >= 0 && psalmNumber <= 150;
    return (
      <Frame
        embed={true}
        onboardingPage={undefined}
        mainContent={(
          <>
            <BackButton onClick={onPsalmNumberBack} isAbsolutePosition={true}/>
            <input type="file" id="imgupload" style={{display: "none"}} ref={fileChooserInput} onChange={onFileChosen}/>
            <Message $widthInChars={22}>What is the Psalm number?</Message>
            <TextInput
              type='number'
              enterKeyHint='next'
              onChange={onPsalmNumberChanged}
              defaultValue={psalmNumber}
              ref={psalmNumberInput}
            />
          </>
        )}
        footerContent={(
          <>
            <Button onClick={onClickUpload} disabled={!isValid}>UPLOAD</Button>
          </>
        )}
        onEnterKeypress={onClickUpload}
        containsInputElement={true}
      />
    );
  }

  function ShowUploadStatus() {
    return (
      <Frame
        embed={true}
        onboardingPage={undefined}
        mainContent={
          status === UploadStatus.Before || status === UploadStatus.Uploading ? (
            <>
              <Message $widthInChars={16}>
                Uploading file
              </Message>
              <Spinner />
            </>
          ) : status === UploadStatus.Failed ? (
            <Message $widthInChars={24}>
              File upload failed.<br/><br/>
              {errorMessage ?? ''}
            </Message>
          ) : (
            <Message $widthInChars={24}>
              File upload successful!<br/><br/>
              The new song will be visible in your church hymnal within a couple of minutes.
            </Message>
          )
        }
        footerContent={
          status === UploadStatus.Before || status === UploadStatus.Uploading ? (
            <Button onClick={onClose}>CANCEL</Button>
          ) : status === UploadStatus.Failed ? (
            <>
              <Button onClick={onRetry}>RETRY</Button>
              <Button onClick={onClose}>CANCEL</Button>
            </>
          ) : (
            <Button onClick={onFinish}>DONE</Button>
          )
        }
        onEnterKeypress={onFinish}
      />
    );
  }
};

const CustomMusicInput = styled(TextInput)<{$squeezePageHeight: boolean}>`
  padding-top: ${props => props.$squeezePageHeight ? '10px' : '40px'};
`;

const Dialog = styled.dialog`
  height: 75%;
  border: none;
  border-radius: 20px;
  position: fixed;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 20px;
  margin: auto;
  background: var(--color-background);
  padding: 20px;
  top: 0;
`

async function songNumberToOverwrite(
  customMusic: Hymn[], songName: string, psalmNumber?: number
): Promise<string | undefined> {

  if (!customMusic) {
    return undefined;
  }

  for (const hymn of customMusic) {
    const hymnPsalmNumber = hymn.psalm?.match(/(?<number>\d+)/)?.groups?.number;
    if (
      hymn.title.toLowerCase() === songName.toLowerCase() && !!hymn.psalm === !!psalmNumber &&
      (!psalmNumber || psalmNumber === Number(hymnPsalmNumber))
    ) {
      if (await confirm({
        confirmation: <span>Would you like to overwrite the existing song with the same name?</span>
      })) {
        return hymn.number.toString().padStart(3, '0');
      }
      break;
    }
  }
}

export const addCustomSong = createConfirmation(confirmable(AddCustomSong));
