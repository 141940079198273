import * as ReactDOM from "react-dom/client";
import * as React from "react";
import {createBrowserRouter, Navigate, RouterProvider,} from "react-router-dom";
import './index.css';
import {ensureExists} from "./common/util";
import {initializeApp} from './client/util/startup';
import {OrganizationPage} from './client/pages/organization_page';
import {Root} from './client/pages/root_page';
import {OnboardingPage} from './client/pages/onboarding_page';
import {getOnboardingPath, OnboardingPages, Pages} from './common/pages';
import {FailurePage} from './client/pages/failure_page';
import {FavoritesPage} from './client/pages/favorites_page';
import {HelpPage} from './client/pages/help_page';
import {LibraryPage} from './client/pages/library_page';
import {LoadingPage} from './client/pages/loading';
import {SettingsPage} from './client/pages/settings_page';
import {AdminInvite} from './client/pages/admin_invite';
import {HouseholdInvite} from './client/pages/household_invite';
import {PrivacyPage} from './client/pages/privacy_page';
import {TodayPage} from './client/pages/today/today_page';
import {EventCalendar} from './client/pages/admin/church/event_calendar';
import {LiturgyPlanner} from './client/pages/admin/church/liturgy_planner';
import {EngineeringPage} from './client/pages/admin/engineering/engineering_page';
import {EngineeringCustomMusicPage} from './client/pages/admin/engineering/music/engineering_custom_music_page';
import {EngineeringBrokenSongsPage} from './client/pages/admin/engineering/music/engineering_broken_songs_page';
import {HymnalsProvider} from './client/data/use_hymnals';
import {CustomMusicProvider} from './client/data/use_custom_music';
import {isInsideMobileApp} from './client/util/billing';
import {getDevice, getPlatform} from './client/util/platform';

// TODO(hewitt): Move into src/client -> see note in webpack.config.js

// provide an answer to https://stackoverflow.com/questions/70341850/react-redirect-to-login-page-when-not-authenticated
// good discussion: https://github.com/remix-run/react-router/issues/10637
// good search: https://www.google.com/search?q=%2BcreateBrowserRouter+hide+routes+behind+login+page
// (searches must include "createBrowserRouter")
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root/>,
    errorElement: <FailurePage/>,
    children: [
      {
        index: true,
        element: <OrganizationPage/>,
      },
      {
        path: Pages.AdminInvite,
        element: <AdminInvite/>,
      },
      {
        path: Pages.EngineeringBrokenSongs,
        element: <EngineeringBrokenSongsPage/>,
      },
      {
        path: Pages.EngineeringCustomMusic,
        element: <EngineeringCustomMusicPage/>,
      },
      {
        path: Pages.Demo,
        element: <Navigate to={getOnboardingPath(OnboardingPages.Demo)}/>,
      },
      {
        path: Pages.Engineering,
        element: <EngineeringPage/>,
      },
      {
        path: Pages.EventCalendar,
        element: <EventCalendar/>,
      },
      {
        path: Pages.LiturgyPlanner,
        element: <LiturgyPlanner/>,
      },
      {
        path: Pages.Favorites,
        element: <FavoritesPage/>,
      },
      {
        path: Pages.Help,
        element: <HelpPage/>,
      },
      {
        path: Pages.HouseholdInvite,
        element: <HouseholdInvite/>,
      },
      {
        path: Pages.Library,
        element: <LibraryPage/>,
      },
      {
        path: Pages.Loading,
        element: <LoadingPage/>,
      },
      {
        path: Pages.Onboarding,
        element: <OnboardingPage/>,
      },
      {
        path: Pages.Privacy,
        element: <PrivacyPage/>,
      },
      {
        path: Pages.Settings,
        element: <SettingsPage/>,
      },
      {
        path: Pages.Organization,
        element: <OrganizationPage/>,
      },
      {
        path: Pages.Today,
        element: <TodayPage/>,
      },
    ],
  },
]);

initializeApp(router.navigate);

console.log(
  `Platform: ${getPlatform()}\nDevice: ${getDevice()}\nMobile App: ${isInsideMobileApp()}\n` +
  `User Agent: ${navigator.userAgent}`);

const rootElement = ensureExists(document.getElementById("root"));
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <CustomMusicProvider>
      <HymnalsProvider>
        <RouterProvider router={router} />
      </HymnalsProvider>
    </CustomMusicProvider>
  </React.StrictMode>
);
