import React, {useCallback, useEffect} from "react";
import jwt_decode from "jwt-decode";
import '../pages/settings_page.css'
import * as server_api from "../../common/server_api";
import {useAuthenticationHelper} from "./use_authentication_helper";
import {recordUserHousehold} from "../data/use_user_attributes";

export function isGoogleCredentials(credentials) {
    return credentials.startsWith('Google');
}

export function getGooglePicture(credentials) {
    return jwt_decode(credentials).picture;
}

export default function GoogleLogin(props) {
    const {setUserAndCredentials} = useAuthenticationHelper();

    let signInDiv = props.location === "settings" ? "signInDivSettings" :
        props.location === "welcome" ? "signInDivWelcome" : "signInDivModal"

    const handleCallbackResponse = useCallback(async (response) => {
        // todo: display meaningful error message if unable to log into our server
        const loginCredentials = `Google ${response.credential}`;
        await server_api.upsertUserDeprecated(loginCredentials).then(({token}) => {
            const {name, email} = jwt_decode(response.credential);
            setUserAndCredentials({name, email}, loginCredentials, token);
        });
        await recordUserHousehold();
    }, [setUserAndCredentials]);

    useEffect( () => {
        void (async () => {
            // occasionally, on safari & firefox the 'google' global is not defined by this point
            // likely because the accounts.google.com script in index.html is marked async & defer
            // thus, we sleep 0s, 250ms, and 1s before giving up
            for (const i of [0, 1, 4]) {
                try {
                    /* global google */
                    google.accounts.id.initialize({
                        client_id: "261418325124-gbbhvui42rgk8akd6vu029oeva22v3ab.apps.googleusercontent.com",
                        callback: handleCallbackResponse,
                    })

                    let parent = document.getElementById(signInDiv);

                    switch (signInDiv) {
                        default:
                        case "signInDivWelcome":
                            google.accounts.id.renderButton(parent, {width: "300px"});
                            break;
                        case "signInDivModal":
                            google.accounts.id.renderButton(parent, {width: "45px", type: "icon"});
                            break;
                        case "signInDivSettings":
                            google.accounts.id.renderButton(parent, {width: "250px"});
                            break;
                    }
                    break;
                } catch {
                }
                await new Promise(r => setTimeout(r, i * 250))
            }
        })();
    }, [handleCallbackResponse, signInDiv])

    return (
        <div id={signInDiv} style={{zIndex: '9999'}}/>
    );
}