import '../util/shared.css';
import {HymnsList} from "./hymns_list";
import {FavoritesHymnalId, HymnalWithHymns, useHymnals} from '../data/use_hymnals';
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {HouseholdStatus, Hymn} from '../../common/model';
import {useNavigate} from 'react-router-dom';
import {getUrlForPage} from '../util/path';
import {Pages} from '../../common/pages';
import {favorites} from '../data/favorites';
import {useHousehold} from '../data/use_household';
import {userIsSignedIn} from '../util/shared';

export const favoritesHymnalIconPath = '/images/bookmark.png';

const favoritesHymnalAttributes: Omit<HymnalWithHymns, 'hymns'> = {
  id: FavoritesHymnalId,
  name: 'Favorites',
  slug: 'FAVORITES',
  icon: {path: favoritesHymnalIconPath},
  hideTitle: true,
};

export const FavoritesPage = () => {
  const hymnals = useHymnals();
  const [favoriteHymns, setFavoriteHymns] = useState<Hymn[]>([]);
  const {household} = useHousehold();
  const householdStatus = household?.status;
  const navigate = useNavigate();

  // refresh favorites
  useEffect(() => {
    if (!userIsSignedIn()) {
      return;
    }
    setFavoriteHymns(favorites.get(hymnals));
    const token = favorites.registerCallback(() => {
      setFavoriteHymns(favorites.get(hymnals));
    });
    void favorites.reconcileWithServer();
    return () => favorites.unregisterCallback(token);
  }, [hymnals]);

  const message = useMemo<ReactElement | undefined>(() => {
    if (householdStatus !== HouseholdStatus.Subscribed) {
      return <div>Subscribe household to view favorites</div>;
    } else {
      if (!userIsSignedIn()) {
        return (
          <div>Please <span className="inlineLink"
                            onClick={() => navigate(getUrlForPage(Pages.Settings))}
                      >sign in</span> to choose favorites.</div>
        );
      } else if (favoriteHymns.length === 0) {
        return <div>Open a song to mark it as a favorite</div>;
      } else {
        return undefined;
      }
    }
  }, [householdStatus, favoriteHymns.length, navigate]);

  const favoritesHymnal = useMemo<HymnalWithHymns>(() =>
    ({...favoritesHymnalAttributes, hymns: favoriteHymns, message}),
    [favoriteHymns, message]
  );

  return <HymnsList hymnals={[favoritesHymnal]} title='Favorites'/>;
};
