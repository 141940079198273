import React, {ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import '../util/shared.css';
import './settings_page.css'
import './settings/shared.css'
import {Header} from './header';
import {Footer} from './footer';
import {VerseCount} from './settings/verse_count';
import {Login} from './settings/login';
import {Guest, LocalStorageKey} from '../data/client_local_storage';
import {useLocalStorage} from '../data/use_local_storage';
import {LocalStoragePage} from './settings/local_storage_page';
import {isInsideAppleAppStoreApp, isInsideGooglePlayApp, manageAppleSubscription} from '../util/billing';
import styled from 'styled-components/macro';
import {
    InnerPageContent,
    OuterPageContent,
    SettingsTransitionWrapper,
} from '../util/shared';
import {LoggingPage} from './settings/logging_page';
import {ChooseChurchPage} from './choose_church_page';
import {synchronizeChurchWithServer, useChurch} from '../data/use_church';
import {deleteHouseholdLocalStorageEntries, synchronizeHouseholdWithServer, useHousehold} from '../data/use_household';
import {useNavigate} from 'react-router-dom';
import {SongIntroductionSettingPage} from './settings/song_introduction';
import {SettingsDisplay} from './settings/shared';
import {SongOptions} from './settings/song_options';
import {getUrlForPage} from '../util/path';
import {confirm} from '../util/confirm';
import {capitalize, isValidEmail} from '../../common/util';
import {HouseholdSchedule} from './settings/household_schedule';
import {Household} from '../../common/model';
import {Pages} from '../../common/pages';
import {ForwardArrow} from '../util/forward_arrow';
import {alert} from '../util/confirm';

// NOTE: Set isModal = true if we want settings to overlay the current page & then disappear

export const SettingsPage = () => {
    const [display, setDisplay] = useState(SettingsDisplay.Settings);
    const {household, setHousehold} = useHousehold();
    const [user] = useLocalStorage(LocalStorageKey.User);
    const {church} = useChurch();
    const [iOSAppVersion] = useLocalStorage(LocalStorageKey.IOSAppVersion);
    const [appVersion] = useLocalStorage(LocalStorageKey.AppVersion);
    const [bundleHash] = useLocalStorage(LocalStorageKey.BundleHash);
    const isModal = false;
    const navigate = useNavigate();
    const nameInputRef = useRef<HTMLInputElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);

    const onShowChooseChurch = useCallback(() => {
        setDisplay(SettingsDisplay.ChooseChurch)
    }, []);

    const {schedule} = household?.surveyResponses ?? {};
    const onShowSchedule = useCallback(() => {
        setDisplay(SettingsDisplay.HouseholdSchedule)
    }, []);

    const onHouseholdSignOut = useCallback(async () => {
        if (await confirm({confirmation: <span>Are you sure you wish to<br/>Sign Out?</span>})) {
            deleteHouseholdLocalStorageEntries();
            window.location.href = '/settings';
        }
    }, []);

    useEffect(() => {
        void synchronizeHouseholdWithServer({force: true});
        void synchronizeChurchWithServer({force: true});
    }, []);

    function renderHouseholdSettings() {
        return (
            <div className="settingsBox">
                <div className="settingBoxHeader">Household</div>
                <table className="settings">
                    <tbody>
                        <tr key='last name' className="setting arrow">
                            <td className="settingName">Last Name</td>
                            <td className="currentValue">
                                <InvisibleInput
                                    type='text'
                                    ref={nameInputRef}
                                    defaultValue={household?.familyLastName}
                                    onBlur={(event) => {
                                        if (!household) {
                                          return;
                                        }
                                        let newHousehold: Household = {...household};
                                        newHousehold.familyLastName = event.target.value;
                                        setHousehold(newHousehold);
                                    }}
                                    onKeyUp={(event) => {
                                        if ((event.key === 'Enter' || event.key === 'Escape') && nameInputRef.current) {
                                            nameInputRef.current.blur();
                                        }
                                    }}
                                />
                            </td>
                            <td className='settingAction'></td>
                        </tr>
                        <tr key='email' className="setting arrow">
                            <td className="settingName">Email</td>
                            <td className="currentValue">
                                <InvisibleInput
                                    type='email'
                                    ref={emailInputRef}
                                    defaultValue={household?.householdEmail}
                                    onBlur={(event) => {
                                        if (!household) {
                                            return;
                                        }
                                        const newEmail = event.target.value;
                                        if (!isValidEmail(newEmail)) {
                                            void alert({
                                                confirmation: <span>"{newEmail}" is not a valid email address</span>
                                            });
                                            event.target.value = household.householdEmail;
                                            return;
                                        }
                                        let newHousehold: Household = {...household};
                                        newHousehold.householdEmail = newEmail;
                                        setHousehold(newHousehold);
                                    }}
                                    onKeyUp={(event) => {
                                        if ((event.key === 'Enter' || event.key === 'Escape') && emailInputRef.current) {
                                            emailInputRef.current.blur();
                                        }
                                    }}
                                />
                            </td>
                            <td className='settingAction'></td>
                        </tr>
                        <tr key='church' onClick={onShowChooseChurch} className="setting arrow">
                            <td className="settingName">Church</td>
                            <td className="currentValueEllipse">{church?.name ?? "No Church"}</td>
                            <td><ForwardArrow/></td>
                        </tr>
                        {
                            household?.surveyResponses && (
                                <tr onClick={onShowSchedule} className="setting arrow">
                                    <td className="settingName">Schedule</td>
                                    <td className="currentValueEllipse">
                                        {schedule ? schedule.map(entry => capitalize(entry)).join('/') : ''}
                                    </td>
                                    <td><ForwardArrow/></td>
                                </tr>
                            )
                        }
                        <tr key='individual' onClick={() => setDisplay(SettingsDisplay.Login)} className="setting arrow">
                            <td className="settingName">Individual</td>
                            <td className="currentValue">{user && user !== Guest ? user.name : "Sign In For Favorites"}</td>
                            <td><ForwardArrow/></td>
                        </tr>
                        <tr key='sign out' onClick={onHouseholdSignOut} className="setting arrow">
                            <RedSettingName className="settingName">Sign Out</RedSettingName>
                            <td className="currentValue"></td>
                            <td/>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    function renderSongSettings() {
        return <div className="settingsBox">
            <div className="settingBoxHeader">Song Options</div>
            <SongOptions setDisplay={setDisplay}/>
        </div>;
    }

    const versionHash = appVersion === 'localhost' ? appVersion : `${appVersion},${bundleHash}`;

    function renderInternalSettings() {
        return <div className="settingsBox">
            <div className="settingBoxHeader">Internal</div>
            <table className="settings">
                <tbody>
                {
                  iOSAppVersion &&
                  <tr className="setting arrow">
                      <td className="settingName">iOS App Version</td>
                      <td className="currentValue">{iOSAppVersion}</td>
                      <td className='settingAction'></td>
                  </tr>
                }
                <tr className="setting arrow">
                    <td className="settingName">Version Hash</td>
                    <td className="currentValue">{versionHash}</td>
                    <td className='settingAction'></td>
                </tr>
                <tr className="setting arrow" onClick={() => setDisplay(SettingsDisplay.LocalStorage)}>
                    <td className="settingName">Local Storage</td>
                    <td className="currentValue"/>
                    <td className='settingAction'>
                        <ForwardArrow/>
                    </td>
                </tr>
                <tr className="setting arrow" onClick={() => setDisplay(SettingsDisplay.Logging)}>
                    <td className="settingName">Logging</td>
                    <td className="currentValue"/>
                    <td className='settingAction'>
                        <ForwardArrow/>
                    </td>
                </tr>
                </tbody>
            </table>
            <InternalManageSubscription/>
        </div>;
    }

    function renderAppSettings() {
        return <div className="settingsBox appSettings hidden">
            <div className="settingBoxHeader">Display</div>
            <table className="settings">
                <tbody>
                <tr className="setting arrow">
                    <td className="settingName">Initial Screen</td>
                    <td className="currentValue">Library</td>
                    <td className='settingAction'>
                        <ForwardArrow/>
                    </td>
                </tr>
                <tr className="setting arrow">
                    <td className="settingName" colSpan={2}>Text Size</td>
                    <td className='settingAction'>
                        <ForwardArrow/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>;
    }

    const onBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const onHelpClick = useCallback(() => {
        navigate(getUrlForPage(Pages.Help));
    }, [navigate]);

    // for now, always show internal settings
    const showInternalSettings = true; /*isInternalUser ||
      window.location.hostname.startsWith('test.singyourpart.app') ||
      (window.location.protocol !== undefined && window.location.protocol !== 'https' && !isInsideGooglePlayApp());*/

    // TODO(hewitt): window.location.protocol must not be 'https' for Google Play app - find out what it is
    if (isInsideGooglePlayApp()) {
      console.log(`Google Play window.location.protocol: ${window.location.protocol}`);
    }

    function renderSettings() {
        return (
          <OuterPageContent>
              <Header title='Settings' onBack={isModal ? onBack : undefined} onHelpClick={onHelpClick}/>
              <InnerPageContent>
                  <div className="settingsContent">
                      {renderHouseholdSettings()}
                      {renderSongSettings()}
                      {renderAppSettings()}
                      {showInternalSettings && renderInternalSettings()}
                  </div>
              </InnerPageContent>
              {!isModal && <Footer/>}
          </OuterPageContent>
        )}

    const wrapWithTransition = useCallback((
      title: string,
      component: ReactElement,
      page: SettingsDisplay,
      {suppressHeader}: {suppressHeader?: boolean} = {}
    ) => (
      <SettingsTransitionWrapper $left={(display === page) ? "0" : "100%"}>
        {!suppressHeader && <Header title={title} onBack={() => setDisplay(SettingsDisplay.Settings)}/>}
        {component}
      </SettingsTransitionWrapper>
    ), [display]);

    function renderVerseCount() {
        return wrapWithTransition(
          'Verse Count',
          <VerseCount back={() => {setDisplay(SettingsDisplay.Settings)}} />,
          SettingsDisplay.VerseCount
        );
    }

    function renderSongIntroduction() {
        return wrapWithTransition(
          'Song Introduction',
          <SongIntroductionSettingPage back={() => {setDisplay(SettingsDisplay.Settings)}} />,
          SettingsDisplay.SongIntroduction
        );
    }

    function renderLocalStorage() {
        return wrapWithTransition(
          'Local Storage',
          <LocalStoragePage/>,
          SettingsDisplay.LocalStorage
        );
    }

    function renderLogging() {
        return wrapWithTransition(
          'Logging',
          <LoggingPage/>,
          SettingsDisplay.Logging
        );
    }

    function renderLogin() {
        return wrapWithTransition(
          'Login',
          <Login visible={display === SettingsDisplay.Login} />,
          SettingsDisplay.Login
        );
    }

    function renderHouseholdSchedule() {
        return wrapWithTransition(
          'Schedule',
          <HouseholdSchedule
            visible={display === SettingsDisplay.HouseholdSchedule}
            onBack={() => setDisplay(SettingsDisplay.Settings)}
          />,
          SettingsDisplay.HouseholdSchedule,
          {suppressHeader: true},
        );
    }

    const onSettingChanged = useCallback(() => {
        setDisplay(SettingsDisplay.Settings);
    }, []);

    const renderChooseChurch = useCallback(() => {
        return wrapWithTransition(
          'Choose Church',
          <ChooseChurchPage
            visible={display === SettingsDisplay.ChooseChurch}
            onSettingChanged={onSettingChanged}
            onBack={() => setDisplay(SettingsDisplay.Settings)}
          />,
          SettingsDisplay.ChooseChurch,
          {suppressHeader: true},
        );
    }, [display, onSettingChanged, wrapWithTransition]);

    return (
      <div>
          {renderSettings()}
          {renderHouseholdSchedule()}
          {household && renderChooseChurch()}
          {renderLogin()}
          {renderVerseCount()}
          {renderSongIntroduction()}
          {renderLocalStorage()}
          {renderLogging()}
      </div>
    )
}

const InternalManageSubscription = () => {
    // TODO(hewitt): how to bring up Google Play subscription management UI?
    if (!isInsideAppleAppStoreApp()) {
        return null;
    }
    return (
      <InternalManageSubscriptionButton onClick={manageAppleSubscription}>
          Manage Subscription
      </InternalManageSubscriptionButton>
    );
}

const InternalManageSubscriptionButton = styled.button`
  color: white;
  background-color: black;
  font-size: 1em;
  padding: 5px 15px;
  border-radius: 30px;
  margin-top: 10px;
  cursor: pointer;
`;

const RedSettingName = styled.td`
    color: red;
`;

const InvisibleInput = styled.input`
    width: 100%;
    height: 23px;
    text-align: right;
    border: none;
    outline: none;
    color: var(--color-text-light);
    background-color: var(--color-background);
    overflow: hidden;
    font-size: 16px;
    text-overflow: ellipsis;
`;