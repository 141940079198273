import {OuterPageContent} from '../../../util/shared';
import React, {KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  isLiturgySchemaVisible,
  songFutureSizeInWeeks,
  songHistorySizeInWeeks,
  synchronizeAllSongLists,
  useChurch
} from '../../../data/use_church';
import {
  addDayOffset,
  createDateString,
  dateFromString,
  DateString,
  dateStringFromDate,
  daysBetween,
  getDateForMonth,
  getDayOfMonth,
  getMonthName,
  getWeeklyDateForOrganization,
  getYear,
  lastSundayDate,
  maxDate,
  minDate,
  thisSundayDate,
} from '../../../../common/date_string';
import {
  Church,
  ClientChurch,
  getWeeklySongListType, isBound,
  OrganizationType,
  SongList,
  SongListEntry,
  SongListEntryType,
  SongListType,
} from '../../../../common/model';
import {
  BackButton,
  BackButtonPlaceholder,
  DateRowTop,
  LiturgyYearHeight,
  MinSongListRowCount,
  SongRowHeightSingle,
  SongRowWidth
} from './shared';
import {CellHoverOutlineWidth, SongCell} from './song_cell';
import {useAllSongLists} from './use_all_song_lists';
import {getNameForSunday} from '../../../../common/get_name_for_sunday';
import {useCustomMusic} from '../../../data/use_custom_music';
import {useHymnals} from '../../../data/use_hymnals';
import {getWeeklyListName} from '../../../util/school';
import {UploadSongButton} from '../../../util/upload_song_button';
import {NonEmptyArray} from '../../../../common/util';
import {ReactComponent as DownloadIcon} from "../../../assets/download.svg";
import * as server_api from "../../../../common/server_api";
import {LiturgySchemaColumn} from './liturgy_schema_column';
import {getPlatform, Platform} from '../../../util/platform';
import {useUserAttributes} from '../../../data/use_user_attributes';
import {useLiturgyKeyboardNavigation} from './use_liturgy_keyboard_navigation';
import {getDisplayNameForSongListEntry, resolveSearchStringToSongSlug} from '../../../util/path';

export interface CellPosition {
  date: string;
  row: number;
}

function getLiturgyTitle(type?: OrganizationType) {
  return type === OrganizationType.School ? 'Weekly Planner' : 'Liturgy Planner';
}

export const LiturgyPlanner = () => {
  const allSongLists = useAllSongLists();
  const gridRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const customMusic = useCustomMusic();
  const hymnals = useHymnals();
  const {church} = useChurch();
  const {isInternalUser, isChurchAdmin} = useUserAttributes();
  const currentWeekDate = church?.type === OrganizationType.Church ? thisSundayDate() : lastSundayDate();
  const [containsUpdates, setContainsUpdates] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const schema = church?.churchLiturgySchema;
  const [selectedColumnDate, setSelectedColumnDate] = useState<DateString>();
  const selectedWeekRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    void (async () => {
      setContainsUpdates(await allSongLists.containsUpdates());
    })();
  });

  const onBack = useCallback(() => {
    void (async () => {
      await synchronizeAllSongLists();
      navigate(-1);
    })();
  }, [navigate]);

  useEffect(() => {
    void synchronizeAllSongLists();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(getDayElementId(currentWeekDate))?.scrollIntoView({
        block: "end",
        inline: "center",
        // @ts-ignore
        behavior: "instant",
      });
    }, 100);
  }, [currentWeekDate, gridRef]);

  const dateInfos = useMemo(() => {
    const newestDate = maxDate([...allSongLists.map(list => list.date), currentWeekDate]);
    const oldestYear = getYear(addDayOffset(currentWeekDate, -(songHistorySizeInWeeks * 7)));
    const oldestColumnDate = thisSundayDate(createDateString(oldestYear, 1, 1));
    const newestColumnDate = addDayOffset(newestDate, (songFutureSizeInWeeks * 7));
    const weekCount = Math.round(daysBetween(oldestColumnDate, newestColumnDate) / 7) + 1;
    const dates: DateString[] = [];
    let date = dateFromString(oldestColumnDate);
    for (let index = 0; index < weekCount; index++) {
      dates.push(dateStringFromDate(date));
      date.setDate(date.getDate() + 7);
    }
    return dates as NonEmptyArray<DateString>;
  }, [allSongLists, currentWeekDate]);
  const yearCount = getYear(dateInfos[dateInfos.length - 1]!) - getYear(dateInfos[0]) + 1;
  const yearInfos = [...Array(yearCount).keys()].map(index => {
    const year = getYear(dateInfos[0]) + index;
    const datesInYear = dateInfos.filter(date => getYear(date) === year);
    const months = getUniqueMonthNames(datesInYear.map(date => date)).map(monthName => ({
      monthName,
      dates: datesInYear.filter(date => getMonthName(date) === monthName),
    }));
    return {year, months};
  });

  function getDateInfosForMonth(monthName: string, year: number): DateString[] {
    return dateInfos.filter(date => getYear(date) === year && getMonthName(date) === monthName);
  }

  const songListType = getWeeklySongListType(church);

  function getDateHeaders(monthName: string, year: number) {
    const dateToHighlight = currentWeekDate;
    return (
      <DateHeadersForMonth>
        {
          getDateInfosForMonth(monthName, year)
            .map(date => (
              <DateHeader
                key={date}
                date={date}
                id={getDayElementId(date)}
                highlight={date === dateToHighlight}
                setSelectedColumnDate={setSelectedColumnDate}
              >
                {titleFromDate(church, date)}
                {
                  church?.allowPrinting && date === getWeeklyDateForOrganization(church) &&
                  <DownloadPDFButton church={church} date={date} type={songListType}/>
                }
              </DateHeader>
            ))
        }
      </DateHeadersForMonth>
    );
  }

  function getDayElementId(date: DateString): string {
    return `liturgy-day-${date}`;
  }

  const maxSongListRow = useMemo(() => Math.max(...allSongLists
    .filter(({date}) => date >= dateInfos[0] && date <= dateInfos[dateInfos.length - 1])
    .flatMap(({songs}) => songs)
    .map(({row}) => row)
  ), [allSongLists, dateInfos]);
  const maxLiturgyRow = Math.max(...(church?.churchLiturgySchema?.rows.map(({row}) => row) ?? []), 0);
  const rowCount = Math.max(MinSongListRowCount, maxSongListRow + 1, maxLiturgyRow + 1);

  const nextDate = useCallback((date: DateString) =>
    minDate([dateInfos[dateInfos.length - 1], addDayOffset(date, 7)]), [dateInfos]);
  const prevDate = useCallback((date: DateString) =>
    maxDate([dateInfos[0], addDayOffset(date, -7)]), [dateInfos]);
  const {moveDown, onKeyDown, selectedCell, setSelectedCell} = useLiturgyKeyboardNavigation({
    defaultDate: currentWeekDate,
    rowCount,
    nextDate,
    prevDate,
  });

  useEffect(() => {
    if (selectedColumnDate) {
      setSelectedCell({date: selectedColumnDate, row: 1});
    }
  }, [selectedColumnDate, setSelectedCell])

  const setSelectedCellWrapper = useCallback((value: CellPosition) => {
    setSelectedColumnDate(undefined);
    setSelectedCell(value);
  }, [setSelectedCell]);

  function getWeeklySongs(monthName: string, year: number) {
    return (
      <WeeksOfMonthStacker key={`weeks-of-month-${monthName}-${year}`}>
        {
          getDateInfosForMonth(monthName, year).map(date => {
            const songList = allSongLists.find(list =>
              list.type === songListType &&
              list.date === date
            );
            return (
              <WeekStacker key={`week-stacker-${date}`}>
                {
                  [...Array(rowCount).keys()].map(index => index + 1).map(rowIndex => {
                    const row = church?.churchLiturgySchema?.rows.find(({row: current}) => current === rowIndex);
                    const label = getPlatform() === Platform.Mobile ? row?.label : undefined;
                    return (
                      <SongCell
                        key={`${songList?.id ?? date}-${rowIndex}`}
                        customMusic={customMusic}
                        date={date}
                        hymnals={hymnals}
                        row={rowIndex}
                        label={label}
                        songList={songList ?? {date, type: songListType, songs: []}}
                        songListType={songListType}
                        songListEntryType={row?.type ?? SongListEntryType.Song}
                        selectedCell={selectedColumnDate ? undefined : selectedCell}
                        setSelectedCell={setSelectedCellWrapper}
                        setIsEditing={setIsEditing}
                        moveDown={moveDown}
                        isInternalUser={isInternalUser}
                        isChurchAdmin={isChurchAdmin}
                      />
                    );
                  })
                }
                {date === selectedColumnDate &&
                  <SelectedWeekOutline tabIndex={0} onKeyDown={onLocalKeyDown} ref={selectedWeekRef}/>}
              </WeekStacker>
            );
          })
        }
      </WeeksOfMonthStacker>
    );
  }

  function getHymnOfTheMonth(monthName: string, year: number) {
    const date = getDateForMonth(monthName, year);
    const songList = allSongLists.find(
      list => list.type === SongListType.HymnOfTheMonth && list.date === date
    );
    return (
      <SongCell
        key={`${songList?.id ?? date}`}
        customMusic={customMusic}
        date={date}
        hymnals={hymnals}
        row={1}
        label={`${getMonthName(date)} Hymn of the Month`}
        songList={songList ?? {date, type: SongListType.HymnOfTheMonth, songs: []}}
        songListType={SongListType.HymnOfTheMonth}
        songListEntryType={SongListEntryType.Song}
        isInternalUser={isInternalUser}
        isChurchAdmin={isChurchAdmin}
      />
    )
  }

  const getCellValues = useCallback((row: string): string[] => {
    return row.split('\t');
  }, []);

  const getCopiedRows = useCallback((text: string): string[] => {
    const rows = text.split('\n');
    if (!selectedColumnDate || rows.length !== 1) {
      return rows;
    }
    // special case - (single row copy + column selection paste) => convert columns to rows for paste
    return getCellValues(rows[0]);
  }, [getCellValues, selectedColumnDate]);

  const onPaste = useCallback((event: ClipboardEvent) => {
    console.log('onPaste');
    if (isEditing) {
      return; // Allow currently open editor to handle paste
    }
    const text = event.clipboardData?.getData('text/plain').replace(/\r/g, ''); // strip Microsoft cruft
    if (text && selectedCell) {
      let updatedSongLists: SongList[] = [];
      const rows = getCopiedRows(text);
      for (const [rowIndex, rowValue] of rows.entries()) {
        const cells = getCellValues(rowValue);
        for (const [cellIndex, cellValue] of cells.entries()) {
          const date = addDayOffset(selectedCell.date, 7 * cellIndex);
          let songList =
            updatedSongLists.find(list => list.date === date) ??
            allSongLists.find(list => list.date === date) ?? {
              type: getWeeklySongListType(church),
              date: date,
              songs: [],
            };
          const row = selectedCell.row + rowIndex;
          let entry: SongListEntry | undefined;
          const schemaRow = schema?.rows?.find(({row: currentRow}) => currentRow === row);
          const entryType = schemaRow?.type ?? SongListEntryType.Song;
          const slug = entryType === SongListEntryType.Song &&
            resolveSearchStringToSongSlug(hymnals, church && customMusic[church?.id], cellValue);
          if (slug) {
            entry = {row, slug};
          } else if (cellValue) {
            entry = {row, text: cellValue};
          }
          if (entry) {
            songList = {...songList, songs: [...songList.songs.filter(song => song.row !== row), entry]};
            updatedSongLists = [...updatedSongLists.filter(list => list.date !== date), songList];
          }
        }
      }
      void allSongLists.updateEach(updatedSongLists);
      event.preventDefault();
      event.stopPropagation();
    }
  }, [allSongLists, church, customMusic, getCellValues, getCopiedRows, hymnals, isEditing, schema?.rows, selectedCell]);

  const onCopy = useCallback(() => {
    let text: string | undefined;
    const songList = allSongLists.find(({date}) => date === (selectedColumnDate ?? selectedCell.date));
    if (selectedColumnDate) {
      text = '';
      let firstEntry = true;
      const maxRow = Math.max(...(songList?.songs.map(({row}) => row) ?? []), 0);
      for (let currentRow = 1; currentRow <= maxRow; currentRow++) {
        const entry = songList?.songs?.find(({row}) => row === currentRow);
        if (!firstEntry) {
          text += '\n';
        }
        text += getEntryText(entry) ?? '';
        firstEntry = false;
      }
    } else if (selectedCell) {
      const entry = songList?.songs?.find(({row}) => row === selectedCell.row);
      text = getEntryText(entry);
    }
    if (text) {
      void navigator.clipboard.writeText(text);
    }

    function getEntryText(entry: SongListEntry | undefined): string | undefined {
      return isBound(entry) ? getDisplayNameForSongListEntry({entry: entry, hymnals, customMusic}) : entry?.text;
    }
  }, [allSongLists, hymnals, customMusic, selectedCell, selectedColumnDate]);

  useEffect(() => {
    // @ts-ignore
    window.addEventListener('paste', onPaste);
    window.addEventListener('copy', onCopy);
    return () => {
      // @ts-ignore
      window.removeEventListener("paste", onPaste);
      // @ts-ignore
      window.removeEventListener("copy", onCopy);
    }
  }, [onPaste, onCopy]);

  const title = getLiturgyTitle(church?.type);

  const onLocalKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (selectedColumnDate) {
        const songList = allSongLists.find(({date}) => date === selectedColumnDate);
        if (songList) {
          void allSongLists.update({...songList, songs: []});
        }
      }
    }
  }, [allSongLists, selectedColumnDate]);

  useEffect(() => {
    if (selectedColumnDate) {
      selectedWeekRef.current?.focus();
    }
  }, [selectedColumnDate, selectedWeekRef]);

  return (
    <OuterPageContent key='outer'>
      <InnerPageContent key='inner'>
        <LiturgySchemaWrapper key='liturgy-schema-wrapper'>
          {
            isLiturgySchemaVisible(isInternalUser) &&
            church?.type === OrganizationType.Church &&
            getPlatform() !== Platform.Mobile ? (
            <LiturgySchemaColumn key='liturgy-schema-column' allSongLists={allSongLists} songRowCount={rowCount}/>
          ) : null}
          <YearStacker key='year-stacker' ref={gridRef}>{
            yearInfos.map(({year, months}) => (
              <YearWrapper key={year}>
                <YearHorizontalWrapper>
                  <YearHeader key='year-header'>
                    <BackButton key='back' onClick={onBack}/>
                    <BackButtonPlaceholder/>
                    <YearNumber key='year'>{`${year} ${title}${containsUpdates ? ' *' : ''}`}</YearNumber>
                    <UploadSongButton />
                  </YearHeader>
                </YearHorizontalWrapper>
                <MonthStacker key='month-stacker' onKeyDown={onKeyDown}>{
                  months.map(({monthName, dates}) => (
                    <MonthWrapper key={monthName + year.toString()} $columnCount={dates.length}>
                      {
                        getDateHeaders(monthName, year)
                      }
                      {
                        getHymnOfTheMonth(monthName, year)
                      }
                      {
                        getWeeklySongs(monthName, year)
                      }
                    </MonthWrapper>
                  ))
                }</MonthStacker>
              </YearWrapper>
            ))
          }</YearStacker>
        </LiturgySchemaWrapper>
      </InnerPageContent>
    </OuterPageContent>
  );
}

function titleFromDate(church: Church | undefined, date: DateString) {
  const shortDate = `${getMonthName(date)} ${getDayOfMonth(date)}`;

  if (church?.type === OrganizationType.Church) {
    const name = getNameForSunday(date);
    if (name !== 'Ordinary Sunday') {
      return `${shortDate} - ${name}`;
    }
    return shortDate;
  }

  const listName = getWeeklyListName(church, date);
  return listName.includes(getMonthName(date)) ? listName : `${listName} - ${shortDate}`;
}

// retains order
function getUniqueMonthNames(dates: DateString[]): string[] {
  const monthNames: string[] = [];
  for (const date of dates) {
    const monthName = getMonthName(date);
    if (monthNames.length === 0 || monthNames[monthNames.length - 1] !== monthName) {
      monthNames.push(monthName);
    }
  }
  return monthNames;
}

const InnerPageContent = styled.div`
  flex: auto;
  overflow: scroll;
  overscroll-behavior-x: none; /* avoid swipe to go back */
  overscroll-behavior-y: none;
  max-width: 100vw;
    /* check out scroll-snap-points */
  /*scroll-snap-points-y: repeat(100vh);*/
  /*scroll-snap-type: y mandatory;*/
  /*scroll-snap-align: start; - on child */
`

const YearStacker = styled.div`
  display: flex;
  width: fit-content;
  text-align: left;
`;

const YearWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const YearHorizontalWrapper = styled.div`
  z-index: 6;
  position: sticky;
  top: 0;
  background-color: var(--color-background);
  height: ${LiturgyYearHeight};
`;

const YearHeader = styled.div`
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const YearNumber = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.7rem;
  max-width: 85vw;
  text-wrap: nowrap;
  overflow: hidden;
`

const MonthStacker = styled.div`
  display: flex;
  /*
      TODO(hewitt): Eliminate double borders between months.
                    See comments below about "outline" to understand how we are eliminating double borders.
                    See this post for the strategy: https://phuoc.ng/collection/css-layout/grid-without-double-borders/
  */
`;

const MonthWrapper = styled.div<{$columnCount: number}>`
  display: flex;
  flex-direction: column;
`;

const DateHeadersForMonth = styled.div`
  z-index: 4;
  display: flex;
  position: sticky;
  top: ${DateRowTop};
`;

function DateHeader({date, highlight, id, children, setSelectedColumnDate}: {
  date: DateString,
  highlight: boolean,
  id: string,
  setSelectedColumnDate: (date: DateString) => void,
  children: React.ReactNode;
}) {
  const onClick = useCallback(() => {
    setSelectedColumnDate(date)
  }, [date, setSelectedColumnDate]);
  return <DateHeaderWrapper id={id} $highlight={highlight} onClick={onClick}>{children}</DateHeaderWrapper>;
}

const DateHeaderWrapper = styled.div<{$highlight: boolean}>`
  width: ${SongRowWidth};
  height: ${SongRowHeightSingle};
  font-family: Jost-SemiBold, Arial, sans-serif;
  outline: 1px solid var(--color-text); /* outline instead of border takes up 0 space */
  padding: 0 10px;
  color: ${props => props.$highlight ? 'var(--color-date-highlight-text)' : 'var(--color-text)'};
  background-color: var(--color-liturgy-row-header);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const WeeksOfMonthStacker = styled.div`
  display: flex;
`;

const WeekStacker = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const SelectedWeekOutline = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: ${SongRowWidth};
  height: 100%;
  outline: ${CellHoverOutlineWidth} solid var(--color-text);
  pointer-events: none;
`;

function DownloadPDFButton({church, date, type}: {church: ClientChurch, date: DateString, type: SongListType}) {
  return (
    <a href={server_api.getPdfUrlForSongList(church.id, date, type)}>
      <DownloadIconWrapper/>
    </a>
  );
}

const DownloadIconWrapper = styled(DownloadIcon)`
  width: 25px;
  cursor: pointer;
  fill: var(--color-text);
`;

const LiturgySchemaWrapper = styled.div`
  display: flex;
  width: max-content;
`;
