import {default as ExternalAppleLogin} from 'react-apple-login';
import React, {useCallback} from "react";
import * as server_api from "../../common/server_api";
import {useAuthenticationHelper} from "./use_authentication_helper";
import {ReactComponent as AppleLogo} from "../assets/logo-apple.svg";
import './apple_login.css';
import {recordUserHousehold} from '../data/use_user_attributes';

interface Props {
  location: string
}

export function isAppleCredentials(credentials: string) {
  return credentials.startsWith('Apple');
}

// sets up a callback on globalThis that is used by the iOS app to log in
export function useSetupIOSLogin() {
  const {setUserAndCredentials} = useAuthenticationHelper();
  // @ts-ignore
  globalThis.handleIOSLogin = (jwt_token: string) => {
    const loginCredentials = `Google ${jwt_token}`;
    server_api.upsertUserDeprecated(loginCredentials).then(({token, name, email}: {token: string, name: string, email: string}) => {
      setUserAndCredentials({name, email}, loginCredentials, token);
    })
  }
}

// a callback to the iOS app that displays the login dialog in native code since Apple broke WKWebView login in iOS 17.1
export function getIOSDisplayLoginCallback(): (() => void) | undefined {
  // @ts-ignore
  const displayLoginDialog = window.webkit?.messageHandlers?.displayLoginDialog;
  return displayLoginDialog ? () => displayLoginDialog.postMessage({}) : undefined;
}

export function isWKWebView(): boolean {
  if (getIOSDisplayLoginCallback()) {
    return true;
  }

  // deprecated - retaining for legacy iOS clients - remove this code 6/2024
  if( navigator.platform.substr(0,2) === 'iP' ) {    // iOS detected
    // @ts-ignore
    if( window.webkit && window.webkit.messageHandlers ) {
      return true;
    }
  }
  return false;
}

export function showGoogleButton() {
  // Google does not support login inside a WKWebView control, which is how we host the browser for the App Store
  return !isWKWebView();
}

export function AppleLogin(props: Props) {
  const {setUserAndCredentials} = useAuthenticationHelper();

  const appleResponse = useCallback(async (response: any) => {
    if (response.error || !response.authorization || !response.authorization.code) {
      console.log(`ERROR: appleResponse "${response.error}"`);
      return;
    }
    const {code} = response.authorization;
    const {user} = response;
    const loginCredentials = `Apple ${code}${user ? `:${user.name.firstName} ${user.name.lastName}` : ''}`;
    await server_api.upsertUserDeprecated(loginCredentials).then(({token, name, email}: {token: string, name: string, email: string}) => {
      setUserAndCredentials({name, email}, loginCredentials, token);
    });
    await recordUserHousehold();
  }, [setUserAndCredentials]);

  let classNames = 'appleSignInBase';
  if (props.location === 'settings') {
    classNames += ' appleSignInSettings'
  } else if (props.location === 'welcome') {
    classNames += ' appleSignInWelcome'
  } else {
    classNames += ' appleSignInSmall'
  }

  // Apple broke their own sign in for WKWebView in iOS 17.1, so we call up to native Swift to sign in
  // They fixed it in iOS 17.2, but we are retaining the code just in case
  // to re-enable Native login, just set iosLoginCallback to getIOSDisplayLoginCallback()
  // Note that Apple doesn't support localhost, nor do they respect our `test.singyourpart.app`
  // subdomain settings on their developer website, so we use this as a stop-gap.
  const iOSLogInCallback = getIOSDisplayLoginCallback();
  if (iOSLogInCallback) {
    return (
      <button className={classNames} onClick={iOSLogInCallback}>Sign In</button>
    );
  }

  return (
    <ExternalAppleLogin
      clientId="app.singyourpart.client"
      redirectURI="https://singyourpart.app"
      usePopup={true}
      callback={appleResponse} // Catch the response
      scope="email name"
      responseMode="query"
      render={renderProps => (  //Custom Apple Sign in Button
        <button
          onClick={renderProps.onClick}
          className={classNames}
        >
          <div style={{display: "flex"}}>
            <AppleLogo style={props.location !== "modal" ? {
              width: "25px",
              filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(138deg) brightness(103%) contrast(101%)",
              transform: "translateY(-4px)",
            } :
              {
                width: "30px",
                filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(138deg) brightness(103%) contrast(101%)",
                transform: "translate(4px,-2px)"
              }}
            />
            <div>
              {props.location !== "modal" && <div
                  style={props.location === "settings" ?
                    {marginLeft: "30px", fontWeight: "500", fontSize: "15px"} :
                    {marginLeft: "51px", fontWeight: "500", fontSize: "15px"}}>Sign in with Apple</div>}
            </div>
          </div>
        </button>
      )}
    />
  );
}
