import React, {useCallback, useEffect} from 'react';
import {Frame} from './frame';
import {isDebugOnboardingEnvironment, navigateToOnboardingPage} from './common';
import {Button} from './button';
import {OnboardingPages} from '../../../common/pages';
import {getAppStoreUrl, isInsideMobileBrowser} from '../../util/billing';
import {maybeUpgradeApp} from '../../util/app_upgrade';
import {Device, getDevice, iOSVersion} from '../../util/platform';
import {useHousehold} from '../../data/use_household';
import {OrganizationPage} from '../organization_page';
import styled from 'styled-components/macro';
import {useNavigate} from 'react-router-dom';
import {Environment, getEnvironment} from '../../../common/environment';


export const Demo = () => {
  // "Get Started" navigates to app store in mobile browsers
  // PROBLEM: Does not properly detect in-app for some Android devices
  // SOLUTION: Temporarily eliminate app store link for all Android devices
  // TODO(hewitt): When new Android Studio-based app releases, bring this back (assuming a reliable in-app indicator)
  // const appStoreUrl = isInsideMobileBrowser() && !isDebugOnboardingEnvironment() ? getAppStoreUrl() : undefined;
  const appStoreUrl =
    isInsideMobileBrowser() &&
    !isDebugOnboardingEnvironment() &&
    getDevice() !== Device.Android &&
    getEnvironment() !== Environment.Development &&
    (!iOSVersion || iOSVersion > 15) // iOS 15 WebView has problems with input focus, so allow mobile browser usage
      ? getAppStoreUrl()
      : undefined;
  const {household} = useHousehold();
  const navigate = useNavigate();

  useEffect(() => {
    void (async () => {
      await maybeUpgradeApp();
      if (household) {
        navigate('/');
      }
    })();
  }, [household, navigate]);

  const beginOnboarding = useCallback((page: OnboardingPages) => {
    if (appStoreUrl) {
      window.location.href = appStoreUrl;
      return;
    }
    navigateToOnboardingPage(navigate, page);
  }, [appStoreUrl, navigate]);

  const onGetStarted = useCallback(() => {
    beginOnboarding(OnboardingPages.Start);
  }, [beginOnboarding]);

  const onHaveHousehold = useCallback(() => {
    beginOnboarding(OnboardingPages.HouseholdFind);
  }, [beginOnboarding]);

  return (
    <DemoWrapper>
      <Frame
        onboardingPage={OnboardingPages.Demo}
        mainContent={
          <OrganizationPage isDemo={true}/>
        }
        footerContent={(
          <>
            <Button key='getStarted' onClick={onGetStarted}>GET STARTED</Button>
            {
              !appStoreUrl &&
              <Button key='haveHousehold' onClick={onHaveHousehold}>I ALREADY HAVE A HOUSEHOLD</Button>
            }
          </>
        )}
        suppressBackButton={true}
        onEnterKeypress={onGetStarted}
        alignTop={true}
      />
    </DemoWrapper>
  );
}

const DemoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
